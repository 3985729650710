import { httpRequest } from '@/apis/request'

enum Apis {
  addFeedback = 'new_feedback',
  getFeedback = 'user_feedback',
}
interface addFeedback {
  relatedModel: string
  content: string
  feedbackPhone: string
  feedbackName: string
  appType: number
  imagesUrlArr: Array<any>
  uid: number,
  version:string
}

interface getFeedback {
  uid: number
}

export function addFeedback(data: addFeedback) {
  return httpRequest.post(Apis.addFeedback, data)
}

export function getFeedback(data: getFeedback) {
  return httpRequest.get(Apis.getFeedback, data)
}
