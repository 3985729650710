import sampleCabinet1 from '@/assets/sample/sample_cabinet1.png'
import sampleCabinet2 from '@/assets/sample/sample_cabinet2.png'
import sampleCabinet3 from '@/assets/sample/sample_cabinet3.png'
import sampleCabinet4 from '@/assets/sample/sample_cabinet4.png'

const ASSETS_CDN_URL = 'http://cdn.eggrj.com/bluenAssets'
export const sampleCabinets = [
  sampleCabinet1,
  sampleCabinet2,
  sampleCabinet3,
  sampleCabinet4,
]
export const sampleCdn = [
  {
    fileName: 'sample1.png',
    fileType: 'url',
    originFile: '试生产样品柜样例图',
    url: `${ASSETS_CDN_URL}/sample/sample1.png`,
  },
  {
    fileName: 'sample2.png',
    fileType: 'url',
    originFile: '试生产样品柜样例图',
    url: `${ASSETS_CDN_URL}/sample/sample2.png`,
  },
  {
    fileName: 'sample3.png',
    fileType: 'url',
    originFile: '试生产样品柜样例图',
    url: `${ASSETS_CDN_URL}/sample/sample3.png`,
  },
  {
    fileName: 'sample4.png',
    fileType: 'url',
    originFile: '试生产样品柜样例图',
    url: `${ASSETS_CDN_URL}/sample/sample4.png`,
  },
]
