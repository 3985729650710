import { buryPoint } from '@/apis/common/buryPoint'
import { Directive, DirectiveBinding } from 'vue'
import Vue from 'vue'

interface IDirective {
  key: string
  directive: Directive<HTMLElement>
}

// 指令的集合
const directiveArr: (() => IDirective)[] = [vBuryPoint]

/**
 * @description 注册所有的指令
 */
export function registryDirective() {
  for (let idx = 0; idx < directiveArr.length; idx++) {
    const fn = directiveArr[idx]
    const { key, directive } = fn()
    Vue.directive(key, directive)
  }
}

/**
 * @description 埋点指令 指令参数 v-bp:event event 指令参数 表示指令运行的方法  value 指令绑定值 "module:point" 按照此格式传值
 * @returns
 */
function vBuryPoint() {
  const directive: Directive = {
    bind(el: HTMLElement, binding: DirectiveBinding<string>) {
      const fn = () => {
        const keys = binding.value.split(':')
        if (!keys.length) return
        buryPoint({ function_module: keys[0], function_point: keys[1] })
      }
      // 向元素上挂载原始函数，便于后续的解除引用
      ;(el as any)._ygvfn = fn
      el.addEventListener(binding.arg ?? 'click', fn)
    },
    unbind(el: HTMLElement, binding: DirectiveBinding<string>) {
      el.removeEventListener(binding.arg ?? 'click', (el as any)._ygvfn)
      Reflect.deleteProperty(el, '_ygvfn')
    },
  }
  return {
    key: 'bp',
    directive,
  }
}
