import { request } from '@/util/vueRequest.js'

interface FetchNoticeListPayloadType {
  uid: number
  limit: number
  page: number
}

export function fetchNoticeList(payload: FetchNoticeListPayloadType) {
  return request({
    url: '/notice_list',
    method: 'POST',
    headers: {
      token: window.sessionStorage.getItem('bldata'),
    },
    data: payload,
  })
}
