export default {
  // Options.jsx
  items_per_page: '/ бет',
  jump_to: 'Секіру',
  jump_to_confirm: 'Растау',
  page: '',
  // Pagination.jsx
  prev_page: 'Артқа',
  next_page: 'Алға',
  prev_5: 'Алдыңғы 5',
  next_5: 'Келесі 5',
  prev_3: 'Алдыңғы 3',
  next_3: 'Келесі 3',
}
