import ClipperLib from './clipper_unminified.js'

// 设置缩放比例
const scale = 10000

/**
 * 判断path1和path2是否相交
 *
 * @param { Array } path1 第一个多边形路径
 * @param { Array } path2 第二个多边形路径
 * @returns { Boolean } 返回path1是否和path2相交
 */
export function polyIntersect(path1, path2) {
  let poly1 = JSON.parse(JSON.stringify(path1))
  let poly2 = JSON.parse(JSON.stringify(path2))

  ClipperLib.JS.ScaleUpPaths(poly1, scale)
  ClipperLib.JS.ScaleUpPaths(poly2, scale)

  let cpr = new ClipperLib.Clipper()
  cpr.AddPaths(poly1, ClipperLib.PolyType.ptSubject, true)
  cpr.AddPaths(poly2, ClipperLib.PolyType.ptClip, true)
  let subject_fillType = ClipperLib.PolyFillType.pftNonZero
  let clip_fillType = ClipperLib.PolyFillType.pftNonZero
  let solutionPoly = new ClipperLib.Paths()

  cpr.Execute(
    ClipperLib.ClipType.ctIntersection,
    solutionPoly,
    subject_fillType,
    clip_fillType
  )

  // 如果没有求到交集, 则说明两个多边形没有相交, 返回false, 反之返回true
  // if (solutionPoly.length == 0) {
  //   return true
  // } else {
  //   return false
  // }
  // 如果solutionPoly内部收集到的路径数量不为0则说明两个路径出现交集，反之不存在任何关联
  return solutionPoly.length == 0
}

/**
 * 判断path1是否包含path2
 *
 * @param { Array } path1 较大的多边形路径
 * @param { Array } path2 较小的多边形路径
 * @returns { Boolean } 返回path1是否包含path2
 */
export function polyContain(path1, path2) {
  // 先判断两个多边形路径是否相交
  let poly1 = JSON.parse(JSON.stringify(path1))
  let poly2 = JSON.parse(JSON.stringify(path2))

  ClipperLib.JS.ScaleUpPaths(poly1, scale)
  ClipperLib.JS.ScaleUpPaths(poly2, scale)

  let cpr = new ClipperLib.Clipper()
  cpr.AddPaths(poly1, ClipperLib.PolyType.ptSubject, true)
  cpr.AddPaths(poly2, ClipperLib.PolyType.ptClip, true)
  let subject_fillType = ClipperLib.PolyFillType.pftNonZero
  let clip_fillType = ClipperLib.PolyFillType.pftNonZero
  let solutionPoly = new ClipperLib.Paths()

  cpr.Execute(
    ClipperLib.ClipType.ctIntersection,
    solutionPoly,
    subject_fillType,
    clip_fillType
  )

  // 当solutionPoly不存在路径参数时表明path2并不在path1内部或有产生交集
  if (solutionPoly.length == 0) {
    return false
  } else {
    // 如果有相交的路径, 则清除路径, 将相交的路径与较小的多边形路径求异或
    cpr.Clear()
    cpr.AddPaths(poly2, ClipperLib.PolyType.ptSubject, true)
    cpr.AddPaths(solutionPoly, ClipperLib.PolyType.ptClip, true)
    let solutionPoly2 = new ClipperLib.Paths()
    cpr.Execute(
      ClipperLib.ClipType.ctXor,
      solutionPoly2,
      subject_fillType,
      clip_fillType
    )
    // 如果两个路径相等, 则为包含关系, 也就是两个多边形path1, path2相交的路径等于path2, 说明为包含关系
    if (solutionPoly2.length == 0) {
      return true
    } else {
      return false
    }
  }
}

// 路径扩张
export function expandPoly(path, deviation) {
  var poly = JSON.parse(JSON.stringify(path))
  ClipperLib.JS.ScaleUpPath(poly, scale)
  // 外轮廓为扩张, 就是负的刀半径, 挖洞为内缩, 正的刀半径
  let offsett = deviation
  let co = new ClipperLib.ClipperOffset()
  let offsetted_paths = new ClipperLib.Paths()
  co.Clear()
  co.AddPath(
    poly,
    ClipperLib.JoinType.jtMiter,
    ClipperLib.EndType.etClosedPolygon
  )
  co.Execute(offsetted_paths, offsett * scale)

  let result = JSON.parse(JSON.stringify(offsetted_paths))
  for (let i = 0; i < result.length; i++) {
    for (let j = 0; j < result[i].length; j++) {
      result[i][j].x = Number((result[i][j].X / 100).toFixed(3))
      result[i][j].y = Number((result[i][j].Y / 100).toFixed(3))
      delete result[i][j].X
      delete result[i][j].Y
    }
  }
  if (result.length > 0) {
    return result[0]
  }
}
// 判断两个圆是否相交
export function holeIsIntersect(hole1, hole2) {
  const { x: x1, y: y1, diameter: diameter1 } = hole1
  const { x: x2, y: y2, diameter: diameter2 } = hole2
  if (x2 === x1 && y2 === y1 && diameter1 === diameter2) {
    return true
  }
  const d = (+diameter1 + +diameter2) / 2
  const d_x = Math.pow(x2 - x1, 2)
  const d_y = Math.pow(y2 - y1, 2)
  const distance = Math.sqrt(d_x + d_y)
  if (d >= distance) {
    return false
  } else {
    return true
  }
}

export function calcIntersectPoint(a, b, c, d) {
  const denominator = (b.x - a.x) * (d.y - c.y) - (d.x - c.x) * (b.y - a.y)
  if (denominator == 0) return false
  const b1 = (b.y - a.y) * a.x + (a.x - b.x) * a.y
  const b2 = (d.y - c.y) * c.x + (c.x - d.x) * c.y
  const x = (b2 * (b.x - a.x) - b1 * (d.x - c.x)) / denominator
  const y = (b2 * (b.y - a.y) - b1 * (d.y - c.y)) / denominator
  return {
    x: x,
    y: y,
  }
}

/**
 * 判断线段是否出现相交
 * @param {Array[{x,y}]} line1
 * @param {Array[{x,y}]} line2
 */
export function dealLineSegmentIntersect(line1, line2) {
  const [a, b] = line1
  const [c, d] = line2
  // if (a.y > c.y || b.y < d.y || a.x > d.x || b.x < c.x) {
  //   return false
  // }
  const eps = Math.pow(0.1, 9)
  let ac, ad, cb, ca
  ac = (c.x - a.x) * (b.y - a.y) - (c.y - a.y) * (b.x - a.x)
  ad = (d.x - a.x) * (b.y - a.y) - (d.y - a.y) * (b.x - a.x)
  ca = (a.x - c.x) * (d.y - c.y) - (a.y - c.y) * (d.x - c.x)
  cb = (b.x - c.x) * (d.y - c.y) - (b.y - c.y) * (d.x - c.x)
  if (ac * ad < eps && ca * cb < eps) {
    return true
  }
  return false
}
