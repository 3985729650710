import { GetProductionNcSetting } from '@/apis/common/productionNcSetting'
import { getEngravingSetting } from '@/apis/engravingSettings/index'
import { getSaeSetting } from '@/apis/equipment/index'
import { getFileStartNum } from '@/apis/tag'
import { sampleCabinets } from '@/constants'
import store from '@/store'
import { Message, convertBlobToBase64, getUrlParams } from '@/util/commonFuncs'
import { fileNameSetObj } from '@/util/configure'
import { savaFileToDisk } from '@/util/fileSystem/generateFile'
import JsZip from '@/util/jszip'
import { getGraghEdge } from '@/util/tag'
import { getByToken, postByToken } from '@/util/vueRequest'
import { checkIsMergeFolder } from '@/views/newPaiban/util/downLoadNC'
import { dealElectronicSawData } from '@/views/newPaiban/util/downLoadNC'
import OSS from 'ali-oss'
import { message } from 'ant-design-vue'
import axios from 'axios'
import FileSaver from 'file-saver'
import Vue from 'vue'

import { translate } from './commonFun'

// 获取文件数据
function _getFile(url) {
  return new Promise((resolve) => {
    axios({
      method: 'get',
      url,
      responseType: 'arraybuffer',
    })
      .then((data) => {
        resolve(data.data)
      })

      .catch(() => {
        resolve(false)
      })
  })
}

/**
 * 根据value获取key
 * @param {*} obj
 * @param {*} value
 * @returns
 */
export function _getKey(obj, value) {
  for (let key in obj) {
    if (obj[key] === value) {
      return key
    }
  }
}

/**
 * 判断是否在文件合并中
 * @param {*} folderSetting
 * @param {*} type
 * @returns
 */
function _isIncludeMerge(folderSetting, type) {
  // 第一步：通过fileType获得 key
  // 第二步：然后通过key获取到 fileNameSetObj 的值 value
  // 第三部：通过value判断是都在 mergeFolderList 里面
  let value = fileNameSetObj[_getKey(folderSetting, type)] + '文件'
  const mergeFolderList = folderSetting.merge_folder_list
  return mergeFolderList.includes(value)
}

/**
 * 转换文件名
 * @param {*} initName
 * @param {*} folderSetting
 * @param {*} key
 * @returns
 */
// TODO标记 方法
export function _translateFileNameFn(
  initName,
  folderSetting = {},
  key,
  itemData = null
) {
  let needMerge = folderSetting.merge_folder_list?.length > 1
  // 如果为true，那么就不使用合并文件(玻璃切割机)
  if (folderSetting.use_category_folder) {
    needMerge = false
  }

  let translateFileName = initName
  const mergeFolderDisplayName = folderSetting.merge_folder_display_name
  if (key && folderSetting[key]) {
    translateFileName = folderSetting[key]
  }
  let paibanList = ['paiban1', 'paiban2']
  // 生成的文件对应的类型和名称都需要存储起来
  // 先获取当前文件的内容
  let currentFolderContents = JSON.parse(
    JSON.stringify(store.state.folderContents)
  )
  let translateFileNameCopy = translateFileName //保存一个数据副本
  if (key && translateFileName) {
    if (
      paibanList.indexOf(key) === -1 &&
      _getKey(folderSetting, translateFileName) &&
      ((folderSetting.required_folders?.indexOf(
        _getKey(folderSetting, translateFileName)
      ) === -1 &&
        key === translateFileName) ||
        (folderSetting.required_folders?.indexOf(key) === -1 &&
          key !== translateFileName))
    ) {
      translateFileName = ''
    } else {
      if (key === translateFileName) {
        currentFolderContents[_getKey(folderSetting, translateFileName)] =
          translateFileName
      } else {
        currentFolderContents[key] = translateFileName
      }

      store.commit(
        'setFolderContents',
        JSON.parse(JSON.stringify(currentFolderContents))
      )
    }
  }
  // 处理需要合并的情况
  const mergeFolderList = folderSetting.merge_folder_list
  if (needMerge && paibanList.indexOf(key) !== -1) {
    if (mergeFolderList.includes(translateFileNameCopy + '文件')) {
      translateFileName = mergeFolderDisplayName
    }
  } else if (
    needMerge &&
    _isIncludeMerge(folderSetting, translateFileNameCopy)
  ) {
    translateFileName = mergeFolderDisplayName
    if (
      mergeFolderList.length > 1 &&
      folderSetting.platePropertyMergeFile &&
      itemData &&
      mergeFolderList.includes(translateFileNameCopy + '文件')
    ) {
      // 如果满足子文件夹拼接的条件
      translateFileName += dealTransFileName(itemData)
    }
  }
  return translateFileName
}

/**
 * 请求nc下载文件
 * @param {any []} arr 文件数组
 * @param {number} shuntNum 请求分流量
 */
async function genNcFileRequest(
  arr,
  shuntNum = 50,
  zip = new JsZip(),
  cache = {},
  name,
  folderSetting
) {
  const num = Math.ceil(arr.length / shuntNum)
  const shuntRequestList = []
  const fileResultList = []
  // 将数据分割
  for (let index = 0; index < num; index++) {
    const fileList = arr.slice(index * shuntNum, (index + 1) * shuntNum)
    shuntRequestList.push(fileList)
  }
  const len = shuntRequestList.length
  let exeNum = 0
  const _loopRequest = async (fileList) => {
    if (exeNum >= len) return
    exeNum++
    const urlList = fileList.map((item) => {
      let url = ['tag', 'excel'].includes(item.fileType)
        ? item.url
        : 'https://eggrj.oss-cn-hangzhou.aliyuncs.com/' +
          encodeURIComponent(item.filename)
      let fileData = item.fileData

      return fileData
        ? tagFileExport(
            item,
            zip,
            cache,
            name,
            folderSetting,
            item.fileType == 'excel'
          )
        : _getFile(url)
    })
    const result = await Promise.all(urlList)
    /**
     * promise.all返回值顺序是传入的顺序，执行是谁先返回谁先执行
     * 所以直接通过下标访问,将对应的文件数据保存
     */
    fileList.forEach((item, idx) => {
      item.fileBlobResult = result[idx]
    })
    fileResultList.push(fileList)
    await _loopRequest(shuntRequestList.shift())
  }
  await _loopRequest(shuntRequestList.shift())
  return fileResultList.flat(2)
}

export async function genContentPromises(
  arr,
  folderSetting,
  isNeedRecordSize2,
  jsonBlob,
  name,
  setting_id
) {
  let sawSetting
  let saw_setting_res = await getSaeSetting({
    setting_id: store.state.settingId,
    setting_type: 'electronicSaw',
  })
  if (saw_setting_res.data.data) sawSetting = saw_setting_res.data.data

  let ncSetting = store.state.ncSetting
  // 获取生产线配置,解决刷新导致useCategoryFolder和categoryFolderId丢失的问题
  if (setting_id) {
    const nc_setting_res = await GetProductionNcSetting({
      setting_id,
    })
    if (nc_setting_res.data) ncSetting = nc_setting_res.data
  }
  // 兼容从车间下载
  const isNeedRecordSize = true
  let num = 1
  let ncSuffix = []
  let zip = new JsZip()
  const cache = {}
  const {
    useCategoryFolder,
    same_size_sheet_cutting_files,
    label_image_in_nc_folder,
  } = ncSetting.folderCategory
  const data = await genNcFileRequest(arr, 50, zip, cache, name, folderSetting)
  let res = await getEngravingSetting({ setting_id })
  // 双工位合并
  let doubleStationCombine =
    res.data.ncOutputSetting?.fileMerge?.doubleStationCombine ?? false

  const ncGropArr = groupNCData(data)
  const isOnlyStandard = !ncGropArr[0].length
  const hasSurplus = ncGropArr[1].some((item) => {
    const stockKey = item.stock_key
      .split(':')
      .map((i) => {
        if (Number(i)) {
          return Number(i)
        } else return i
      })
      .slice(0, -1)
      .join(':')
    return Object.hasOwnProperty.call(store.state.surplusParams, stockKey)
  })
  // 特殊
  ncGropArr[0].forEach((item) => {
    // 对于特殊大板勾了材质就生成材质文件夹，没勾就不生成。
    // 勾选了尺寸的话，就生成尺寸文件夹，没勾就不生成
    let filename = ''
    let nextFileName = ''
    let plankSize = ''
    // 勾了材质
    if (ncSetting.folderCategory && useCategoryFolder) {
      // 截取stock_key，获取颜色、材质、厚度相同的文件放入相同文件夹
      nextFileName =
        item.stock_key
          .substring(0, item.stock_key.lastIndexOf(':'))
          .replace(/:/g, '_') + '/'
    }
    if (!doubleStationCombine) {
      // 勾了尺寸
      if (same_size_sheet_cutting_files) {
        plankSize = `${item.big_plank_size.width}×${item.big_plank_size.height}/`
      }
      // 都勾选了
      if (same_size_sheet_cutting_files && useCategoryFolder) {
        plankSize = `${item.big_plank_size.width}×${item.big_plank_size.height}/`
      }
    }
    generateFolder(
      item,
      nextFileName,
      useCategoryFolder,
      same_size_sheet_cutting_files,
      filename,
      folderSetting,
      zip,
      jsonBlob,
      cache,
      plankSize,
      num,
      name
    )
  })
  // 余料/标准
  ncGropArr[1].forEach((item) => {
    let filename = ''
    let nextFileName = ''
    let plankSize = ''
    // 勾了材质
    if (ncSetting.folderCategory && useCategoryFolder) {
      // 截取stock_key，获取颜色、材质、厚度相同的文件放入相同文件夹
      nextFileName =
        item.stock_key
          .substring(0, item.stock_key.lastIndexOf(':'))
          .replace(/:/g, '_') + '/'
    }
    if (!doubleStationCombine) {
      // 勾了尺寸
      if (same_size_sheet_cutting_files) {
        if (!isOnlyStandard) {
          if (hasSurplus) {
            plankSize = `${item.big_plank_size.width}×${item.big_plank_size.height}（余料）/`
          } else {
            plankSize = `${item.big_plank_size.width}×${item.big_plank_size.height}/`
          }
        }
      }
      const stockKey = item.stock_key
        .split(':')
        .map((i) => {
          if (Number(i)) {
            return Number(i)
          } else return i
        })
        .slice(0, -1)
        .join(':')
      // 尺寸和材质都勾了
      if (same_size_sheet_cutting_files && useCategoryFolder) {
        if (isNeedRecordSize && !doubleStationCombine) {
          if (!isOnlyStandard) {
            if (
              Object.hasOwnProperty.call(store.state.surplusParams, stockKey)
            ) {
              plankSize = `${item.big_plank_size.width}×${item.big_plank_size.height}（余料）/`
            } else {
              plankSize = `${item.big_plank_size.width}×${item.big_plank_size.height}/`
            }
          }
        }
      }
    }

    generateFolder(
      item,
      nextFileName,
      useCategoryFolder,
      same_size_sheet_cutting_files,
      filename,
      folderSetting,
      zip,
      jsonBlob,
      cache,
      plankSize,
      num,
      name
    )
  })
  const { orderType, orderCounts, OrderTypeMap } = dealElectronicSawData(data)
  data.forEach((item) => {
    if (!item.fileBlobResult) return
    let filename = ''
    let nextFileName = ''
    const isLabelOrNC = item.filetype === 'label' || item.filetype === 'nc'

    // 在下载文件之前对文件夹进行一下分类的设定
    if (isLabelOrNC && ncSetting.folderCategory && useCategoryFolder) {
      // 截取stock_key，获取颜色、材质、厚度相同的文件放入相同文件夹
      nextFileName =
        item.stock_key
          .substring(0, item.stock_key.lastIndexOf(':'))
          .replace(/:/g, '_') + '/'
    }
    if (item.fileType == 'tag') {
      let translateFileName = _translateFileNameFn(
        '标签图',
        folderSetting,
        'label_image_folder'
      )
      if (label_image_in_nc_folder && store.state.isFollowExportTag) {
        translateFileName = _translateFileNameFn(
          '下料文件',
          folderSetting,
          'engraving_folder'
        )
        translateFileName = translateFileName
          ? translateFileName + '/label/image/'
          : ''
      } else {
        translateFileName = translateFileName ? translateFileName + '/' : ''
      }
      let afterType = item.url.endsWith('.pdf') ? '.pdf' : '.zip' //修改.zip文件
      filename = nextFileName + translateFileName + name + afterType
    } else if (item.fileType == 'excel') {
      filename = name + '.xlsx'
    } else if (item.filetype == 'label') {
      let translateFileName = _translateFileNameFn(
        '贴标机',
        folderSetting,
        'labeling_folder'
      )
      if (label_image_in_nc_folder && store.state.isFollowExportTag) {
        translateFileName = _translateFileNameFn(
          '下料文件',
          folderSetting,
          'engraving_folder'
        )
        translateFileName = translateFileName
          ? translateFileName + '/label/'
          : ''
      } else {
        translateFileName = translateFileName ? translateFileName + '/' : ''
      }
      // 子文件夹合并
      if (item.stock_key && checkIsMergeFolder('贴标机文件')) {
        const templateString = dealTransFileName(item)
        translateFileName += templateString
      }
      ncSuffix.push(item.filename.split('.').slice(-1)[0])
      filename = nextFileName + translateFileName + item.file_display_name
    } else if (item.filetype == 'nc') {
      return
    } else if (item.file_type == '玻璃切割机') {
      // filename = nextFileName + '玻璃切割机/' + item.file_display_name
      const { use_category_folder } = store.state.ncSetting.glass_setting ?? {}
      let fName = '玻璃切割机/'
      if (use_category_folder) {
        fName = item.stock_key.replace(/:/g, '_') + '/'
      }
      filename = nextFileName + fName + item.file_display_name
    } else if (item.file_type === 'saw') {
      let translateFileName = _translateFileNameFn(
        'saw',
        folderSetting,
        'electronic_saw_folder'
      )
      translateFileName = translateFileName ? `${translateFileName}/` : ''

      let delimiter = sawSetting?.delimiter || ''
      let lv2Folder = ''
      let lv3Folder = ''
      let nextFileName = ''

      if (sawSetting.rooms_by_category) {
        if (sawSetting.useCategoryFolder) {
          switch (orderType) {
            case OrderTypeMap['noOrder']:
            case OrderTypeMap['singleOrder']:
              if (item.room_name) {
                const newRemark = item.room_remark
                  ? item.room_remark.replace(/[/:?<>\|."*]/g, '')
                  : ''
                nextFileName = `${item.room_name}${
                  newRemark ? delimiter + newRemark : ''
                }`
              } else if (item.partHasRoom) {
                nextFileName = '其他房间'
              }
              break
            case OrderTypeMap['multiOrder']:
              if (item.order_no) {
                const newCustomer = item.customer_name
                  ? item.customer_name.replace(/[/:?<>\|."*]/g, '')
                  : ''
                lv2Folder = `${item.order_no}${
                  newCustomer ? delimiter + newCustomer : ''
                }`
              } else {
                lv2Folder = '其他订单'
              }
              if (item.room_name) {
                const newRemark = item.room_remark
                  ? item.room_remark.replace(/[/:?<>\|."*]/g, '')
                  : ''
                lv3Folder = `${item.room_name}${
                  newRemark ? delimiter + newRemark : ''
                }`
              } else if (
                orderCounts[item.order_no]?.length !== 1 &&
                orderCounts[item.order_no].some((item) => item.room_name)
              ) {
                lv3Folder = item.partHasRoom ? '其他房间' : ''
              }
              nextFileName = `${lv2Folder}${lv3Folder ? '/' + lv3Folder : ''}`
              break
            default:
              break
          }
        } else {
          switch (orderType) {
            case OrderTypeMap['noOrder']:
              break
            case OrderTypeMap['singleOrder']:
              break
            case OrderTypeMap['multiOrder']:
              if (item?.partHasOrder) {
                nextFileName = '其他订单'
              } else {
                const newCustomer = item.customer_name
                  ? item.customer_name.replace(/[/:?<>\|."*]/g, '')
                  : ''
                nextFileName = `${item.order_no}${
                  newCustomer ? delimiter + newCustomer : ''
                }`
              }
              break
            default:
              break
          }
        }
      }

      translateFileName += nextFileName ? `${nextFileName}/` : ''

      if (item.file_display_name) {
        filename = `${translateFileName}${item.file_display_name}`
      } else {
        filename = `${translateFileName}${item.filename.split('/')[1]}`
      }

      filename = dealGroupFile(item, filename)
    } else {
      let translateFileName = _translateFileNameFn(
        item.filetype,
        folderSetting,
        item.filetype,
        item
      )
      translateFileName = translateFileName ? translateFileName + '/' : ''
      if (item.file_display_name) {
        filename = translateFileName + item.file_display_name
      } else {
        filename = translateFileName + item.filename.split('/')[1]
      }
      filename = dealGroupFile(item, filename)
    }

    let nameList = filename.split('/')
    const ignoreList = [
      'PTP_folder',
      'dock5F_drill_folder',
      'dock6F_drill_folder',
      'side_hole_folder',
      'electronic_saw_folder',
    ].map((item) => (folderSetting ? folderSetting[item] : ''))
    if (nameList.length) {
      if (
        nameList[0] === folderSetting?.merge_folder_display_name &&
        nameList[1] &&
        store.state.ncSetting?.customizeFolder?.merge_folder_list.length > 1 &&
        !ignoreList.includes(
          item.filetype ?? folderSetting['electronic_saw_folder']
        )
      ) {
        if (store.state.tempFileName.includes(nameList[nameList.length - 1])) {
          let tempList = JSON.parse(JSON.stringify(store.state.repeatFileName))
          tempList.push(nameList[nameList.length - 1])
          store.commit('setRepeatFileName', tempList)
        } else {
          let tempList = JSON.parse(JSON.stringify(store.state.tempFileName))
          tempList.push(nameList[nameList.length - 1])
          store.commit('setTempFileName', tempList)
        }
      }
    }
    zip.file(filename, item.fileBlobResult, { binary: true }) // 逐个添加文件
    if (jsonBlob) {
      zip.file(`${name}.json`, jsonBlob)
    }
    cache[filename] = item.fileBlobResult
  })
  return {
    zip,
    cache,
    promises: data,
    ncSuffix,
  }
}

// 返回二维数组 0:特殊大板 1:余料/标准
function groupNCData(arr) {
  const ncArr = arr.filter((item) => item.filetype === 'nc')
  return ncArr.reduce(
    (acc, cur) => {
      if (!acc[0]) acc[0] = []
      if (!acc[1]) acc[1] = []
      if (cur.big_plank_size && cur.big_plank_size.otherPlate) {
        acc[0].push(cur)
      } else {
        acc[1].push(cur)
      }
      return acc
    },
    [[], []]
  )
}
function generateFolder(
  item,
  nextFileName,
  useCategoryFolder,
  same_size_sheet_cutting_files,
  filename,
  folderSetting,
  zip,
  jsonBlob,
  cache,
  plankSize,
  num,
  name
) {
  let translateFileName = _translateFileNameFn(
    '下料文件',
    folderSetting,
    'engraving_folder'
  )
  // 如当前nc的文件类型实际上为anc(因为nc和anc文件的filetype相同都是nc),同时文件合并设置开启了一体机的文件名则按照一体机文件名的方式进行生成文件名称
  if (isUseAioFolder(folderSetting, item)) {
    translateFileName = _translateFileNameFn(
      '一体机文件',
      folderSetting,
      'aio_folder'
    )
  }
  translateFileName = translateFileName ? translateFileName + '/' : ''

  if (item.stock_key && checkIsMergeFolder('雕刻机文件')) {
    if (!checkIsMergeFolder('雕刻机文件')) {
      filename += plankSize
    }
    const templateString = dealTransFileName(item)
    translateFileName += templateString
  }
  filename = nextFileName + translateFileName
  if (!checkIsMergeFolder('雕刻机文件')) {
    if (
      (useCategoryFolder && same_size_sheet_cutting_files) ||
      (!useCategoryFolder && same_size_sheet_cutting_files)
    ) {
      filename += plankSize
    }
  }
  if (item.file_display_name == '') {
    filename =
      filename +
      ('00' + num).substr(-3, 3) +
      '' +
      (item.direction == 'front' ? '正.' : '反.') +
      item.filetype
    num++
  } else {
    filename += item.file_display_name
  }

  let nameList = filename.split('/')
  const ignoreList = [
    'PTP_folder',
    'dock5F_drill_folder',
    'dock6F_drill_folder',
    'side_hole_folder',
  ].map((item) => (folderSetting ? folderSetting[item] : ''))
  if (nameList.length) {
    if (
      nameList[0] === folderSetting?.merge_folder_display_name &&
      nameList[1] &&
      store.state.ncSetting?.customizeFolder?.merge_folder_list.length > 1 &&
      !ignoreList.includes(item.filetype)
    ) {
      if (store.state.tempFileName.includes(nameList[nameList.length - 1])) {
        let tempList = JSON.parse(JSON.stringify(store.state.repeatFileName))
        tempList.push(nameList[nameList.length - 1])
        store.commit('setRepeatFileName', tempList)
      } else {
        let tempList = JSON.parse(JSON.stringify(store.state.tempFileName))
        tempList.push(nameList[nameList.length - 1])
        store.commit('setTempFileName', tempList)
      }
    }
  }
  zip.file(filename, item.fileBlobResult, { binary: true }) // 逐个添加文件
  if (jsonBlob) {
    zip.file(`${name}.json`, jsonBlob)
  }
  cache[filename] = item.fileBlobResult
}
export async function genMergeSubFolderData(
  data,
  isNeedSplice = true,
  ncSetting,
  flag
) {
  const date = new Date()
  const mounth =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()

  const stockKeyArr = Array.from(
    new Set(
      data
        .filter((d) => d.stockKey || d.stock_key)
        .map((item) => {
          let sizeList
          let size
          if (flag == 'paibanData') {
            let copyItem = JSON.parse(JSON.stringify(item))
            if (
              copyItem?.surplusInfo?.width > copyItem?.surplusInfo?.height &&
              store.state.ncSetting.xyReverse
            ) {
              // 交换两个的数据
              let copy = copyItem.surplusInfo.width
              copyItem.surplusInfo.width = copyItem.surplusInfo.height
              copyItem.surplusInfo.height = copy
            }
            size = getGraghEdge(copyItem, ncSetting).size
            sizeList = size.split('*').splice(0, 2)
            size = `${sizeList[0]}×${sizeList[1]}`
            if (
              (copyItem.surplusInfo &&
                !copyItem.surplusInfo?.isNotSurplus &&
                copyItem.surplusInfo.shape == 'lshape' &&
                store.state.ncSetting.xyReverse) ||
              !store.state.ncSetting.xyReverse ||
              store.state.ncSetting.startPosition == '右下角' ||
              store.state.ncSetting.startPosition == '左上角'
            ) {
              size = `${sizeList[1]}×${sizeList[0]}`
            }
          } else {
            if (item.display_big_plank_size) {
              sizeList = item.display_big_plank_size.split('*').splice(0, 2)
              size = `${sizeList[0]}×${sizeList[1]}`
            }
          }
          return isNeedSplice
            ? (item.stockKey ?? item.stock_key)
                .split(':')
                .slice(0, -1)
                .map((i, idx) => {
                  if (idx == 2) {
                    return Number(i)
                  } else {
                    return i
                  }
                })
                .join(':') + `:${size}`
            : item.stockKey ?? item.stock_key
        })
    )
  )
  let startRes = '0001'
  const mergeSetting =
    store.state.mergeFolderSetting ?? store.state.ncSetting.customizeFolder
  if (
    mergeSetting.platePropertyMergeFile &&
    mergeSetting.subfolderName.templateString.includes('文件数自增')
  ) {
    startRes = await getFileStartNum({
      plateTypeNum: stockKeyArr.length,
      fileStartNum: mergeSetting.fileStartNum,
      settingId:
        store.state.ncSetting.process_setting_id ??
        mergeSetting.process_setting_id,
    })
  }
  const startNum = startRes.data ?? startRes
  store.commit('setMergeSubFolderData', {
    date: `${mounth}${day}`,
    startNum,
    stockKeyArr,
  })
}

export function dealTransFileName(item, isNeedSlice = true) {
  if (!item.size && !item.display_big_plank_size) {
    return ''
  }
  const mergeSubFolderData = store.state.mergeSubFolderData
  const stockKeyArr = mergeSubFolderData.stockKeyArr
  const date = mergeSubFolderData.date
  let templateString = ''
  // 传进来的size不需要厚度
  let sizeList =
    item.size?.split('*')?.splice(0, 2) ??
    item.display_big_plank_size?.split('*')?.splice(0, 2)
  // 文件夹名称内不能出现*特殊符号
  let plankSize = `${sizeList[0]}×${sizeList[1]}`
  const partKeysArr = isNeedSlice
    ? item.stock_key.split(':').slice(0, -1)
    : item.stock_key.split(':')
  const stockKeyStr = partKeysArr.join(':') + `:${plankSize}`
  const stockNo = stockKeyArr.indexOf(stockKeyStr)
  if (stockNo != -1) {
    let finalNo = Number(mergeSubFolderData.startNum) + stockNo
    const subLenth =
      String(mergeSubFolderData.startNum).length - String(finalNo).length
    if (subLenth > 0) {
      finalNo = '0'.repeat(subLenth) + finalNo
    }
    const subFolderMap = new Map([
      ['{输出日期}', date],
      ['{文件数自增}', finalNo],
      ['{板件颜色}', partKeysArr[0]],
      ['{板件类型}', partKeysArr[1]],
      ['{板件厚度}', partKeysArr[2]],
      ['{板件长宽}', plankSize],
    ])

    const folderSetting =
      store.state.mergeFolderSetting ?? store.state.ncSetting.customizeFolder
    templateString = folderSetting.subfolderName.templateString + '/'
    subFolderMap.forEach((val, key) => {
      templateString = templateString.replace(key, val)
    })
  }
  return templateString
}

/**
 * 把promise文件转换成zip文件
 * @param {*} zip
 * @param {*} promises
 * @param {*} zipName
 * @param {*} thenCb
 * @param {*} finalCb
 * @param {*} imgZip 生成的imgZip对象
 * @param {*} ncSuffix 贴标文件后缀
 * @param {*} ncSetting
 * @param {*} isOpenSingleFile 开启生成单个文件(后台设置的(贴标管理))
 */
export async function promiseToZip(
  zip,
  promises,
  zipName,
  thenCb,
  finalCb,
  that,
  ncSetting
) {
  const { imgZip, ncLifeFuncObj } = that
  const { genZip } = ncLifeFuncObj ?? {}
  // 获取下载NC中的标签图片Zip路径，并移除掉
  if (imgZip && Object.keys(imgZip).length) {
    let zipPath = ''
    let translateFileName = _translateFileNameFn(
      '标签图',
      ncSetting?.customizeFolder,
      'label_image_folder'
    )
    Object.keys(zip.files).forEach((key) => {
      if (key.startsWith(translateFileName) && key.endsWith('.zip')) {
        zipPath = key
      }
    })
    zip.remove(zipPath)
  }

  zip.generateAsync({ type: 'blob' }).then(async (content) => {
    // 生成二进制流 将二进制流转化为文件对象
    let time = new Date()
    let ncName = `/bluenMaterialData/saw_${time.getTime()}.zip`
    let file = new File([content], ncName, {
      type: '',
      lastModified: Date.now(),
    })
    Vue.prototype.$getByToken('/get_oss_token', {}, (res) => {
      if (res.status == 1) {
        let client = new OSS({
          region: 'oss-cn-hangzhou',
          accessKeyId: res.data.AccessKeyId,
          accessKeySecret: res.data.AccessKeySecret,
          stsToken: res.data.SecurityToken,
          bucket: 'eggrj',
        })
        client
          .multipartUpload(ncName, file)
          .then(() => {
            let url = 'https://eggrj.oss-cn-hangzhou.aliyuncs.com' + ncName
            if (typeof thenCb === 'function') {
              thenCb(url)
            }
          })
          .catch(() => {
            Vue.prototype.$message({
              type: 'error',
              message: '上传失败!',
            })
          })
      }
    })
    let flag = false
    // 是否采用zip的形式导出 否则是采用文件直接写入
    if (store.state.preferencesSetting.setting?.is_use_folder_export) {
      flag = await savaFileToDisk(zip, zipName)
    }
    if (!flag) {
      FileSaver.saveAs(content, `${zipName}.zip`) // 利用file-saver保存文件
    }
    genZip && genZip('end')
    that.isShowNCTask = false
    if (typeof finalCb === 'function') {
      finalCb()
    }
  })
}

// 将data数据保存到Oss
export function saveDataJsonToOss(data, cb) {
  let blob = ''
  if (typeof data === 'object') {
    blob = new Blob([JSON.stringify(data)], { type: '' })
  } else {
    blob = new Blob([data], { type: '' })
  }
  // 存储
  const name =
    '/bluenMaterialData/operateRecord/' +
    new Date().getTime() +
    '_' +
    'operateRecord' +
    '.json'
  let file = new File([blob], name, { type: '', lastModified: Date.now() })
  getByToken('/get_oss_token', {}, (res) => {
    if (res.status == 1) {
      let client = new OSS({
        region: 'oss-cn-hangzhou',
        accessKeyId: res.data.AccessKeyId,
        accessKeySecret: res.data.AccessKeySecret,
        stsToken: res.data.SecurityToken,
        bucket: 'eggrj',
      })
      client
        .multipartUpload(name, file)
        .then(() => {
          let url = 'https://eggrj.oss-cn-hangzhou.aliyuncs.com' + name
          if (typeof cb === 'function') {
            cb(url)
          }
        })
        .catch((err) => {
          console.error(err)
          Message({
            type: 'error',
            info: '上传失败!',
            duration: 1500,
            offset: 60,
          })
        })
    } else {
      Message({
        type: 'error',
        info: '身份信息已过期, 请重新从柜柜跳转!',
        duration: 1500,
        offset: 60,
      })
    }
  })
}

// 为了操作记录保存到oss
export function saveDataJsonToOssForRecord(record, type, option = {}, cb) {
  saveDataJsonToOss(record, (url) => {
    let obj = {
      uid: store.state.userInfo.id,
      // setting_id: this.activeProductId,
      action_type: type,
      action_url: url,
      // 用于还原设备数据
      operate_equipment: option.operate_equipment || '', // 修改的设备
      save_data: option.save_data || '', // 保存的数据
      setting_id: option.setting_id || 0, // 产品线id
      operator_phone: option.operatorPhone || '', // 后台应用生产线设置时，记录操作人的uid
    }
    if (option?.id) {
      obj.setting_id = option.id
    }
    obj.process_line_name =
      option?.name || getUrlParams('line') || store.state.currentProductLineName
    postByToken('/save_operation_record', obj, () => {
      if (typeof cb === 'function') {
        cb()
      }
    })
  })
}

// 处理五六面钻分文件
export function dealGroupFile(file, fileName) {
  let nameArr = fileName.split('/')
  const order_code = file.group_file ? file.order_code : null
  if (!order_code) return fileName
  const last = nameArr[nameArr.length - 1]
  return [...nameArr.slice(0, -1), order_code, last].join('/')
}

async function tagFileExport(
  tagFile,
  zip,
  cache,
  name,
  folderSetting,
  isExcel = false
) {
  let translateFileName = _translateFileNameFn(
    '标签图',
    folderSetting,
    'label_image_folder'
  )
  translateFileName = translateFileName ? translateFileName + '/' : ''
  // let translateFileName = '标签图'
  let filename = translateFileName + name + `.${tagFile?.exportType}`
  if (isExcel) filename = name + `.${tagFile?.exportType}`
  const fr = new FileReader()
  await fr.readAsArrayBuffer(tagFile.fileData)
  return new Promise((resolve) => {
    fr.addEventListener('loadend', (event) => {
      const res = event.target.result
      zip.file(filename, res, { binary: true }) // 逐个添加文件
      cache[filename] = res
      resolve()
    })
  })
}

/**
 * @description 检查是否是否需要生成一体机的单独文件夹
 * @param {object} folderSetting 自定义文件设置
 * @returns
 */
function isUseAioFolder(folderSetting, ncData) {
  return (
    folderSetting?.required_folders?.includes('aio_folder') &&
    ncData?.aio_file_type === 'aio'
  )
}
