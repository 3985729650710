import { Browser } from '@/util/commonFun'

/**
 * @description 检测当前 环境是否支持file sys api
 */
export function isSupportFileSysApi() {
  const {
    isSecureContext,
    showOpenFilePicker,
    showSaveFilePicker,
    showDirectoryPicker,
  } = window as any
  return (
    isSecureContext &&
    showOpenFilePicker &&
    showSaveFilePicker &&
    showDirectoryPicker
  )
}

/**
 * @description 是否支持本地直接生成文件夹，内嵌不需要我们做处理
 */
export function isSupportLocalGenFile() {
  const { webToQt } = window as any
  return isSupportFileSysApi() && !webToQt && Browser.isEdge()
}

/**
 * @description 切割文件路径分离出文件名和文件夹路径
 * @param filePath 文件路径
 * @returns
 */
export function splitPath(filePath: string) {
  const arr = filePath.split(/[/\\]/).filter(Boolean)
  let fileName = ''
  let folderPath = ''
  if (arr.length === 1) {
    fileName = arr[0]
  } else {
    fileName = arr.pop()!
    // 需补充一个 / 表明为一个文件夹路径
    folderPath = arr.join('/') + '/'
  }
  return {
    fileName,
    folderPath,
  }
}

/**
 * @description 获取当前文件下的所有已存在的文件夹名称
 * @param handle 文件处理句柄
 * @returns
 */
export async function getFolderAllFile(handle: any) {
  const files: string[] = []
  const folders: string[] = []
  for await (const [k, v] of handle.entries()) {
    if (v?.kind === 'directory') {
      folders.push(k)
    } else {
      files.push(k)
    }
  }
  return {
    files,
    folders,
  }
}

/**
 * @description 检测当前句柄是否还存在
 * @param handle 文件句柄
 * @returns
 */
export async function folderIsExist(handle: any) {
  if (!handle) return false
  try {
    for await (const iterator of handle.entries()) {
      break
    }
    return true
  } catch (error) {
    return false
  }
}

/**
 * @description  创建文件夹名称，重复的话会加上(number)
 * @param folderName 需要创建的文件名
 * @param folders 已存在的文件名组
 * @returns
 */
export function createFolderName(folderName: string, folders: string[]) {
  let number = 1
  let newFolderName = folderName
  while (folders.includes(newFolderName)) {
    newFolderName = `${folderName}(${number++})`
  }
  return newFolderName
}

/**
 * 根据路径创建对应的文件夹
 * @param handle 文件处理句柄
 * @param filePath 文件路径
 * @returns 文件处理句柄
 */
export async function createFolder(handle: any, filePath: string) {
  // 如果传入路径是一个文件路径则排除掉文件本身那一层
  if (isFile(filePath)) {
    filePath = filePath.substring(0, filePath.lastIndexOf('/'))
  }
  const foldername = filePath.split('/').map((name) => name.trim())
  // 文件处理句柄
  let fileHandle = handle
  for (const folder of foldername) {
    if (folder) {
      fileHandle = await fileHandle.getDirectoryHandle(
        deleteFilePathSymbol(folder),
        {
          create: true,
        }
      )
    }
  }
  return fileHandle
}

/**
 * 是否是文件夹
 * @param filePath 文件路径
 * @returns
 */
export function isDirectory(filePath: string) {
  return filePath.endsWith('/') || filePath.endsWith('\\')
}

/**
 * 是否是文件
 * @param filePath 文件路径
 * @returns
 */
function isFile(filePath: string) {
  const extPattern = /\.[^\/\\]+$/
  return extPattern.test(filePath)
}

/**
 * 返回路径上的文件名称
 * @param path 文件路径
 * @returns
 */
export function basename(filePath: string, ext?: string) {
  const baseName = filePath.substring(filePath.lastIndexOf('/') + 1)
  if (ext && baseName.endsWith(ext)) {
    return baseName.slice(0, -ext.length)
  }
  return baseName
}

/**
 * 返回路径上的文件后缀
 * @param path 文件路径
 * @returns
 */
export function extname(filePath: string) {
  const dotIndex = filePath.lastIndexOf('.')
  if (dotIndex > 0 && dotIndex < filePath.length - 1) {
    return filePath.substring(dotIndex)
  }
  return ''
}

/**
 * @description 检查权限，内部会自动判断是否需要申请权限
 * @param handle 文件操作句柄
 * @param mode 申请的权限
 * @returns
 */
type IMode = 'read' | 'readwrite'
export async function verifyPermission(handle: any, mode: IMode = 'readwrite') {
  try {
    // 检测当前使用的句柄是否拥有申请的权限
    if (await queryPermission(handle, mode)) {
      return true
    }
    // 未授权的情况下向用户申请权限
    if (await requestPermission(handle, mode)) {
      return true
    }
    // 用户拒绝授权
    return false
  } catch (error) {
    console.error(error)
    return false
  }
}

/**
 * @description 查询权限
 * @param handle 句柄
 * @param mode 模式
 * @returns
 */
export async function queryPermission(handle: any, mode: IMode = 'readwrite') {
  try {
    return (await handle?.queryPermission({ mode })) === 'granted'
  } catch (error) {
    return false
  }
}

/**
 * @description 申请权限
 * @param handle 句柄
 * @param mode 模式
 * @returns
 */
export async function requestPermission(
  handle: any,
  mode: IMode = 'readwrite'
) {
  try {
    return (await handle?.requestPermission({ mode })) === 'granted'
  } catch (error) {
    return false
  }
}

/**
 * @description 删除文件上的特色符号，避免由于文件名影响导致无法生成文件
 * @param str 文件名
 */
export function deleteFilePathSymbol(str: string) {
  // 清除...
  if (str.endsWith('...')) str = str.slice(0, -3)
  return str
}
