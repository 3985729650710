export enum DrawPlankColor {
  /** 活跃板件背景色 */
  active = '#94E2AD',
  /** 普通板件背景色 */
  normal = 'rgba(238, 238, 161, 0.8)',
  /** 板件冲突背景色 */
  plankMerge = '#F008',
  /** 孔槽冲突背景色 */
  holeSlotMerge = '#F808',
  /** 余料板件背景色 */
  surplus = 'rgba(255, 200, 200, 0.8)',
  /** 夹手背景色 */
  clampHand = 'rgba(170, 170, 170)',
}

/** js文件使用 */
export const DrawPlankColorJs = {
  /** 活跃板件背景色 */
  active: DrawPlankColor.active,
  /** 普通板件背景色 */
  normal: DrawPlankColor.normal,
  /** 板件冲突背景色 */
  plankMerge: DrawPlankColor.plankMerge,
  /** 孔槽冲突背景色 */
  holeSlotMerge: DrawPlankColor.holeSlotMerge,
  /** 余料板件背景色 */
  surplus: DrawPlankColor.surplus,
}
