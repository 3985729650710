import html2Canvas from 'html2canvas'
import jsPDF from 'jspdf'

const defaultDpi = getDeviceXDPI()
export function pxTomm(value, arrDPI = defaultDpi) {
  var inch = value / arrDPI[0]
  var c_value = inch * 25.4
  return +c_value.toFixed(2)
}
export function mmToPx(value, arrDPI = defaultDpi) {
  var inch = value / 25.4
  var c_value = inch * arrDPI[0]
  return +c_value.toFixed(2)
}
export function getDeviceXDPI() {
  var arrDPI = new Array()
  if (window.screen.deviceXDPI) {
    arrDPI[0] = window.screen.deviceXDPI
    arrDPI[1] = window.screen.deviceYDPI
  } else {
    var tmpNode = document.createElement('DIV')
    tmpNode.style.cssText =
      'width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden'
    document.body.appendChild(tmpNode)
    arrDPI[0] = parseInt(tmpNode.offsetWidth)
    arrDPI[1] = parseInt(tmpNode.offsetHeight)
    tmpNode.parentNode.removeChild(tmpNode)
  }
  return arrDPI
}
export function PtToPx(pt, arrDPI) {
  return parseInt((arrDPI[0] / 72) * pt)
}
export function PxToPt(px) {}
export function exportPDF(options = {}, canvas = [], callBack) {
  var title = options.name
  let width = canvas[0].width / options.scale
  let height = canvas[0].height / options.scale
  let arrPI = getDeviceXDPI()
  let mmWidth = pxTomm(width, arrPI)
  let mmHeight = pxTomm(height, arrPI)
  let pdf = new jsPDF('l', 'mm', [mmWidth, mmHeight])
  if (typeof window.webToQt !== 'undefined') {
    let pdfImgArr = []
    for (let i = 0; i < canvas.length; i++) {
      var pageData = options.tegTem[i].mycanvas.toDataURL({
        multiplier: 2,
      })
      let newUrl = pageData.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
      pdfImgArr.push(newUrl)
    }
    let webPdfObj = {
      fileName: options.name + '.pdf',
      imgArr: pdfImgArr,
    }
    callBack(webPdfObj)
    return
  }
  let promiseAll = []
  for (let i = 0; i < canvas.length; i++) {
    // let item = canvas[i]

    let prom = new Promise((resolve) => {
      var pageData = options.tegTem[i].mycanvas.toDataURL({
        multiplier: 2,
      })
      var img = new Image()
      img.src = pageData
      img.onload = () => {
        img.width = img.width / options.scale ? options.scale : 1
        img.height = img.height / options.scale ? options.scale : 1
        const imgRatio = img.width / img.height
        if (i > 0) {
          if (imgRatio > 0) {
            pdf.addPage([mmWidth, mmHeight])
          } else {
            pdf.addPage([mmWidth, mmHeight], 'p')
          }
        }
        pdf.setPage(i + 1)
        pdf.addImage(img, 'JPEG', 0, 0, mmWidth, mmHeight, null, 'NONE')
        resolve(pdf)
        // if (i == canvas.length - 1) {
        //     pdf.save(options.name);
        //     callBack()
        // }
      }
    })
    promiseAll.push(prom)

    // html2Canvas(item, {
    //     allowTaint: true,
    // }).then((can) => {
    //     var context = can.getContext('2d')
    //     // context.mozImageSmoothingEnabled = false
    //     // context.webkitImageSmoothingEnabled = false
    //     // context.msImageSmoothingEnabled = false
    //     // context.imageSmoothingEnabled = false
    //     // var pageData = can.toDataURL('image/jpeg', 1.0);

    // })
  }
  Promise.all(promiseAll).then(() => {
    pdf.save(options.name)
    callBack()
  })
}
