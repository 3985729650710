import type { Module } from 'vuex'

import type { IRootState } from '../../types'
import type { IStore } from './types'

const Lang: Module<IStore, IRootState> = {
  namespaced: true,
  state: {
    lang: 'zh',
  },
  mutations: {
    setLang(state, val) {
      state.lang = val
    },
  },
}

export default Lang
