import _extends from 'babel-runtime/helpers/extends'

import TimePickerLocale from '../time-picker'

const CalendarLocale = {
  today: 'Бүгін',
  now: 'Қазір',
  backToToday: 'Ағымдағы күн',
  ok: 'Таңдау',
  clear: 'Таза',
  month: 'Ай',
  year: 'Жыл',
  timeSelect: 'Уақытты таңдау',
  dateSelect: 'Күнді таңдау',
  weekSelect: 'апта таңдау',
  monthSelect: 'Айды таңдаңыз',
  yearSelect: 'Жылды таңдаңыз',
  decadeSelect: 'Онжылды таңдаңыз',
  yearFormat: 'YYYY',
  dateFormat: 'D-M-YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D-M-YYYY HH:mm:ss',
  previousMonth: 'Алдыңғы ай (PageUp)',
  nextMonth: 'Келесі ай (PageDown)',
  previousYear: 'Алдыңғы жыл (Control + left)',
  nextYear: 'Келесі жыл (Control + right)',
  previousDecade: 'Алдыңғы онжылдық',
  nextDecade: 'Келесі онжылдық',
  previousCentury: 'Алдыңғы ғасыр',
  nextCentury: 'Келесі ғасыр',
}

var locale = {
  lang: _extends(
    {
      placeholder: 'Күнді таңдауыңызды сұраймыз',
      rangePlaceholder: ['Басталу күні', 'Аяқталу күні'],
    },
    CalendarLocale
  ),
  timePickerLocale: _extends({}, TimePickerLocale),
}

locale.lang.ok = 'Сенімдісің бе'

export default locale
