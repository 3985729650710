// index.ts
import enJson from './json/en'
import kzJson from './json/kz'
import viJson from './json/vi'
import zhJson from './json/zh'

export const messages = {
  zh: zhJson,
  en: enJson,
  vi: viJson,
  kz: kzJson,
}
