import { httpRequest } from '@/apis/request'

enum Apis {
  PreLayout = 'pre_layout',
  Compare = 'pre_layout/clash',
}

/**
 * @description 获取预排布设置
 * @param payload 参数，具体见文档
 * @link https://api.thinkerx.com/web/#/162/12498
 */
export function getPrelayoutSetting() {
  return httpRequest.get(Apis.PreLayout)
}

/**
 * @description 保存预排布设置
 * @param payload 参数，具体见文档  setting_value为预排版设置  setting_id为当前预排版设置id
 * @link https://api.thinkerx.com/web/#/162/12499
 */
export function savePrelayoutSetting(payload: {
  setting_value: any
  setting_id?: number
}) {
  return httpRequest.post(Apis.PreLayout, payload)
}

/**
 * @description 获取预排布设置对比结果
 * @param payload 参数，具体见文档  setting_value为预排版设置  process_setting_id为对比的生产线
 * @link https://api.thinkerx.com/web/#/162/12500
 */
export function comparePreLayoutSetting(payload: {
  setting_value: any
  process_setting_id: number
}) {
  return httpRequest.post(Apis.Compare, payload)
}
