import type { PreferencesSetting } from '@/store/modules/preferencesSetting/types'
import type { IStateType } from '@/store/types'
import { EventBusKey } from '@/util/bus'
import EventBus from '@/util/bus'
import { Store } from 'vuex'

export type PreferenceUpdateFnParam = {
  data: Partial<PreferencesSetting>
  isErr?: boolean
}
export type PrefCb = (data: PreferenceUpdateFnParam) => void
/**
 * 对偏好设置进行监听以及相关的销毁操作
 * 如果监听的源非路由页面，且需要移除监听需要调用 remove 来移除监听器，路由页面会自动销毁
 */
export default {
  install(Vue: any, store: Store<IStateType>) {
    Vue.prototype.$preference = {
      /** 记录所有已经监听的函数 */
      handlers: new Map<string, PrefCb[]>(),
      /**
       * @description 初始化偏好设置监听
       * @param updateCb 偏好设置更新后的回调
       * @param listenSource 需要监听的源
       */
      init(updateCb: PrefCb, listenSource: string) {
        if (!updateCb) {
          throw new Error('需要一个回调函数来执行偏好设置的更新')
        }
        if (!listenSource) {
          throw new Error('需要提供一个源用于移除监听器(需保证唯一性)')
        }
        const preference = store.state.preferencesSetting!.setting
        const isDone = store.state.preferencesSetting?.isLoadDone
        if (isDone) {
          updateCb({ data: preference, isErr: false })
        }
        this.listen(updateCb, listenSource)
      },
      /**
       * @description 增加监听函数
       * @param updateCb
       * @param listenSource
       * @returns
       */
      listen(updateCb: PrefCb, listenSource: string) {
        const handlers = this.handlers.get(listenSource) ?? []
        // 监听过的函数不再监听，避免调用失误导致多次监听
        if (handlers.some((handle: PrefCb) => handle === updateCb)) {
          return
        }
        handlers.push(updateCb)
        // 记录所有监听过的函数
        this.handlers.set(listenSource, handlers)
        EventBus.$on(EventBusKey.PREFERENCES_UPDATE, updateCb)
      },
      /**
       * @description 移除监听
       * @param listenSource
       * @param clearFunc
       * @returns
       */
      remove(listenSource: string, clearFunc?: PrefCb) {
        const handlers = this.handlers.get(listenSource)
        if (!handlers) return
        if (clearFunc) {
          const index = handlers.findIndex(
            (handle: any) => handle === clearFunc
          )
          if (index !== -1) handlers.splice(index, 1)
          // 不存在数据了之间删除相关的存储
          if (handlers.length === 0) this.handlers.delete(listenSource)
        } else {
          handlers.forEach((handle: any) => {
            EventBus.$off(EventBusKey.PREFERENCES_UPDATE, handle)
          })
          this.handlers.delete(listenSource)
        }
      },
    }
    // 全局路由守卫处理监听的销毁
    Vue.mixin({
      beforeRouteLeave(to: any, from: any, next: any) {
        if (Vue.prototype.$preference.handlers.has(from.path)) {
          Vue.prototype.$preference.remove(from.path)
        }
        next()
      },
    })
  },
}
