<template>
  <div class="user_editPwd">
    <div class="edit_warp">
      <div class="edit_head">
        <span>{{ $t('main.head.editPassword') }}</span>
        <span class="cursor"
          ><i
            style="fontsize: 12px; color: rgba(0, 0, 0, 0.45)"
            class="iconfont icon-close"
            @click="hide"
            id="cancel_amend_nickname_btn"
          ></i
        ></span>
      </div>
      <div class="edit_pwd_form">
        <div class="form_item">
          <p class="form_item_title">{{ $t('login.tell') }}</p>
          <div class="form_item_input">
            <input
              type="text"
              :placeholder="$t('common.tellPlaceholder')"
              v-model="loginMsg.phone"
              id="amend_phone_input"
            />
          </div>
        </div>
        <div class="form_item">
          <p class="form_item_title">{{ $t('login.password') }}</p>
          <div class="form_item_input">
            <input
              type="password"
              :placeholder="$t('login.passwordPlaceholder')"
              v-model="loginMsg.pwd"
              id="amend_password_input"
            />
          </div>
        </div>
        <div class="form_item">
          <p class="form_item_title">{{ $t('login.confimPassword') }}</p>
          <div class="form_item_input">
            <input
              type="password"
              :placeholder="$t('login.confimPasswordPlaceholder')"
              v-model="loginMsg.surePwd"
              id="repeat_amend_password_input"
            />
          </div>
        </div>
        <div class="form_item">
          <p class="form_item_title">{{ $t('login.verifyCode') }}</p>
          <div class="form_item_input form_item_btn">
            <input
              type="text"
              :placeholder="$t('lockModal.captchaPlaceholder')"
              v-model="loginMsg.code"
              id="verification_code_input"
            />
            <span
              class="get_code cursor"
              :style="{ color: isGetCode ? '#999999' : '#18A8C7' }"
              @click="getCode"
              id="get_verification_code_btn"
              >{{
                isGetCode
                  ? `${time}${$t('login.reSend')}`
                  : $t('login.getVerifyCode')
              }}</span
            >
          </div>
        </div>
      </div>
      <div class="edit_pwd_sure">
        <div
          class="edit_pwd_sureBtn cursor"
          @click="editPwd"
          id="sure__amend_password_btn"
        >
          {{ $t('common.confirm') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import commonFun from '../util/commonFun'

let md5 = require('md5')

export default {
  data() {
    return {
      isGetCode: false,
      time: 60,
      loginMsg: {
        phone: '',
        pwd: '',
        surePwd: '',
        code: '',
      },
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    ...mapMutations(['setEditPwdvisible']),
    hide(e) {
      if (e.currentTarget == e.target) {
        this.setEditPwdvisible(false)
      }
    },
    getCode() {
      if (this.isGetCode) return
      if (!this.loginMsg.phone.match(/^1[3|4|7|5|8|6|9][0-9]\d{4,8}$/)) {
        this.$message({
          message: this.$t('login.tellErr'),
          type: 'error',
          duration: 2000,
        })
        return
      }
      let key = {
        phone: this.loginMsg.phone,
        sms_type: 'reset_password',
      }
      let RSAKey = commonFun.calcKeyByRSA(key)
      this.$post(
        '/send_code',
        key,
        (res) => {
          if (res.status == 1) {
            this.isGetCode = true
            this.$message({
              message: this.$t('lockModal.sendSeccess'),
              type: 'success',
              duration: 2000,
            })
            this.getCodeTimeout = setInterval(() => {
              this.time -= 1
              if (!this.time) {
                this.isGetCode = false
                clearInterval(this.getCodeTimeout)
                this.time = 60
              }
            }, 1000)
          } else {
            this.isGetCode = false
            this.$message({
              message: res.msg,
              type: 'warning',
              duration: 2000,
            })
          }
        },
        { sign: RSAKey }
      )
    },
    editPwd() {
      if (this.isRegister) return
      if (!this.loginMsg.phone.match(/^1[3|4|7|5|8|6|9][0-9]\d{4,8}$/)) {
        this.$message({
          message: this.$t('login.tellErr'),
          type: 'error',
          duration: 2000,
        })
        return
      }
      if (!this.loginMsg.pwd) {
        this.$message({
          message: this.$t('login.passwordErr'),
          type: 'error',
          duration: 2000,
        })
        return
      }
      if (this.loginMsg.pwd != this.loginMsg.surePwd) {
        this.$message({
          message: '两次密码输入不一致',
          type: 'error',
          duration: 2000,
        })
        return
      }
      if (!this.loginMsg.code) {
        this.$message({
          message: '请输入验证码',
          type: 'error',
          duration: 2000,
        })
        return
      }
      let key = {
        password: md5(this.loginMsg.pwd),
        phone: this.loginMsg.phone,
        repeat_password: md5(this.loginMsg.surePwd),
        sms_code: this.loginMsg.code,
      }
      let RSAKey = commonFun.calcKeyByRSA(key)
      this.$post(
        '/reset_password',
        key,
        (res) => {
          if (res.status == 1) {
            this.$message({
              type: 'success',
              message: '修改成功，请重新登录',
              duration: 2000,
            })
            this.setEditPwdvisible(false)
            for (let key in sessionStorage) {
              sessionStorage.removeItem(key)
            }
            this.$store.commit('clearAllInfo')
            setTimeout(() => {
              this.$router.push({
                path: '/login',
              })
            }, 1000)
          } else {
            this.$message({
              type: 'error',
              message: res.msg,
              duration: 2000,
            })
          }
        },
        { sign: RSAKey }
      )
    },
  },
  mounted() {
    if (this.userInfo.hasOwnProperty('id')) {
      this.loginMsg.phone = this.userInfo.phone
    }
  },
  beforeDestroy() {
    clearInterval(this.getCodeTimeout)
  },
}
</script>

<style lang="less" scoped>
.user_editPwd {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 2003;
  .cursor {
    cursor: pointer;
    user-select: none;
  }
  .edit_warp {
    width: 380px;
    background-color: #fff;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 4px;
    padding-bottom: 24px;
    > div {
      padding: 0 24px;
    }
    .edit_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      span {
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
      }
    }
    .edit_pwd_form {
      padding: 24px 24px 0;
      color: #333;
      .form_item {
        .form_item_title {
          margin-bottom: 8px;
        }
        .form_item_input {
          height: 40px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #e5e5e5;
          display: flex;
          align-items: center;
          margin-bottom: 24px;
          input {
            width: 90%;
            outline: none;
            border: none;
            text-indent: 16px;
            &::placeholder {
              color: rgba(176, 176, 176, 1);
            }
          }
        }
        .form_item_btn {
          padding-right: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          input {
            width: 70%;
          }
          // .get_code{
          //     cursor: pointer;
          // }
        }
      }
    }
    .edit_pwd_sure {
      padding: 0 24px;
      .edit_pwd_sureBtn {
        height: 48px;
        line-height: 48px;
        text-align: center;
        color: #fff;
        font-size: 16px;
        letter-spacing: 4px;
        background: #18a8c7;
        border-radius: 4px;
      }
    }
  }
}
</style>
