import { httpRequest } from '@/apis/request'

import type { BujianStore } from './types'

enum Apis {
  plankToStore = 'workshop/supplements',
  delete = 'workshop/supplements',
}

/**
 * @description 添加板件到补件仓库或待排版仓库
 * @link https://api.thinkerx.com/web/#/p/912743f65abd18e1f64912104a6f5418
 * @returns
 */
export function addPlankToStore(params: BujianStore) {
  return httpRequest.post(Apis.plankToStore, params)
}
/**
 * @description 获取补件/待排版库数据
 * @link https://api.thinkerx.com/web/#/p/912743f65abd18e1f64912104a6f5418
 * @returns
 */
export function fetchStoreData(params: any) {
  return httpRequest.get(Apis.plankToStore, params)
}
/**
 * @description 删除补件/待排版库数据
 * @link https://api.thinkerx.com/web/#/p/912743f65abd18e1f64912104a6f5418
 * @returns
 */
export function deleteStoreData(params: { ids: string; new: string }) {
  return httpRequest.delete(
    `${Apis.delete}?ids=${params.ids}&new=${params.new}`
  )
}
