import { getSaeSetting } from '@/apis/equipment'
import { sampleCdn } from '@/constants'
import store from '@/store'
import { splitTime } from '@/util/commonFuncs'
import { fileNameSetObj } from '@/util/configure'
import { dealGuimenFGNo } from '@/util/plankCommonFuncs'
import {
  _translateFileNameFn,
  dealTransFileName,
  genContentPromises,
} from '@/util/saveFile'
import FileSaver from 'file-saver'
import Vue from 'vue'

/**
 *
 * @param arr 文件信息数组
 * @param name 打包名称
 * @param imgArr 排版图数组
 * @param isNeedRecordSize 是否需要记录
 * @param paibanWayName 排版历史名称
 * @param mergeFolderFun 文件夹合并方法
 * @param ncSetting nc设置
 * @param callBack 回调函数
 * @returns 无
 */

export async function downloadFile(
  /** 全部文件数组 */
  arr: any[],
  name: string,
  imgArr: any[],
  isNeedRecordSize: boolean,
  paibanWayName?: string,
  mergeFolderFun?: any,
  ncSetting?: any,
  callBack?: () => void,
  jsonBlob?: any,
  operateFun?: () => void
) {
  const len = arr.length
  let err_tip = true //防止多次弹出提示
  const error_message: any[] = []
  for (let i = 0; i < len; ++i) {
    const cut_it = arr[i]
    if (cut_it.error_info && err_tip && !cut_it.plank_size_abnormal) {
      err_tip = false
      return {
        isErr: true,
        errMsg: cut_it.error_info,
      }
    }
    if (cut_it.filetype == 'nc' && cut_it.plank_size_abnormal) {
      error_message.push(cut_it.error_info)
      return {
        isErr: true,
        errMsg: cut_it.error_info,
      }
    }
  }
  if (error_message.length) {
    Vue.prototype.$modalMsg(error_message, 'error', '警告')
  }

  mergeFolderFun(ncSetting)
  const win = window as any
  // 内嵌
  if (win.webToQt) {
    const res = await dealWebToQtData(arr, imgArr, ncSetting)
    if (res && operateFun) {
      operateFun()
      return res
    }
    if (callBack) callBack()
    return
  }

  const { cache, zip, promises, ncSuffix }: any = await genContentPromises(
    arr,
    ncSetting?.customizeFolder,
    isNeedRecordSize,
    jsonBlob,
    name,
    // 需要兼容删除生产线问题 不适用store.state.ncSetting?.process_setting_id
    ncSetting?.process_setting_id
  )
  // 判断合并文件夹 排班页下载NC -- 排版图
  for (const key in imgArr) {
    imgArr[key].forEach((item: any) => {
      let translateFileName = _translateFileNameFn(
        `排版图${item.index}`,
        ncSetting.customizeFolder,
        `paiban${item.index}`
      )
      if (checkIsMergeFolder(`排版图${item.index}文件`)) {
        const tempFileNameStr = dealTransFileName(item).slice(0, -1)
        translateFileName += `/${tempFileNameStr}`
      }
      const fileName = translateFileName + '/' + item.name
      const url = item.url.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
      const nameList = fileName.split('/')
      if (nameList.length) {
        if (
          nameList[0] === ncSetting.customizeFolder.merge_folder_display_name &&
          nameList[1] &&
          store.state.ncSetting.customizeFolder?.merge_folder_list.length > 1
        ) {
          if (
            store.state.tempFileName.includes(nameList[nameList.length - 1])
          ) {
            const tempList = JSON.parse(
              JSON.stringify(store.state.repeatFileName)
            )
            tempList.push(nameList[nameList.length - 1])
            store.commit('setRepeatFileName', tempList)
          } else {
            const tempList = JSON.parse(
              JSON.stringify(store.state.tempFileName)
            )
            tempList.push(nameList[nameList.length - 1])
            store.commit('setTempFileName', tempList)
          }
        }
      }
      zip.file(fileName, url, { base64: true })
      cache[fileName] = url
    })
  }
  const timeName = `历史方案 ${splitTime()}`
  const noArr = dealGuimenFGNo(store.state.preLayoutData)
  const orderName = noArr.join(',')
  return {
    zip,
    nameStr: name,
    orderName,
    timeName,
    promises,
    ncSuffix,
  }
}

export function mergeFolder(ncSetting: any) {
  const folderSetting = ncSetting
    ? ncSetting
    : store.state.ncSetting.customizeFolder
  /** 意义不明的代码 */
  // const mergeFolderList = folderSetting.merge_folder_list
  // const mergeFolderDisplayName = folderSetting.merge_folder_display_name
  // 如果有合并文件
  let needMerge = folderSetting?.merge_folder_list?.length > 1
  if (folderSetting?.use_category_folder) {
    // 如果为true，那么就不使用合并文件
    needMerge = false
  }
  return needMerge
}

// 处理web和客户端通信时的数据 内嵌
// TODO标记
export async function dealWebToQtData(
  arr: any[],
  imgArr: any[],
  ncSetting: any
) {
  try {
    const qtData = []
    // 处理排版图
    for (const key in imgArr) {
      imgArr[key]?.forEach((item: any) => {
        const url = item.url.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')

        let translateFileName = _translateFileNameFn(
          `排版图${item.index}`,
          ncSetting.customizeFolder,
          `paiban${item.index}`
        )
        if (checkIsMergeFolder(`排版图${item.index}文件`)) {
          const tempFileNameStr = dealTransFileName(item).slice(0, -1)
          translateFileName += `/${tempFileNameStr}`
        }
        const obj = {
          originFile: translateFileName,
          fileName: item.name,
          url: url,
          fileType: 'base64',
        }
        qtData.push(obj)
      })
    }

    let repeatZipFileNameList = [] as any
    const tempZipFileNameList = [] as any
    const ignoreList = [
      'PTP_folder',
      'dock5F_drill_folder',
      'dock6F_drill_folder',
      'side_hole_folder',
      'electronic_saw_folder',
    ].map((item) =>
      ncSetting.customizeFolder ? ncSetting.customizeFolder[item] : ''
    )
    let sawSetting
    const saw_setting_res = await getSaeSetting({
      setting_id: store.state.settingId,
      setting_type: 'electronicSaw',
    })
    if (saw_setting_res.data.data) sawSetting = saw_setting_res.data.data
    const { orderType, orderCounts, OrderTypeMap } = dealElectronicSawData(arr)
    // 处理下料文件
    let num = 1
    for (let i = 0; i < arr.length; ++i) {
      const item = arr[i]
      const url =
        arr[i].fileType == 'tag'
          ? arr[i].url
          : 'https://eggrj.oss-cn-hangzhou.aliyuncs.com/' + arr[i].filename
      // 如果是贴标机文件
      let prefixName = ''
      let originFile = ''
      let fileName = ''
      let nextFileName = ''
      const { useCategoryFolder, label_image_in_nc_folder } =
        ncSetting.folderCategory
      if (
        ncSetting.folderCategory &&
        useCategoryFolder &&
        (arr[i].filetype == 'label' || arr[i].filetype == 'nc')
      ) {
        prefixName =
          arr[i].stock_key
            .substring(0, arr[i].stock_key.lastIndexOf(':'))
            .replace(/:/g, '_') + '/'
      }
      const obj = {} as { [key: string]: any }
      if (arr[i].filetype === 'label') {
        originFile =
          prefixName +
          _translateFileNameFn(
            '贴标机',
            ncSetting.customizeFolder,
            'labeling_folder'
          )
        if (label_image_in_nc_folder && store.state.isFollowExportTag) {
          originFile =
            prefixName +
            _translateFileNameFn(
              '下料文件',
              ncSetting.customizeFolder,
              'engraving_folder'
            ) +
            '/label/'
        }
        if (item.stock_key && checkIsMergeFolder('贴标机文件')) {
          const templateString = dealTransFileName(item)
          originFile = originFile.endsWith('/')
            ? `${originFile}${templateString}`
            : `${originFile}/${templateString}`
        }

        fileName = arr[i].file_display_name
      } else if (arr[i].filetype === 'nc') {
        originFile =
          prefixName +
          _translateFileNameFn(
            `下料文件`,
            ncSetting.customizeFolder,
            'engraving_folder'
          )
        if (item.stock_key && checkIsMergeFolder('雕刻机文件')) {
          const templateString = dealTransFileName(item)
          originFile = originFile.endsWith('/')
            ? `${originFile}${templateString}`
            : `${originFile}/${templateString}`
        }
        if (arr[i].file_display_name == '') {
          fileName =
            ('00' + num).substr(-3, 3) +
            '_' +
            (arr[i].direction == 'front' ? '正.' : '反.') +
            arr[i].filetype
          num++
        } else {
          fileName = arr[i].file_display_name
        }
      } else if (arr[i].file_type == '玻璃切割机') {
        let fName = '玻璃切割机'
        const { use_category_folder } = ncSetting.glass_setting ?? {}
        if (use_category_folder) {
          fName = arr[i].stock_key.replace(/:/g, '_') + '/'
        }
        originFile = prefixName + fName
        fileName = arr[i].file_display_name
      } else if (arr[i].fileType == 'tag') {
        if (arr[i].exportType === 'pdf') {
          // pdf
          FileSaver.saveAs(arr[i].fileData, '标签图.pdf')
        } else {
          if (arr[i].tagData && arr[i].tagData?.length) {
            arr[i].tagData.forEach((item: any) => {
              const url = item.url.replace(
                /^data:image\/(png|jpg|jpeg);base64,/,
                ''
              )
              let oriFile = _translateFileNameFn(
                '标签图',
                ncSetting.customizeFolder,
                'label_image_folder'
              )
              if (label_image_in_nc_folder && store.state.isFollowExportTag) {
                oriFile = _translateFileNameFn(
                  '下料文件',
                  ncSetting.customizeFolder,
                  'engraving_folder'
                )
                oriFile = oriFile ? oriFile + '/label/image/' : ''
              }
              if (item.stock_key && checkIsMergeFolder('贴标图片文件')) {
                const templateString = dealTransFileName(item)
                oriFile = oriFile.endsWith('/')
                  ? `${oriFile}${templateString}`
                  : `${oriFile}/${templateString}`
              }
              const obj = {
                originFile: oriFile,
                fileName: `${item.fileName}.${
                  ncSetting.labelImageFormat || 'jpg'
                }`,
                url: url,
                fileType: 'base64',
              }
              qtData.push(obj)
            })
          } else {
            originFile = _translateFileNameFn(
              '标签图',
              ncSetting.customizeFolder,
              'label_image_folder'
            )
            if (label_image_in_nc_folder && store.state.isFollowExportTag) {
              originFile = _translateFileNameFn(
                '下料文件',
                ncSetting.customizeFolder,
                'engraving_folder'
              )
              originFile = originFile ? originFile + '/label/image/' : ''
            }
            const afterType = arr[i].url.endsWith('.pdf') ? '.pdf' : '.zip'
            if (arr[i].file_display_name) {
              fileName = arr[i].file_display_name + afterType
            } else {
              fileName = arr[i].filename + afterType
            }
          }
        }
      } else if (arr[i].file_type === 'saw') {
        originFile = _translateFileNameFn(
          'saw',
          ncSetting.customizeFolder,
          'electronic_saw_folder'
        )
        originFile = originFile ? `${originFile}/` : ''

        if (sawSetting.rooms_by_category) {
          const delimiter = sawSetting?.delimiter || ''
          let lv2Folder = ''
          let lv3Folder = ''
          if (sawSetting.useCategoryFolder) {
            switch (orderType) {
              case OrderTypeMap['noOrder']:
              case OrderTypeMap['singleOrder']:
                if (arr[i].room_name) {
                  const newRemark = arr[i].room_remark
                    ? arr[i].room_remark.replace(/[/:?<>\|."*]/g, '')
                    : ''
                  nextFileName = `${arr[i].room_name}${
                    newRemark ? delimiter + newRemark : ''
                  }`
                } else if (arr[i].partHasRoom) {
                  nextFileName = '其他房间'
                }
                break
              case OrderTypeMap['multiOrder']:
                if (arr[i].order_no) {
                  const newCustomer = arr[i].customer_name
                    ? arr[i].customer_name.replace(/[/:?<>\|."*]/g, '')
                    : ''
                  lv2Folder = `${arr[i].order_no}${
                    newCustomer ? delimiter + newCustomer : ''
                  }`
                } else {
                  lv2Folder = '其他订单'
                }
                if (arr[i].room_name) {
                  const newRemark = arr[i].room_remark
                    ? arr[i].room_remark.replace(/[/:?<>\|."*]/g, '')
                    : ''
                  lv3Folder = `${arr[i].room_name}${
                    newRemark ? delimiter + newRemark : ''
                  }`
                } else if (
                  orderCounts[arr[i].order_no]?.length !== 1 &&
                  orderCounts[arr[i].order_no].some(
                    (item: any) => item.room_name
                  )
                ) {
                  lv3Folder = arr[i].partHasRoom ? '其他房间' : ''
                }
                nextFileName = `${lv2Folder}${lv3Folder ? '/' + lv3Folder : ''}`
                break
              default:
                break
            }
          } else {
            switch (orderType) {
              case OrderTypeMap['noOrder']:
                break
              case OrderTypeMap['singleOrder']:
                break
              case OrderTypeMap['multiOrder']:
                if (arr[i]?.partHasOrder) {
                  nextFileName = '其他订单'
                } else {
                  const newCustomer = arr[i].customer_name
                    ? arr[i].customer_name.replace(/[/:?<>\|."*]/g, '')
                    : ''
                  nextFileName = `${arr[i].order_no}${
                    newCustomer ? delimiter + newCustomer : ''
                  }`
                }
                break
              default:
                break
            }
          }

          originFile += nextFileName ? `${nextFileName}/` : ''
        }

        if (arr[i].file_display_name) {
          fileName = arr[i].file_display_name
        } else {
          fileName = arr[i].fileName.split('/')[1]
        }
      } else {
        originFile = _translateFileNameFn(
          arr[i].filetype ?? arr[i].fileType,
          ncSetting.customizeFolder,
          arr[i].filetype
        )
        if (arr[i].file_display_name) {
          fileName = arr[i].file_display_name
        } else {
          fileName = arr[i].filename?.split('/')[1]
        }
      }
      obj.url = url
      obj.fileName = fileName
      obj.originFile = originFile
      obj.fileType = 'url'
      qtData.push(obj)
      if (
        obj.originFile.indexOf(
          ncSetting.customizeFolder?.merge_folder_display_name
        ) !== -1 &&
        !ignoreList.includes(
          arr[i].filetype ?? ncSetting.customizeFolder['electronic_saw_folder']
        )
      ) {
        if (tempZipFileNameList.includes(obj.fileName)) {
          repeatZipFileNameList.push(obj.fileName)
        } else {
          // 存入值
          tempZipFileNameList.push(obj.fileName)
        }
      }
    }
    let repeatNameList = [] as any
    const repeatZipFolderNameList = [] as any
    // if (qtData) {
    //   // 遍历数据
    //   qtData.forEach((item) => {
    //     if (
    //       item.originFile.indexOf(
    //         ncSetting.customizeFolder?.merge_folder_display_name
    //       ) !== -1
    //     ) {
    //       if (tempZipFileNameList.includes(item.fileName)) {
    //         repeatZipFileNameList.push(item.fileName)
    //       } else {
    //         // 存入值
    //         tempZipFileNameList.push(item.fileName)
    //       }
    //     }
    //   })
    // }
    Object.keys(store.state?.folderContents)?.forEach((item) => {
      if (
        !ncSetting?.customizeFolder?.merge_folder_list.includes(
          fileNameSetObj[item] + '文件'
        ) &&
        ncSetting?.customizeFolder?.merge_folder_list.length > 1
      ) {
        if (
          ncSetting.customizeFolder.merge_folder_display_name.trim() ===
          store.state.folderContents[item].trim()
        ) {
          repeatZipFolderNameList.push(fileNameSetObj[item])
        }
      }
    })
    // 检查数据 TODO
    repeatZipFileNameList = [...new Set(repeatZipFileNameList)]
    repeatNameList = []
      .concat(repeatZipFolderNameList)
      .concat(repeatZipFileNameList)
    if (repeatNameList.length) {
      return {
        repeatNameList,
        repeatZipFolderNameList,
        repeatZipFileNameList,
        qtRepeat: true,
        zip: null,
        nameStr: null,
      }
    }
    if (
      (store.state.orderInfo && store.state.isTrialProduct) ||
      store.state.produceFileUrl
    ) {
      qtData.push(...sampleCdn)
    }
    dataToQt(qtData.filter((obj) => obj.url))
    return
  } catch (err) {
    Vue.prototype.$message({
      message: err,
      type: 'error',
    })
    throw err
  }
}
// 电子锯数据处理
export const dealElectronicSawData = (arr: any[]) => {
  const OrderTypeMap = {
    singleOrder: '单订单',
    multiOrder: '多订单',
    noOrder: '无订单',
  }
  let orderType = OrderTypeMap['singleOrder']
  const hasOrderNo = arr.some((item) => item.order_no)
  const hasNoOrderNo = arr.some((item) => !item.order_no)
  const hasRoom = arr.some((item) => item.room_name)
  const hasNoRoom = arr.some((item) => item.room_name)
  const getOrderNos = (data: any[]) => {
    return data
      .filter((item) => {
        if (item.file_type === 'saw') {
          return item
        }
      })
      .filter((item) => item.order_no)
      .map((item) => item.order_no)
  }
  // 获取所有order_no
  const allOrderNos = getOrderNos(arr)
  const uniqueOrderNos = new Set(allOrderNos) // 检查是否有数据缺少order_no
  // 根据订单数量设置orderType
  const hasMissingOrderNo = arr
    .filter((item) => {
      if (item.file_type === 'saw') return item
    })
    .some((item) => !item.order_no)
  if (!hasOrderNo) {
    orderType = OrderTypeMap['noOrder']
  } else if (hasMissingOrderNo || uniqueOrderNos.size > 1) {
    orderType = OrderTypeMap['multiOrder']
  } else if (uniqueOrderNos.size === 1) {
    orderType = OrderTypeMap['singleOrder']
  }
  if (hasOrderNo && hasNoOrderNo) {
    arr.forEach((item) => {
      if (!item.order_no) {
        item.partHasOrder = true
      }
    })
  }
  if (hasRoom && hasNoRoom) {
    arr.forEach((item) => {
      if (!item.room_name) {
        item.partHasRoom = true
      }
    })
  }
  const orderCounts = arr.reduce((accumulator, current) => {
    const key = current.order_no
    if (!accumulator[key]) {
      accumulator[key] = []
    }
    accumulator[key].push(current)
    return accumulator
  }, {})
  return {
    orderType,
    orderCounts,
    hasOrderNo,
    hasNoOrderNo,
    hasRoom,
    hasNoRoom,
    OrderTypeMap,
  }
}
// 处理web和客户端通信时的数据
function dataToQt(qtData: any, oriArr?: any) {
  const paibanDataObj = {
    layoutData: store.state.preLayoutData,
    paibanData: oriArr ?? store.state.paibanData,
    process_setting_id: store.state.ncSetting.process_setting_id,
    process_setting_name: store.state.ncSetting.process_setting_name,
    recodeSelectPart: store.state.recodeSelectPart,
    recodeSelectSurplus: store.state.recodeSelectSurplus,
    thinkerxMaterialKeys: store.state.thinkerxMaterialKeys,
  }
  const ncData = {
    layoutData: paibanDataObj,
    ncData: qtData,
    process_setting_id: store.state.ncSetting.process_setting_id,
  }
  const win = window as any

  win.webToQt.ncDataToQtV2
    ? win.webToQt.ncDataToQtV2(ncData)
    : win.webToQt.ncDataToQt(qtData)
}

/**
 * 检查是否需要合并子文件夹
 * @param key 是否在合并文件夹选项中
 * @returns true false
 */
export function checkIsMergeFolder(key: string) {
  const { merge_folder_list, platePropertyMergeFile } =
    store.state.mergeFolderSetting ?? store.state.ncSetting.customizeFolder
  return (
    platePropertyMergeFile &&
    merge_folder_list.length > 1 &&
    merge_folder_list.includes(key)
  )
}
