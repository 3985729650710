import store from '@/store'
import { getPlateKnifeDiameter } from '@/util/dealPaibanData'
import { Message } from 'element-ui'
import Vue from 'vue'

// 计算单张大板的优化率(暂时只计算矩形面积的比例)
export function calcBigpartArea(bigpart, nc_setting, isUnuseSurplus) {
  let area = 0
  let areaAll = 0
  // 获取存储在大板中的修边信息
  const edge = bigpart.margin ?? nc_setting.drawPlankEdgeOff ?? 0
  let plankArea = 0
  for (let i = 0; i < bigpart.parts.length; ++i) {
    const arr = bigpart.parts[i].polyArr[0].map((it) => ({ x: it.X, y: it.Y }))
    if (!bigpart.parts[i].surplusPath) {
      if (bigpart.parts[i].path) {
        const arr = bigpart.parts[i].path[0].map((it) => ({
          x: Number(it.x.toFixed(2)),
          y: Number(it.y.toFixed(2)),
        }))
        plankArea += Math.abs(window.ClipperLib.Clipper.Area(arr))
      } else {
        plankArea +=
          Number(bigpart.parts[i].realRect.width.toFixed(2)) *
          Number(bigpart.parts[i].realRect.height.toFixed(2))
      }
    }
    let insideArea = 0
    bigpart.parts[i].path?.map((poly, index) => {
      if (index) {
        insideArea += Math.abs(window.ClipperLib.Clipper.Area(poly))
      }
    })
    const curveHolesArr = bigpart.parts[i].curveHoles?.map((it) => it.path)
    curveHolesArr?.map((poly) => {
      insideArea += Math.abs(window.ClipperLib.Clipper.Area(poly))
    })
    if (isUnuseSurplus && bigpart.parts[i].surplusPath) {
      area += 0
    } else {
      area += Math.abs(window.ClipperLib.Clipper.Area(arr)) - insideArea
    }
    if (!bigpart.parts[i].surplusPath) {
      if (bigpart.parts[i].path) {
        areaAll += Math.abs(window.ClipperLib.Clipper.Area(arr)) - insideArea
      } else {
        areaAll +=
          Number(bigpart.parts[i].realRect.width.toFixed(2)) *
            Number(bigpart.parts[i].realRect.height.toFixed(2)) -
          insideArea
      }
    }
  }
  if (bigpart.surplusInfo && Object.keys(bigpart.surplusInfo).length > 0) {
    // 玻璃切割机也是余料优化率按照矩形计算
    if (bigpart.surplusInfo.isNotSurplus || nc_setting.glass_setting) {
      bigpart.usedRate =
        area /
        ((bigpart.surplusInfo.width - edge * 2) *
          (bigpart.surplusInfo.height - edge * 2))
    } else {
      bigpart.usedRate = area / (bigpart.surplusInfo.area * 1000 * 1000)
    }
  } else {
    bigpart.usedRate =
      area /
      ((bigpart.plankWidth - edge * 2) * (bigpart.plankHeight - edge * 2))
  }
  bigpart.usedArea = areaAll
  return { usedRate: bigpart.usedRate, usedArea: bigpart.usedArea }
}

// 绘制完余料后, 重新设置下刀点和下刀顺序
export function calcCutPositionOrder(planks, ncSetting, userInfo) {
  if (!planks.length) return
  return new Promise((resolve) => {
    let plankWidth =
      ncSetting.drawPlankWidth ?? store.state.selectStandardPlank.plankWidth
    let plankHeight =
      ncSetting.drawPlankHeight ?? store.state.selectStandardPlank.plankHeight
    let { startPosition, xyReverse, cutDirection, surplusPosition } = ncSetting
    let rectMap = {}
    let plankMap = {}
    const stockKey = planks[0]?.stockKey
    const diameter = +getPlateKnifeDiameter(stockKey, ncSetting)
    let cutKnifeR = diameter / 2

    const realSurplusPosition = calcSurplusPositionByStartPosition(
      startPosition,
      surplusPosition,
      xyReverse
    )

    planks.forEach((bigpart) => {
      let title = `${bigpart.texture}:${bigpart.matCode}:${bigpart.thick}`
      for (let k = 0; k < bigpart.parts.length; ++k) {
        let part = bigpart.parts[k]

        const { startX, startY } = calcSurplusPosition(
          realSurplusPosition,
          part,
          cutKnifeR,
          { plankWidth, plankHeight }
        )

        let partRectWidth = 0
        let partRectHeight = 0
        partRectWidth = part.rect.width
        partRectHeight = part.rect.height
        let partInfo = {
          width: partRectWidth,
          height: partRectHeight,
          // startX: part.startX + cutKnifeR,
          // startY: plankHeight - partRectHeight - part.startY + cutKnifeR,
          startX: Number(startX),
          startY: Number(startY),
          isRotated: part.isRotated ? part.isRotated : false,
          partIndex: part.index,
          stockNum: part.stockNum,
        }
        if (part.surplusInfo) {
          partInfo.surplusInfo = part.surplusInfo
        }
        if (rectMap[title]) {
          rectMap[title].push(partInfo)
          plankMap[title].push(bigpart)
        } else {
          rectMap[title] = [partInfo]
          plankMap[title] = [bigpart]
        }
      }
    })

    let obj = {
      ncConfig: {
        plankWidth: plankWidth ?? ncSetting.panelSize.plankWidth,
        plankHeight: plankHeight ?? ncSetting.panelSize.plankHeight,
        startPosition: calcStartPositionBySurplus(realSurplusPosition),
        // xyReverse: xyReverse,
        cutDirection: cutDirection ?? '逆时针',
        gap: ncSetting.panelSize.layoutGap,
        surplus_position: realSurplusPosition,
        new_cut_sequence: !!ncSetting.movePlankSetting?.strong_suction_zones
          .length
          ? Boolean(ncSetting.movePlankSetting?.newCutSequence)
          : false,
        strong_suction_zones: ncSetting.movePlankSetting?.strong_suction_zones,
        safe_length: ncSetting.movePlankSetting?.safe_length,
        scattered_layout: ncSetting.movePlankSetting?.scattered_layout,
      },
      layoutRectMap: rectMap,
      uid: userInfo.id,
    }
    if (sessionStorage.getItem('thinkerx_material')) {
      obj['from'] = '门窗erp'
    }
    Vue.prototype.$token('/get_cal_priority', obj, (res) => {
      if (res.status == 1) {
        Object.keys(res.result.data).forEach((title) => {
          let newPointArr = res.result.data[title]
          for (let i = 0; i < newPointArr.length; ++i) {
            let bigpart = plankMap[title][i]
            for (let k = 0; k < bigpart.parts.length; ++k) {
              let plank = bigpart.parts[k]
              if (plank.index === newPointArr[i].index) {
                plank.priority = newPointArr[i].priority
                plank.cutOrigin = newPointArr[i].cutOrigin
              }
            }
          }
        })
      } else {
        Message({
          type: 'error',
          message: '计算下刀点失败!',
        })
      }
      resolve(true)
    })
  })
}

/**
 * @param {Object} ctx 画布对象
 * @param {Number} x 中心坐标
 * @param {Number} y 中心坐标
 * @param {Number} r 圆圈半径
 *
 */

export function circleText(ctx, x, y, r, text, scale) {
  const textInfo = calcTextSize(text)
  ctx.beginPath()
  ctx.arc(x, y, r, 0, 2 * Math.PI)
  ctx.stroke()
  ctx.closePath()
  ctx.fillText(
    text,
    x - (textInfo.width * scale) / 2,
    y + (textInfo.height * scale) / 4
  )
}

function calcTextSize(text) {
  let span = document.createElement('span')
  let result = {}
  result.width = span.offsetWidth
  result.height = span.offsetHeight
  span.style.visibility = 'hidden'
  span.style.fontSize = `14px`
  span.style.fontFamily = 'PingFangSC-Regular, PingFang SC'
  span.style.display = 'inline-block'
  document.body.appendChild(span)
  if (typeof span.textContent != 'undefined') {
    span.textContent = text
  } else {
    span.innerText = text
  }
  result.width = parseFloat(window.getComputedStyle(span).width) - result.width
  result.height =
    parseFloat(window.getComputedStyle(span).height) - result.height
  document.body.removeChild(span)
  return result
}

export function dealPlankType(paibanData) {
  let realPlankList = paibanData
  let matCodeObj = {}
  // 聚类统计
  realPlankList.forEach((data) => {
    const matCodeArr = data.stockKey.split(':')
    matCodeArr.pop()
    const type =
      matCodeArr.join(':') +
      (data.otherPlate
        ? `:特殊大板${data.plankHeight}*${data.plankWidth}`
        : data.surplusInfo && !data.surplusInfo.isNotSurplus
        ? `余料大板${data.plankHeight}*${data.plankWidth}`
        : '')
    if (matCodeObj[type]) {
      matCodeObj[type].push(data)
    } else {
      matCodeObj[type] = [data]
    }
  })

  const result = Object.keys(matCodeObj).map((key) => {
    const plank = matCodeObj[key][0]
    let matCode = plank.matCode
    if (plank.parts[0] && plank.parts[0].is_high_gloss_plank) {
      matCode
    }
    return {
      matCode,
      texture: plank.texture,
      thick: plank.thick,
      height:
        key.includes('余料大板') && plank.surplusInfo?.shape == 'lshape'
          ? `${plank.plankHeight}(${plank.surplusInfo.y4})`
          : plank.plankHeight,
      width:
        key.includes('余料大板') && plank.surplusInfo?.shape == 'lshape'
          ? `${plank.plankWidth}(${plank.surplusInfo.x4})`
          : plank.plankWidth,
      count: matCodeObj[key].length,
      plankType: key.includes('特殊大板')
        ? 'special'
        : key.includes('余料大板')
        ? 'surplus'
        : 'normal',
      branch_name: key.includes('余料大板')
        ? plank.surplusInfo.branch_name == '-'
          ? '余料清单'
          : plank.surplusInfo.branch_name
        : '-',
    }
  })
  // 统计封边信息
  const parts = paibanData.map((d) => d.parts).flat(1)
  // 按厚度分组
  const partGroup = Array.from(parts).reduce((res, cur) => {
    const groupKey = String(cur.thick) + ',' + cur['texture']
    if (res.hasOwnProperty(groupKey)) {
      res[groupKey].push(cur)
    } else {
      res[groupKey] = [cur]
    }
    return res
  }, {})
  const edgInfoArrList = []
  for (const key in partGroup) {
    const parts = partGroup[key]
    const info = parts.reduce((re, cur) => {
      if (cur['edge_length_info']) {
        const lengKeys = Object.keys(cur['edge_length_info'])
        lengKeys.forEach((lenKey) => {
          const len = Number(cur['edge_length_info'][lenKey])
          const fixedLenKey = Number(Number(lenKey).toFixed(2))
          if (re.hasOwnProperty(fixedLenKey)) {
            re[fixedLenKey] += len
          } else {
            re[fixedLenKey] = len
          }
        })
      } else {
        if (cur.edgeInfo) {
          const str = cur.edgeInfo
          const regex = /(?:←|↓|→|↑)[^←↓→↑]+/g
          const r = str.match(regex)
          for (let i = 0; i < r.length; i++) {
            const el = String(r[i])
            const lenKey = Number(el.slice(1))
            let len = 0
            if (lenKey && Number(lenKey)) {
              if (el.includes('←') || el.includes('→')) {
                len += cur.oRect.height
              } else {
                len += cur.oRect.width
              }
              if (re.hasOwnProperty(lenKey)) {
                re[lenKey] += len
              } else {
                re[lenKey] = len
              }
            }
          }
        }
      }
      return re
    }, {})
    const edgeInfoArr = []
    for (const thick in info) {
      const keyArr = key.split(',')
      edgeInfoArr.push({
        // 老板良要求封边的宽度和厚度对调传
        width: thick,
        thick: keyArr[0],
        length: info[thick],
        color: keyArr[1],
      })
    }

    edgInfoArrList.push(edgeInfoArr)
  }

  // 统计五金件信息
  const partsChildrenInfo = parts.reduce((re, cur) => {
    const dealPartsChildren = (partsChildren) => {
      const curChildrenInfo = partsChildren.reduce((res, cu) => {
        const childkey = cu.name + cu.matSpec
        if (res[childkey]) {
          res[childkey].count += Number(cu.amount)
        } else {
          res[childkey] = {
            name: cu['name'],
            spec: cu['handleModelName']
              ? `${cu['handleModelName']}_${cu['matSpec']}`
              : `${cu['maskPartName'] ? cu['maskPartName'] + '_' : ''}${
                  cu['matSpec']
                }`,
            unit: cu['unit'],
            count: Number(cu['amount']),
          }
        }
        return res
      }, {})
      for (const key in curChildrenInfo) {
        if (re[key]) {
          re[key].count += Number(curChildrenInfo[key].count)
        } else {
          re[key] = curChildrenInfo[key]
        }
      }
    }
    if (cur.partsChildren) {
      dealPartsChildren(cur.partsChildren)
    }
    if (cur.handle && cur.handle.partsChildren) {
      dealPartsChildren(cur.handle.partsChildren)
    }
    return re
  }, {})
  const partsChildrenRes = Object.values(partsChildrenInfo)
  const edgInfoRes = edgInfoArrList.flat(1)
  return {
    plankData: result,
    edgeData: edgInfoRes,
    addonData: partsChildrenRes,
  }
}

// 处理长圆孔异形孔的数据
export function dealCurveHoles(curveHoles) {
  if (!curveHoles) return
  const specialCurveHoles = []
  const commonCurveHoles = []
  // 区分特殊长圆孔(牛角槽)
  curveHoles.forEach((item) => {
    if (item.knifeName) {
      specialCurveHoles.push(item)
    } else {
      commonCurveHoles.push(item)
    }
  })
  // 牛角槽只需要绘制大的那一个就行
  const maxSpecialCurveHoles = getPathsMaxAreaObj(specialCurveHoles)
  // 最终使用的长圆孔
  const finalCurveHoles = [...commonCurveHoles, ...maxSpecialCurveHoles]
  return finalCurveHoles
}
// 获取打穿异形孔
export function getThroughPathObj(obj, thick, isThrough = true) {
  if (!obj) return []
  const result = obj.filter((item) => {
    const { deep, path, shape, depth } = item
    if (!path && !shape) return false
    const val = deep ?? depth
    return isThrough ? +val + 0.0000001 >= thick : val < thick
  })
  return result
}
// 获取对象路径中面积最大的
export function getPathsMaxAreaObj(arr, field = 'path') {
  if (!arr?.length) return []
  let maxArea = -1,
    maxObj = arr[0]
  arr.forEach((it) => {
    if (!it[field]) return
    const area = Math.abs(window.ClipperLib.Clipper.Area(it[field]))
    if (area > maxArea) {
      maxArea = area
      maxObj = it
    }
  })
  return [maxObj]
}

// 根据余料摆放位置，计算排版的起始顺序（原来代码中有加减刀半径）（返回的板子的起始坐标是余料摆放位置的对角位置）
export function calcSurplusPosition(
  surplusPosition,
  part,
  cutKnifeR,
  plank,
  isReverse = false
) {
  const { startY, startX, rect } = part
  const plankHeight = Number(plank.plankHeight)
  const plankWidth = Number(plank.plankWidth)
  const width = Number(rect.width)
  const height = Number(rect.height)
  // const realCutknifeR = isReverse ? -cutKnifeR : cutKnifeR
  const realCutknifeR = 0
  switch (surplusPosition) {
    case 'bottomLeft':
      return {
        startX: plankWidth - startX - width,
        startY: startY + realCutknifeR,
      }
    case 'bottomRight':
      return {
        startX: startX + realCutknifeR,
        startY: startY + realCutknifeR,
      }
    case 'topLeft':
      return {
        startX: plankWidth - startX - width + realCutknifeR,
        startY: plankHeight - startY - height + realCutknifeR,
      }
    case 'topRight':
      return {
        startX: startX + realCutknifeR,
        startY: plankHeight - startY - height + realCutknifeR,
      }
  }
}
// 根据余料摆放位置计算出起始位置
export function calcStartPositionBySurplus(surplusPosition) {
  switch (surplusPosition) {
    case 'bottomLeft':
      return '右上角'
    case 'bottomRight':
      return '左上角'
    case 'topLeft':
      return '右下角'
    case 'topRight':
      return '左下角'
    default:
      return '左下角'
  }
}
// 根据计算位置计算出余料摆放位置
export function calcSurplusPositionByStartPosition(
  startPosition,
  surplusPosition,
  isXyReverse
) {
  // 记录四个位置的对角位置
  const reversePositionMap = new Map([
    ['topLeft', 'bottomRight'],
    ['bottomLeft', 'topRight'],
    ['topRight', 'bottomLeft'],
    ['bottomRight', 'topLeft'],
  ])
  const positionMap = new Map([
    ['右上角', 'topRight'],
    ['右下角', 'bottomRight'],
    ['左上角', 'topLeft'],
    ['左下角', 'bottomLeft'],
  ])
  // 如果未开启了xy轴互换，那么不处理
  // 2. 开启了xy轴互换，就分以下情况：
  // 2.1 如果起始位置和余料摆放位置相同/完全相反，那么就原样返回surplusPosition
  // 2.2 否则的话就返回余料位置的对角位置
  if (!isXyReverse) return surplusPosition
  // 判断起始位置和余料摆放位置是否相同或者完全相反
  if (
    positionMap.get(startPosition) === surplusPosition ||
    reversePositionMap.get(positionMap.get(startPosition)) === surplusPosition
  )
    return surplusPosition
  else return reversePositionMap.get(surplusPosition)
}
