import equipEnvironmentStore from '@/store/modules/equipEnvironment'
import { Message } from 'element-ui'
import Vue, { nextTick } from 'vue'

const DICTIONARY = {
  glassEquip: {
    get: '/load_glass_setting',
    save: '/save_glass_setting',
  },
}
const gainRequestAddress = (key, type) => {
  return DICTIONARY[key][type] || DICTIONARY['glassEquip']['get']
}
export default {
  namespaced: true,
  state: {
    // 控制设备设置页面的保存按钮是否可以点击
    isDisplaySaveButton: false,
    // 获取到的设备设置信息
    glassEquipData: {},
    isApply: false,
  },
  mutations: {
    changeSavaButtonStatus(state, flag) {
      state.isDisplaySaveButton = flag
    },
    changeGlassEquipData(state, data) {
      state.glassEquipData = data
    },
    setIsApply(state, flag) {
      state.isApply = flag
    },
  },
  actions: {
    getSettingInfo({ commit }, payload) {
      const { equipType, setting_id } = payload
      const url = gainRequestAddress(equipType, 'get')
      const changeKey = equipType.charAt(0).toUpperCase() + equipType.slice(1)
      // 获取预览电子锯设置
      const data = equipEnvironmentStore.state.previewProductLine['glass']
      Vue.prototype.$getByToken(
        url,
        {
          setting_id,
        },
        (res) => {
          if (res.status === 1) {
            commit(`change${changeKey}Data`, res.data)
            commit('changeSavaButtonStatus', true)

            // 从后台点击应用生产线设置进入
            if (data) {
              nextTick(() => {
                commit('setIsApply', true)
                commit(`change${changeKey}Data`, data)
              })
            }
          } else {
            Message({
              type: 'error',
              message: res.data.err_info,
              duration: 1000,
            })
          }
        }
      )
    },
    saveSetting(store, payload) {
      const {
        equipType,
        data,
        setting_id,
        phone,
        password,
        sms_code,
        platform,
        sms_type,
        area_code,
      } = payload
      const param = {
        setting_id,
        phone,
        password,
        sms_code,
        platform,
        sms_type,
        area_code,
        data,
        platform: 'bluen',
      }
      const url = gainRequestAddress(equipType, 'save')
      Vue.prototype.$token(url, param, (res) => {
        if (typeof payload.finalCb === 'function') {
          payload.finalCb()
        }
        if (res.status === 1) {
          if (typeof payload.cb === 'function') {
            payload.cb()
          }
          if (res.data.phone && res.data.request_ip) {
            payload.cbSaveSuccess(res)
          }
          Message({
            type: 'success',
            message: '保存成功',
            duration: 1000,
          })
        } else if (res.status == -1) {
          payload.cbSaveFail(res)
        } else {
          Message({
            type: 'error',
            message: res?.data?.err_info || res.msg,
            duration: 1000,
          })
        }
      })
    },
  },
}
