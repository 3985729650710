import { render, staticRenderFns } from "./g-global-progress-notice-ball.vue?vue&type=template&id=703deca0&scoped=true&"
import script from "./g-global-progress-notice-ball.vue?vue&type=script&setup=true&lang=ts&"
export * from "./g-global-progress-notice-ball.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./g-global-progress-notice-ball.vue?vue&type=style&index=0&id=703deca0&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "703deca0",
  null
  
)

export default component.exports