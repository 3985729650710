/*
 * @Description:
 * @Author: t
 * @Date: 2022-04-15 10:00:27
 * @LastEditTime: 2022-04-15 10:27:34
 * @LastEditors: t
 */
export const typeDictionary = {
  equipList: 1,
  supplierList: 3,
  demandInfo: 2,
  equipmentMaintenance: 4,
}
