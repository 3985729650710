import router from '@/router'
import commonFun from '@/util/commonFun'
import axios from 'axios'
import ElementUI from 'element-ui'
import Vue from 'vue'

let host = process.env.VUE_APP_BASE_URL
const toolsHost = process.env.VUE_APP_TOOLS_URL

export const request = axios.create({
  baseURL: host,
})
let token = ''
// 如果携带有guigui_token参数, 则直接获取参数中的token值
if (location.href.includes('guigui_token')) {
  // 门窗、柜柜、老板良跳转都在sessionStorage 存了值，为了保证切换路由后，再从不同端跳转获取值有问题，需要在跳转时判断来源
  if (!location.href.includes('thinkerx_material')) {
    sessionStorage.removeItem('thinkerx_material')
  }
  let a = location.href.split('?')[1]
  let s = a.split('&')
  for (let i = 0; i < s.length; i++) {
    if (s[i].includes('guigui_token')) {
      token = decodeURIComponent(s[i].split('guigui_token=')[1])
      window.sessionStorage.setItem('token', token)
    }
    // 获取refresh_token
    if (location.href.includes('refresh_token')) {
      if (s[i].includes('refresh_token')) {
        let reToken = decodeURIComponent(s[i].split('refresh_token=')[1])
        sessionStorage.setItem('refresh_token', reToken)
      }
    }
    // 获取guigui_version
    if (location.href.includes('guigui_version')) {
      if (s[i].split('=')[0] === 'guigui_version') {
        let gv = decodeURIComponent(
          s[i]
            .split('=')
            .filter((str, index) => index !== 0)
            .join('')
        )
        sessionStorage.setItem('guigui_version', gv)
      }
    }

    // 获取embedded
    if (location.href.includes('embedded')) {
      if (s[i].split('=')[0] === 'embedded') {
        let embedded = decodeURIComponent(
          s[i]
            .split('=')
            .filter((str, index) => index !== 0)
            .join('')
        )
        sessionStorage.setItem('embedded', embedded)
      }
    }
  }
} else if (window.sessionStorage.getItem('token')) {
  token = window.sessionStorage.getItem('token')
}

// 请求拦截
request.interceptors.request.use((config) => {
  // 所有请求都添加token
  config.headers.token = window.sessionStorage.getItem('token')
  return config
})

// 响应拦截
let isRefreshing = false
let retryRequests = []
request.interceptors.response.use(
  async function (response) {
    return response
  },
  async function (error) {
    console.error(error)
    if (error?.response?.status === 401) {
      let refresh_token = sessionStorage.getItem('refresh_token')
      if (!isRefreshing) {
        isRefreshing = true
        if (!refresh_token) {
          sessionStorage.removeItem('token')
          ElementUI.Message({
            duration: 1500,
            message: '登录信息过期，请重新登录',
            type: 'warning',
          })
          router.push({
            path: '/login',
          })
          return
        }
        let RSAKey = commonFun.calcKeyByRSA({ refresh_token: refresh_token })
        return axios({
          url: host + '/refresh_token',
          method: 'POST',
          data: {
            refresh_token: refresh_token,
          },
          headers: {
            sign: RSAKey,
          },
        }).then((res) => {
          if (res?.data?.status == 1) {
            sessionStorage.setItem('refresh_token', res.data.data.refresh_token)
            sessionStorage.setItem('token', res.data.data.token)
            // 循环队列再次请求
            retryRequests.forEach((cb) => {
              cb(res.data.data.token)
            })
            retryRequests = []
            error.config.headers['token'] = res.data.data.token
            return request(error.config)
          } else {
            sessionStorage.removeItem('refresh_token')
            sessionStorage.removeItem('token')
            ElementUI.Message({
              duration: 1500,
              message: '登录信息过期，请重新登录',
              type: 'warning',
            })
            router.push({
              path: '/login',
            })
            return
          }
        })
      } else {
        // 刷新token时，存入队列
        return new Promise((resolve) => {
          retryRequests.push((token) => {
            // error.config.baseURL = ''
            error.config.headers['token'] = token
            resolve(request(error.config))
          })
        })
      }
    } else {
      return Promise.reject(error)
    }
  }
)
// 响应拦截
request.interceptors.request.use((config) => {
  const sessionToken = sessionStorage.getItem('token')
  if (sessionToken) {
    config.headers.token = sessionToken
  }
  return config
})

export async function postApi(url, data, callback, headers = null) {
  let params = {
    url: host + url,
    method: 'POST',
    data: data,
  }
  if (headers) {
    params.headers = headers
  }
  await Vue.axios(params).then((res) => {
    callback(res.data)
  })
}

export async function getApi(url, data, callback) {
  await Vue.axios({
    url: host + url,
    method: 'GET',
    params: data,
  }).then((res) => {
    callback(res.data)
  })
}

export async function postByToken(
  url,
  data,
  callback
  // isThinkerxMaterial = false
) {
  let finalUrl = host + url
  // if(!isThinkerxMaterial){
  //   finalUrl = host + url
  // }else{
  //   finalUrl = 'https://dev.eggi.cn/bluen' + url
  // }
  await request({
    url: finalUrl,
    method: 'POST',
    data: data,
    headers: {
      token: window.sessionStorage.getItem('token'),
    },
  })
    .then((res) => {
      callback(res?.data)
    })
    .catch((err) => {
      console.warn(err)
      callback(err)
    })
}

export async function getByToken(url, data, callback) {
  await request({
    url: host + url,
    method: 'GET',
    params: data,
    headers: {
      token: window.sessionStorage.getItem('token'),
    },
  }).then((res) => {
    callback(res.data)
  })
}
export async function delByToken(url, data, callback) {
  await request({
    url: host + url,
    method: 'DELETE',
    data: data,
    headers: {
      token: window.sessionStorage.getItem('token'),
    },
  }).then((res) => {
    callback(res.data)
  })
}
export async function generalRequest(url, config, callback) {
  const baseUrl = config.tools ? toolsHost : host
  const finalUrl = baseUrl + url
  await request({
    url: finalUrl,
    method: 'POST',
    headers: {
      token: window.sessionStorage.getItem('token'),
    },
    ...config,
  })
    .then((res) => {
      callback(res?.data)
    })
    .catch((err) => {
      console.warn(err)
      callback(err)
    })
}

Vue.prototype.$post = postApi
Vue.prototype.$get = getApi
Vue.prototype.$token = postByToken
Vue.prototype.$getByToken = getByToken
Vue.prototype.$request = generalRequest
Vue.prototype.$delByToken = delByToken
