import store from '@/store'
import { createTask } from '@/store/modules/progressNotice'
import { translate } from '@/util/commonFun'
import {
  Data,
  GenerateFileDB,
  addFileDBData,
  deleteFileDBData,
  getFileDBData,
} from '@/util/db/generateExportDB'

import { isSupportLocalGenFile } from './index'
import {
  createFolder,
  createFolderName,
  deleteFilePathSymbol,
  getFolderAllFile,
  queryPermission,
  splitPath,
  verifyPermission,
} from './index'

/**
 * 使用file system的api注意点
 * 1. 此api除了有很大的局限以外，记住最好是在用户和你页面进行交互的时候进行询问你想要操作的文件夹
 * 如果在用户交互一定时间后(哪怕这个时间在你认为来说很短)再去调起api浏览器会认为是你直接调用的api而不是用户主动的操作，从而拒绝此api的一个实现
 */

/**
 *
 * @param zip
 * @param zipName
 * @returns {boolean} 需要告知内部执行情况，内部执行失败还需走之前的zip下载逻辑
 */

interface PathObj {
  folderPath: string
  fileName: string
  isFolder: boolean
  data: Blob
}

/**
 * @description 将jszip的对象转换为本地文件直接生成
 * @param zip jszip所使用的对象
 * @param zipName 文件名称
 * @returns
 */
export async function savaFileToDisk(zip: any, zipName: string) {
  //  task数据，用于报错时关闭相应的任务进度
  let taskData
  try {
    zipName = zipName || '生产文件'
    /** 检测是否是可以本地直接生成文件的 */
    if (!isSupportLocalGenFile()) return
    // 获取当前选择的文件句柄 从保存历史下载zip 值可能为undefined 直接解构赋值会报错
    const { handle } = ((await getFileDBData('currentUserFolder')) ?? {}) as any
    if (!handle) return
    // 检查是否有写入权限没有则不写入
    const isWritePermission = await queryPermission(handle)
    if (!isWritePermission) return
    // 获取所有当前句柄下的所有已存在的文件夹名称
    const { folders: existFolder } = await getFolderAllFile(handle)
    // 获取到能使用的文件名
    const mainFolder = createFolderName(
      deleteFilePathSymbol(zipName),
      existFolder
    )
    const directoryHandle = await createFolder(handle, mainFolder)
    const { files } = zip
    const fileArr = await zipToPathObj(files)
    // 创建新的任务进度显示(如果觉得放在此处进行任务加载不好不建议用参数控制  而是外部再进行传参决定)
    const { task, data } = createTask(
      translate('common.localFileIsBeingWrite'),
      fileArr.length
    )
    taskData = data
    for (let index = 0; index < fileArr.length; index++) {
      const file = fileArr[index]
      const { fileName, folderPath, data, isFolder } = file
      const handle = await createFolder(directoryHandle, folderPath)
      // 文件则需要创建并写入
      if (!isFolder) {
        const fileHandle = await handle.getFileHandle(fileName.trim(), {
          create: true,
        })
        const writable = await fileHandle.createWritable()
        await writable.write(data)
        await writable.close()
      }
      task(index + 1)
    }
    // 成功需要返回成功结果，外部可以进行判断
    return true
  } catch (err) {
    // 停止任务执行
    if (taskData) {
      store.commit('progressNotice/delTask', taskData.taskId)
    }
    console.error(err)
    return false
  }
}

/**
 * @description 将zip文件对象转换成单独的单独的可以使用的文件对象
 * @param zipFiles zip还未进行压缩前的对象
 */
async function zipToPathObj(zipFiles: any): Promise<PathObj[]> {
  const arr: PathObj[] = []
  const fileNames = Object.keys(zipFiles)
  for (const name of fileNames) {
    const file = zipFiles[name]
    const { dir, _data } = file
    const blob = await _data
    const obj: PathObj = {
      folderPath: '',
      fileName: '',
      data: blob,
      isFolder: dir,
    }
    if (dir) {
      obj.folderPath = name
    } else {
      const { fileName, folderPath } = splitPath(name)
      obj.folderPath = folderPath
      obj.fileName = fileName
    }
    arr.push(obj)
  }
  return arr
}

/**
 * @description 检测当前使用的目录是否还存在，如果浏览器不适配则此方法无效
 */
export async function checkCurrentUseFolder() {
  try {
    /** 检测是否是可以本地直接生成文件的 */
    if (!isSupportLocalGenFile()) return
    /** 检查是否已经存储的本地的存储文件夹 */
    const data = await checkCurrentHandleAndClean(false)
    let permission = true
    if (data.handle) {
      // 存在句柄的情况下需要检查句柄是否拥有读写权限，不存在则不返回任何信息
      permission = await verifyPermission(data.handle)
    }
    return { ...data, permission }
  } catch (error) {
    console.error(error)
  }
}

/**
 * @description 检查当前选择的句柄
 * @param isClean 是否清理
 * @returns 如存在则返回当前句柄
 */
export function checkCurrentHandleAndClean(
  isClean = true
): Promise<{ handle?: any }> {
  return new Promise(async (resolve) => {
    const { handle, id } =
      ((await getFileDBData('currentUserFolder')) as Data) ?? {}
    if (isClean) {
      id && deleteFileDBData('currentUserFolder', id)
      checkAllHandleAndClean()
    }
    resolve({ handle })
  })
}

/**
 * @description 检查所有句柄并
 * @param isClean 是否清理
 * @returns 如存在则返回当前句柄
 */
export async function checkAllHandleAndClean(isClean = true) {
  const folders = (await getFileDBData('folders')) as Data[]
  const result: { handle?: any }[] = []
  for (const folder of folders) {
    const { handle, id } = folder
    result.push({ handle })
    if (isClean) {
      id && (await deleteFileDBData('folders', id))
    }
  }
  return result
}

/**
 * @description 存储本地文件
 */
export async function storageLocalFolder() {
  try {
    /** 检测是否是可以本地直接生成文件的 */
    if (!isSupportLocalGenFile()) return
    const { showDirectoryPicker } = window as any
    const handle = await showDirectoryPicker()
    // 申请权限
    const flag = await verifyPermission(handle)
    // 用户未授权返回
    if (!flag) return { permission: false }
    // 分别向当前和所有文件夹中添加数据
    await addFileDBData(
      'currentUserFolder',
      {
        handle,
        id: GenerateFileDB.currentUserFolderPrimaryKey,
      },
      'put'
    )
    const folders = (await getFileDBData('folders')) as Data[]
    const { name } = handle
    // 添加一个本地文件时，在所有文件内也许添加，但不添加文件名相同的文件，存在相同文件名则进行替换
    const target = folders.find(({ handle }) => handle?.name === name) ?? {}
    await addFileDBData(
      'folders',
      { ...target, handle },
      target ? 'put' : 'add'
    )
    return { handle, permission: true }
  } catch (error) {
    console.error(error)
  }
}
