<template>
  <div id="notice">
    <div class="content">
      <div class="title-box">
        <span class="notice">{{ $t('main.head.notice') }}</span>
        <img
          class="close el-icon-close"
          :src="require('@/assets/image/close.png')"
          alt="错误"
          id="notice_close_btn"
          @click="closeNotice"
        />
      </div>
      <div class="notice-list-box">
        <div class="all-read" id="notice_allRead_btn" @click="allRead">
          <img :src="require('@/assets/image/Frame.png')" alt="错误" />
          <span class="all-text">{{ $t('main.allRead') }}</span>
        </div>
        <ul
          class="infinite-list notice-list scroll"
          v-infinite-scroll="loadNoticeList"
          v-loading="isLoadNotice"
          style="overflow: auto"
        >
          <li
            class="notice-item"
            v-for="(notice, index) in noticeList"
            :key="notice.id"
            :id="`notice_list_item${index}`"
            @click="lookNotice(notice)"
          >
            <span
              :class="['title', notice.id == activeNotice ? 'active' : '']"
              >{{ notice.notice_title }}</span
            >
            <i class="unread" v-if="notice.has_read == 0"></i>
          </li>
        </ul>
      </div>
      <div class="notice-content" v-loading="isLoading">
        <div class="notice-title">
          <span>{{ currentNotice.notice_title }}</span>
          <span id="notice_learn_more_btn" @click="gainDetail">{{
            $t('main.learnMore')
          }}</span>
        </div>
        <div
          class="image-text scroll editor-content-view"
          v-html="currentNotice.html"
          v-if="noticeList.length"
        ></div>
        <div v-else>{{ $t('main.noticeEmpty') }}</div>
      </div>
    </div>
    <div class="preViewImg" v-show="isShowImage">
      <img
        :src="currentImageSrc"
        alt="无法显示"
        ref="imageRef"
        @mousewheel="mouseScroll"
      />
      <span
        class="left"
        id="notice_preview_left_btn"
        @click="preImage(null, '-')"
        ><i class="el-icon-arrow-left"></i
      ></span>
      <span
        class="right"
        id="notice_preview_right_btn"
        @click="preImage(null, '+')"
        ><i class="el-icon-arrow-right"></i
      ></span>
      <span
        class="close"
        id="notice_preview_close_btn"
        @click="isShowImage = false"
        ><i class="el-icon-close"></i
      ></span>
      <div class="image-viewer-action">
        <i
          class="el-icon-zoom-out"
          id="notice_preview_zoom_out_btn"
          @click="changeImageStyle('-')"
        ></i>
        <i
          class="el-icon-zoom-in"
          id="notice_preview_zoom_in_btn"
          @click="changeImageStyle('+')"
        ></i>
        <i
          class="el-icon-refresh-left"
          id="notice_preview_refresh_left_btn"
          @click="changeImageStyle('left')"
        ></i>
        <i
          class="el-icon-refresh-right"
          id="notice_preview_refresh_right_btn"
          @click="changeImageStyle('right')"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchNoticeList } from '@/apis/baseMaterial/notice'
import axios from 'axios'

export default {
  props: {
    preViewData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      noticeList: [],
      currentNotice: {},
      activeNotice: -999,
      isLoading: false,
      isShowImage: false,
      imageElementList: [],
      currentImageSrc: '',
      // 记录当前显示的下标
      curPreViewIdx: '',
      // 记录当前是否能执行过度效果
      isActionTransform: true,
      // css动画回调函数
      transitionendFunc: null,
      imageStyle: {
        scale: 0.8,
        rotate: 0,
      },
      // 是否在加载notice列表
      isLoadNotice: false,
      currentPage: 1,
      // 记录全部公告数量
      totalNotice: 0,
    }
  },
  methods: {
    // 滚动加载
    async loadNoticeList() {
      if (this.noticeList.length < this.totalNotice && !this.isLoadNotice) {
        this.isLoadNotice = true
        this.currentPage++
        await this.getNoticeList(20, this.currentPage)
        this.isLoadNotice = false
      }
    },
    // 获取通知列表
    async getNoticeList(limit = 20, page = 1) {
      const { id } = this.$store.state.userInfo

      const { data: res } = await fetchNoticeList({ uid: id, limit, page })
      if (res.status == 1) {
        const { data, unread_amount, total } = res.data
        data.forEach((notice) => {
          this.noticeList.push(notice)
        })
        this.totalNotice = total
        if (unread_amount > 0) {
          this.$store.commit('setNoReadNotice', true)
        } else {
          this.$store.commit('setNoReadNotice', false)
        }
      }
    },
    // 获取通知详细内容
    getDetailNoticeContent(id) {
      if (!id) return
      const { id: uid } = this.$store.state.userInfo
      this.isLoading = true
      this.$token('/get_notice_by_id', { id, uid }, (res) => {
        if (res.status == 1) {
          const { notice_url } = res.data
          axios
            .get(notice_url)
            .then((content) => {
              const { html, detailLink } = content.data
              this.$set(this.currentNotice, 'html', html)
              this.$set(this.currentNotice, 'detailLink', detailLink)
              this.isLoading = false
              const flag = this.noticeList.filter((item) => item.has_read == 0)
              if (!flag.length) {
                this.$store.commit('setNoReadNotice', false)
              }
              this.changeImageList()
            })
            .catch(() => {
              this.isLoading = false
              this.$message.error(this.$t('main.noticeErr'))
            })
        } else {
          this.isLoading = false
          this.$message.error(res.msg)
        }
      })
    },
    // 查看通知
    lookNotice(notice) {
      if (this.activeNotice != notice.id) {
        this.currentNotice = notice
        this.activeNotice = notice.id
        notice.has_read = 1
        this.getDetailNoticeContent(notice.id)
      }
    },
    // 详情链接跳转
    gainDetail() {
      if (this.currentNotice.detailLink) {
        window.open(this.currentNotice.detailLink)
      }
    },
    // 获取第一个通知内容
    getFirstNotice(list) {
      const notice = list[0]
      this.currentNotice = notice
      notice.has_read = 1
      this.activeNotice = notice.id
      this.getDetailNoticeContent(notice.id)
    },
    // 预览公告
    preNotice() {
      this.noticeList = JSON.parse(JSON.stringify(this.preViewData))
      this.currentNotice = this.noticeList[0]
      this.currentNotice.id = 1
      this.activeNotice = 1
    },
    // 全部已读
    allRead() {
      if (!this.noticeList.length) return
      const unreadIds = this.noticeList.reduce((pre, cur) => {
        return cur.has_read == 1 ? pre : pre.concat([cur.id])
      }, [])
      if (!unreadIds.length) return
      const data = {
        uid: this.$store.state.userInfo.id,
        unread_list: unreadIds,
      }
      this.$token('/user_read_all', data, (res) => {
        if (res.status == 1) {
          this.noticeList.forEach((item, idx) => {
            this.$set(this.noticeList, idx, {
              ...item,
              has_read: 1,
            })
          })
          this.$store.commit('setNoReadNotice', false)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    closeNotice() {
      window.sessionStorage.setItem('firstNotice', 1)
      this.$store.commit('setShowNotice', false)
    },
    // 更换内容切换重新添加事件
    changeImageList() {
      this.$nextTick(() => {
        const images = document
          ?.querySelector('.image-text')
          ?.querySelectorAll('img')
        images?.forEach((ele) => {
          ele.addEventListener('click', this.preImage)
        })
        this.imageElementList = Array.from(images)
      })
    },
    preImage(e, next) {
      this.imageStyle = { scale: 0.8, rotate: 0 }
      const element = this.$refs['imageRef']
      element.style.transform = `scale(0.8) rotate(0deg)`
      element.style.transition = 'all 0s'
      this.isShowImage = true
      const len = this.imageElementList.length
      if (e) {
        const target = e.target
        let idx = this.imageElementList.findIndex((item) => item == target)
        this.curPreViewIdx = idx
      }
      if (next == '+') {
        this.curPreViewIdx =
          this.curPreViewIdx >= len - 1 ? 0 : this.curPreViewIdx + 1
      } else if (next == '-') {
        this.curPreViewIdx =
          this.curPreViewIdx <= 0 ? len - 1 : this.curPreViewIdx - 1
      }
      const curImg = this.imageElementList[this.curPreViewIdx]
      this.currentImageSrc = curImg.src
    },
    changeImageStyle(way, size = 0.2) {
      if (!this.isActionTransform) return
      this.isActionTransform = false
      const element = this.$refs['imageRef']
      element.style.transition = `all 0.3s`
      // const curStyle = window.getComputedStyle(element, null)
      // const [a, b] = curStyle.transform.replace(/[matrix()]/g, '').split(',')
      // let scale = Number(Math.sqrt(a * a + b * b))
      // let rotate = Math.round(Math.atan2(b, a) * (180 / Math.PI))
      let scale = this.imageStyle.scale
      let rotate = this.imageStyle.rotate
      if (way == '-') {
        scale = scale - size <= 0.2 ? 0.2 : scale - size
        if (scale == 0.2) {
          this.isActionTransform = true
        }
      } else if (way == '+') {
        scale = scale + size
      }
      if (way == 'left') {
        this.count
        rotate = rotate - 90
      } else if (way == 'right') {
        rotate = rotate + 90
      }
      this.imageStyle = { scale, rotate }
      element.style.transform = `scale(${scale}) rotate(${rotate}deg)`
      // 防止动画执行期间用户再次点击触发动画获取到错误的值，需要等待动画执行完成才能再次触发旋转
      const whichTransitionEvent = this.whichTransitionEvent()
      if (whichTransitionEvent) {
        element.removeEventListener(
          whichTransitionEvent,
          this.transitionendFunc
        )
        element.addEventListener(whichTransitionEvent, this.transitionendFunc)
      }
    },
    // 获取css动画事件名
    whichTransitionEvent() {
      const el = document.createElement('div')
      const transitions = {
        transition: 'transitionend',
        OTransition: 'oTransitionEnd',
        MozTransition: 'transitionend',
        WebkitTransition: 'webkitTransitionEnd',
      }
      for (const key in transitions) {
        if (el.style[key] != undefined) {
          return transitions[key]
        }
      }
    },
    // 事件回调
    transitionendCb() {
      return () => {
        this.isActionTransform = true
      }
    },
    mouseScroll(e) {
      const deltaY = e.deltaY
      let way
      if (deltaY < 0) {
        way = '+'
      } else {
        way = '-'
      }
      this.changeImageStyle(way, 0.05)
    },
  },
  // 后台只做预览不做接口调用，后面代码在云排版项目中完善
  async mounted() {
    if (this.preViewData.length) {
      this.preNotice()
    } else {
      await this.getNoticeList()
      if (this.noticeList.length) {
        this.getFirstNotice(this.noticeList)
      }
    }
    this.transitionendFunc = this.transitionendCb()
    // 将通知挂载在body下面
    const body = document.querySelector('body')
    if (body.appendChild) {
      body.appendChild(this.$el)
    }
  },
  beforeDestroy() {
    this.currentPage = 1
  },
}
</script>

<style lang="less">
#notice {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  font-family: PingFang SC-Medium, PingFang SC;
  background: rgba(0, 0, 0, 0.5);
  .content {
    width: 820px;
    height: 600px;
    margin: 152px auto;
    background: #fff;
    border-radius: 8px;
    .title-box {
      position: relative;
      height: 63px;
      line-height: 63px;
      text-align: center;
      border-bottom: 1px solid #e7e7e7;
      .notice {
        color: rgba(0, 0, 0, 0.9);
        font-weight: 600;
        font-size: 20px;
      }
      .close {
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
    .notice-list-box {
      float: left;
      width: 200px;
      height: 537px;
      background: #f8f8fc;
      .all-read {
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-bottom: 1px solid #e7e7e7;
        cursor: pointer;
        .all-text {
          color: #18a8c7;
          font-size: 14px;
        }
      }
      .notice-list {
        position: relative;
        height: calc(100% - 40px);
        overflow-y: auto;
        .notice-item {
          width: calc(100% - 24px);
          margin: 16px 0 0 24px;
          padding-right: 5px;
          overflow-x: hidden;
          color: rgba(0, 0, 0, 0.9);
          font-size: 14px;
          white-space: nowrap;
          text-overflow: ellipsis;
          list-style: none;
          cursor: pointer;
          .title {
            font-weight: 600;
          }
          .unread {
            display: inline-block;
            width: 6px;
            height: 6px;
            margin-left: 3px;
            font-style: normal;
            background: #e34d59;
            border-radius: 50%;
          }
        }
        & li:last-child {
          margin-bottom: 16px;
        }
      }
    }
    .notice-content {
      float: left;
      width: calc(100% - 200px);
      height: calc(100% - 63px);
      padding: 16px 16px 16px 23px;
      .notice-title {
        height: 24px;
        margin-bottom: 8px;
        border-left: 3px solid #18a8c7;
        & span:nth-child(1) {
          margin-left: 8px;
          color: rgba(0, 0, 0, 0.9);
          font-weight: 600;
          font-size: 18px;
        }
        & span:nth-child(2) {
          margin-left: 16px;
          color: #18a8c7;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .image-text {
        width: 100%;
        height: calc(100% - 48px) !important;
        overflow: auto;
        img {
          cursor: pointer;
        }
      }
    }
  }
  .preViewImg {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    user-select: none;
    > img {
      max-width: 100%;
      max-height: 100%;
      transform: scale(0.8) rotate(0deg);
      transition: all 0.3s;
    }
    > span {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      color: #fff;
      font-size: 24px;
      background: #606266;
      border-radius: 50%;
      cursor: pointer;
      opacity: 0.8;
      i {
        box-sizing: border-box;
      }
    }
    .close {
      top: 40px;
      right: 40px;
    }
    .left {
      top: 50%;
      left: 40px;
      transform: translateY(-50%);
    }
    .right {
      top: 50%;
      right: 40px;
      transform: translateY(-50%);
    }
    .image-viewer-action {
      position: absolute;
      bottom: 30px;
      left: 50%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 240px;
      height: 44px;
      padding: 0 23px;
      color: #fff;
      font-size: 24px;
      background: #18a8c7;
      background: #606266;
      border-radius: 22px;
      transform: translateX(-50%);
      opacity: 0.8;
      i {
        cursor: pointer;
      }
    }
  }
}
.scroll {
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #ededed;
    border-radius: 10px;
  }
}
.active {
  color: #18a8c7;
}
.editor-content-view p,
.editor-content-view li {
  white-space: pre-wrap; /* 保留空格 */
}

.editor-content-view blockquote {
  margin: 10px 0;
  padding: 10px 10px;
  background-color: #f1f1f1;
  border-left: 8px solid #d0e5f2;
}

.editor-content-view code {
  padding: 3px;
  font-family: monospace;
  background-color: #eee;
  border-radius: 3px;
}
.editor-content-view pre > code {
  display: block;
  padding: 10px;
}

.editor-content-view table {
  border-collapse: collapse;
}
.editor-content-view td,
.editor-content-view th {
  min-width: 50px;
  height: 20px;
  border: 1px solid #ccc;
}
.editor-content-view th {
  background-color: #f1f1f1;
}

.editor-content-view ul,
.editor-content-view ol {
  padding-left: 20px;
}

.editor-content-view input[type='checkbox'] {
  margin-right: 5px;
}
</style>
