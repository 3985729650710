export interface IPlateInfo {
  plKId: number
  from: 'bluen'
  plankNum: string
  matCode: string
  thick: string | number
  orderId: string | number
  color: string
  size: string
  new: boolean
  address: string
  customer_name: string
  partUniqueId?: string
  // 预加载图片宽
  width: string
  // 预加载图片高
  height: string
  // 是否在暂存区内显示
  isShow: boolean
  // 标题
  title: string
  // 是否是高光板 我不知道这个是什么，反正是为了兼容
  isHighlightMatCode: boolean
  // 是否是高光板
  is_high_gloss_plank: boolean
}
export interface BujianStore {
  supplements_info: {
    plate_info: IPlateInfo

    // 预先加载的图片
    img_url: string
    // 板件渲染数据
    render_url: string
  }[]
  reason: string
  duty: string
  library_type: LibraryType
}

// 待排版库仓库对应的type
export enum LibraryType {
  awaitStore = 2,
  bujianStore = 1,
}

/** 部分js文件所使用的type */
export const LibraryTypeJS = {
  awaitStore: LibraryType.awaitStore,
  bujianStore: LibraryType.bujianStore,
}
