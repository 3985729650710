import { buryPoint } from '@/apis/common/buryPoint'
import { ValType, buryPointPayloadMap } from '@/constants/buryPointMap'

export async function dealRecord(record: any[]) {
  const recordList: any[] = []
  const dealAction = (actions: any[], keyStr = '') => {
    actions.forEach((action) => {
      if (typeof action.value === 'object' || action.arr) {
        if (action.type && action.type.includes('table')) {
          recordList.push({
            text: keyStr + action.key + ':',
            isTable: true,
            arr: action.value
              ? action.value.map((e: any, index: number) => ({
                  ...e,
                  keyID: index,
                }))
              : action.arr.map((e: any, index: number) => ({
                  ...e,
                  keyID: index,
                })),
          })
        } else if (action.value.length) {
          if (typeof action.value[0] !== 'object') {
            recordList.push({
              text:
                keyStr +
                action.key +
                ':' +
                (action.oldVlue !== undefined ? `${action.oldVlue} >>>>` : '') +
                action.value.join('、'),
              isTable: false,
              arr: [],
            })
          } else {
            if (action.key) {
              dealAction(action.value, keyStr + action.key + '-')
            } else {
              dealAction(action.value, keyStr)
            }
          }
        } else {
          recordList.push({
            text: keyStr + action.key + ':',
            isTable: false,
            arr: [],
          })
        }
      } else {
        if (action.key) {
          recordList.push({
            text:
              keyStr +
              action.key +
              ':' +
              (action.oldVlue !== undefined ? `${action.oldVlue} >>>> ` : '') +
              action.value,
            isTable: false,
            arr: [],
          })
        } else {
          recordList.push({
            isTable: false,
            arr: [],
          })
        }
      }
    })
  }
  dealAction(record)
  const points = dealBuryPoint(recordList)
  const payloadList = points.map((point) => {
    return {
      function_module: point.key1,
      function_point: point.key2,
    }
  })

  if (payloadList.length) await buryPoint(payloadList)
}

export function dealBuryPoint(recordList: any[]) {
  const res = recordList
    .filter((record) => !record.isTable && record.text)
    .map((record) => {
      const recordTextArr = String(record.text).split(':')
      const keys = recordTextArr[0].split('-').map((str) => str.trim())
      const vals = recordTextArr[1].split('>>>>').map((str) => str.trim())
      return {
        keys,
        vals,
      }
    })
  // 需要拼接值的key  通常用于启用开关、单选
  const needCancatArr = [
    '五六面钻-五/六面钻槽或异形放置方向',
    '雕刻机-一体机设置-输出一体机加工文件-输出一体机加工文件',
    '雕刻机-一拖二雕刻机-工位起始点-工位起始点',
    '雕刻机-一拖二雕刻机-修边设置-单面加工板正面生成修边路径且先修边',
    '雕刻机-一拖二雕刻机-修边设置-单面加工板正面生成修边路径且先修边方向',
    '雕刻机-一体机设置-文件格式-文件格式',
    '雕刻机-切割参数-高光设置-排版时小板高光面排在大板',
    '雕刻机-切割参数-切割打孔方式-铣槽走刀方式',
    '雕刻机-切割参数-切割打孔方式-铣槽时未拉穿的槽自动清理圆角',
    '雕刻机-切割参数-加工速度优化-切割时斜下刀',
    '雕刻机-切割参数-加工速度优化-反面修边斜下刀',
    '雕刻机-切割参数-加工速度优化-切割转角减速',
    '雕刻机-切割参数-长板加工-真空关闭方式',
    '雕刻机-切割参数-锯切设置-锯片使用剩余设置',
    '雕刻机-切割参数-废料自动截断-废料自动截断',
    '雕刻机-切割参数-修边设置-单面加工板正面生成修边路径且先修边',
    '雕刻机-切割参数-修边设置-单面加工板正面生成修边路径且先修边方向',
    '雕刻机-排钻设置-启用排钻包-启用排钻包',
    '雕刻机-排钻设置-排钻组合下刀-排钻组合下刀',
    '雕刻机-排钻设置-加工设置-排钻加工模式',
    '雕刻机-贴标设置-贴标自动避让-贴标自动避让',
    '五六面钻-通用参数设置-开启板件长边摆放方向',
    '五六面钻-通用参数设置-前封边摆放方向',
    '五六面钻-通用参数设置-固定在',
    '五六面钻-通用参数设置-圆弧描述方式',
    '五六面钻-通用参数设置-宽槽拆解为多条窄槽加工',
    '五六面钻-通用参数设置-板件长边摆放方向选择框',
    '五六面钻-通用参数设置-板件长边摆放方向',
    '五六面钻-通用参数设置-五/六面钻槽或异形放置方向选择框',
    '五六面钻-通用参数设置-五/六面钻槽或异形放置方向',
  ]
  // 将操作记录的文字处理为匹配用的key
  const resultKeyArr = res
    .filter((r) => r.keys.includes('切割时斜下刀') || r.vals[1] != 'false')
    .map((r) => {
      let key = r.keys.join('-')
      if (needCancatArr.includes(key)) {
        key = `${key}-${r.vals[1]}`
      }
      return key
    })

  const result = resultKeyArr
    .map((key) => {
      const re = buryPointPayloadMap.get(key)
      return re ? re : false
    })
    .filter((i) => i)
  return result as ValType[]
}

export async function buryPointFunc(key: string) {
  const point = buryPointPayloadMap.get(key)
  if (!point) return
  try {
    buryPoint({ function_module: point?.key1, function_point: point?.key2 })
  } catch (error) {}
}
