import OSS from 'ali-oss'
import { Message } from 'element-ui'
import Vue from 'vue'

// 数据字典
import { typeDictionary } from './dictionary'

// 保存用户每次切换页面的数据，用于提交后页面的数据重新获取
let savePageParams = null
export default {
  namespaced: true,
  state: {
    // 设备列表
    equipListData: [],
    equipListDataCount: 0,
    // 求购信息
    demandInfoData: [],
    demandInfoDataCount: 0,
    // 供应商列表
    supplierListData: [],
    supplierListDataCount: 0,
    // 按钮加载动画
    isLoading: false,
    // 页面加载动画
    pageLoading: false,
    equipmentMaintenanceData: [],
    equipmentMaintenanceDataCount: 0,
    // 雕刻机类型
    stationType: '',
    // 是否展示工位2换刀代码和排钻代码
    showCode2: false,
    // 预览进入时，获取到的生产线,设备信息信息
    previewProductLine:
      JSON.parse(window.localStorage.getItem('previewProductLine')) || {},
    previewInfo: JSON.parse(window.sessionStorage.getItem('previewInfo')) || {},
  },
  getters: {
    // 根据页面导航返回数据
    pageListData(state) {
      return (pageNav) => {
        return state[`${pageNav}Data`]
      }
    },
    pageListCount(state) {
      return (pageNav) => {
        return state[`${pageNav}DataCount`]
      }
    },
    // 判断根据选择的设备类型，动态展示对应的配置项
    showConfig(state) {
      return state.stationType === 'double' ? true : false
    },
  },
  mutations: {
    changeEquipListData(state, list) {
      state.equipListData = list
    },
    changeEquipListDataCount(state, count) {
      state.equipListDataCount = count
    },
    changeDemandInfoData(state, list) {
      state.demandInfoData = list
    },
    changeDemandInfoDataCount(state, count) {
      state.demandInfoDataCount = count
    },
    changeSupplierListData(state, list) {
      state.supplierListData = list
    },
    changeSupplierListDataCount(state, count) {
      state.supplierListDataCount = count
    },
    changeLoading(state, flag) {
      state.isLoading = flag
    },
    changePageLoading(state, flag) {
      state.pageLoading = flag
    },
    changeEquipmentMaintenanceData(state, flag) {
      state.equipmentMaintenanceData = flag
    },
    changeEquipmentMaintenanceDataCount(state, flag) {
      state.equipmentMaintenanceDataCount = flag
    },
    // 修改雕刻机类型
    changeStationType(state, payload) {
      state.stationType = payload
    },
    // 修改雕刻机类型
    changeShowCode2(state, payload) {
      state.showCode2 = payload
    },
    setPreviewProductLine(state, data) {
      window.localStorage.setItem('previewProductLine', JSON.stringify(data))
      state.previewProductLine = data
    },
    // 设置预览信息
    setPreviewInfo(state, data) {
      state.previewInfo = data
    },
  },
  actions: {
    // 获取页面数据
    async getEquipEnvironmentData({ commit }, payload) {
      savePageParams = { ...payload }
      commit('changePageLoading', true)
      let { pub_type: siteType } = payload
      let pub_type = typeDictionary[siteType]
      const req = { ...payload, pub_type }
      const changeKey = siteType.charAt(0).toUpperCase() + siteType.slice(1)
      Vue.prototype.$post('/get_publish_list_user', req, (res) => {
        if (res.status == 1) {
          commit(`change${changeKey}Data`, res.data.data)
          commit(`change${changeKey}DataCount`, res.data.total)
          commit('changePageLoading', false)
        } else {
          Message({
            type: 'error',
            message: res.msg,
            duration: 2000,
          })
          commit('changePageLoading', false)
        }
      })
    },
    // 提交用户页面输入
    async commitUserInput({ commit, dispatch, rootState }, payload) {
      let ossUrl = undefined
      commit('changeLoading', true)
      let { pub_type, modalElInstance, picture_url, province_city } = payload
      pub_type = typeDictionary[pub_type]
      let [province, city] = province_city ? province_city : []
      let [file] = picture_url ? picture_url : []
      if (file) {
        let name = '/equipEnvironment/' + file.name
        ossUrl = await new Promise((resolve, reject) => {
          Vue.prototype.$getByToken('/get_oss_token', {}, (res) => {
            if (res.status == 1) {
              let client = new OSS({
                region: 'oss-cn-hangzhou',
                accessKeyId: res.data.AccessKeyId,
                accessKeySecret: res.data.AccessKeySecret,
                stsToken: res.data.SecurityToken,
                bucket: 'eggrj',
              })
              client
                .multipartUpload(name, file)
                .then((result) => {
                  result = result.res.requestUrls[0]
                  resolve(result)
                })
                .catch(() => {
                  Message({
                    type: 'error',
                    message: '图片上传失败!',
                  })
                  reject(undefined)
                })
            }
          })
        })
      }
      let equipmentRepair = payload.equipment_repair
      payload.other_equipment
        ? equipmentRepair.push(payload.other_equipment)
        : ''
      if (equipmentRepair && equipmentRepair.length) {
        equipmentRepair = equipmentRepair
          .filter((equipment) => equipment != '其他设备')
          .join('、')
      }
      let params = {
        uid: rootState.userInfo.id,
        pub_type: pub_type,
        device_type: payload.device_type,
        picture_url: ossUrl,
        city: city,
        province: province,
        information: payload.information,
        contact: payload.contact,
        brand_name: payload.brand_name,
        device_type: payload.device_type,
        contact_name: payload.contact_name,
        opening_hours: payload.opening_hours,
        closing_time: payload.closing_time,
        fact_location: payload.fact_location,
        equipment_repair: equipmentRepair,
      }
      Vue.prototype.$post('/insert_publish_user', params, (res) => {
        try {
          if (res.status == 1) {
            dispatch('getEquipEnvironmentData', savePageParams)
            Message({
              type: 'success',
              message: '提交成功！',
            })
          } else {
            Message({
              type: 'error',
              message: res.msg,
              duration: 2000,
            })
          }
        } finally {
          // 关闭页面的弹窗实例 重置按钮
          modalElInstance.closeDialog()
          commit('changeLoading', false)
        }
      })
    },
  },
}
