export const plankMapState = [
  'ncSetting',
  'sampleTableData',
  'isShowSample',
  'preLayoutData',
  'recodeSelectPart',
  'recodeSelectSurplus',
  'beforeSearchMaterialList',
  'beforeSizeFilterList',
  'paibanData',
  'filterMaterialList',
  'bigPartsInfo',
  'paibanExtraInfo',
  'filterObject',
  'orderInfo',
]
