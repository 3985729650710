export function showCardDetail(data: any) {
  const taskDetail = [] as any
  let matCodeList = [] as any
  let textureList = [] as any
  let texDirList = [] as any
  for (const d of data) {
    const texDirType =
      d.texDir === 'reverse'
        ? '横纹'
        : d.texDir === 'notcare'
        ? '无纹理'
        : '竖纹'

    matCodeList.push(d.thick + d.matCode)
    textureList.push(d.texture)
    texDirList.push(texDirType)
  }
  // 去重
  matCodeList = [...new Set(matCodeList)]
  textureList = [...new Set(textureList)]
  texDirList = [...new Set(texDirList)]
  // 修改数据
  taskDetail.matCode = matCodeList.join(',')
  taskDetail.texture = textureList.join(',')
  taskDetail.texDir = texDirList.join(',')

  return taskDetail
}
