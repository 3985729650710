import { nanoid } from 'nanoid'
import type { Module } from 'vuex'

import store from '../index'
import type { IRootState } from '../types'

export enum TaskStatus {
  pending = 'pending',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

export interface Task {
  // 任务执行进度
  progress: number
  // 任务名称
  taskTitle?: string
  // 任务唯一id 先放着后面需求扩展可能会用到
  taskId: string
  // 总数
  total: number
  // 失败提示：成功提示
  errorMsg?: string
  // 失败的字体颜色
  errorColor?: string
  // 成功的字体颜色
  successColor?: string
  // 成功的提示
  successMsg?: string
  // 是否需要自动关闭
  isAutoClose?: boolean
  // 任务状态
  status: TaskStatus
}

export interface Store {
  // 在执行中的任务列表
  taskList: Task[]
  // 是否显示任务监控列表
  isShowTask: boolean
}

const progressNotice: Module<Store, IRootState> = {
  namespaced: true,
  state: {
    taskList: [],
    isShowTask: false,
  },
  mutations: {
    setTask(state, val) {
      state.taskList.push(val)
    },
    delTask(state, val) {
      state.taskList.forEach((it, index) => {
        if (it.taskId === val) {
          state.taskList.splice(index, 1)
        }
      })
      // 清理任务后需要检查是否还有任务
      if (!state?.taskList.length) {
        state.isShowTask = false
      }
    },
    setIsShowTask(state, val) {
      state.isShowTask = val
    },
    // 清空任务
    clearTask(state) {
      state.taskList = []
    },
  },
}

/**
 * 创建一个任务，外部直接使用内部返回的函数进行更新，内部自己维护状态
 * @param total 总数
 */
export function createTask(
  taskData: string | separateType<Task, 'partial'>,
  total: number
) {
  let task: Task | null = {
    progress: 0,
    taskId: nanoid(),
    errorColor: '#f5222d',
    successColor: '#52c41a',
    status: TaskStatus.pending,
    isAutoClose: false,
    total,
  }
  // 参数归一化，统一参数格式
  if (typeof taskData === 'string') {
    task = { ...task, taskTitle: taskData, isAutoClose: true }
  } else {
    task = { ...task, ...taskData }
  }
  store.commit('progressNotice/setTask', task)
  store.commit('progressNotice/setIsShowTask', true)
  return {
    data: task,
    switchTaskStatus: (taskStatus: TaskStatus) => {
      task!.status = taskStatus
    },
    task: function (current: number) {
      const taskStore = store.state.progressNotice
      const target = taskStore?.taskList.find(
        (it: Task) => it.taskId === task?.taskId
      )
      if (!target) return
      target.progress = Math.ceil((current / total) * 100)
      if (target.progress >= 100) {
        // 任务执行完成，修改任务状态
        target.status = TaskStatus.fulfilled
        if (target.isAutoClose) {
          store.commit('progressNotice/delTask', task?.taskId)
          // 清除引用
          task = null
        }
      }
    },
    // 删除任务
    delTask: () => {
      store.commit('progressNotice/delTask', task?.taskId)
      // 清除引用
      task = null
    },
  }
}

// 分离出类型中的必选和可选
type separateType<T, V extends 'required' | 'partial'> = V extends 'required'
  ? {
      [K in keyof T as T[K] extends Required<T>[K] ? K : never]: T[K]
    }
  : {
      [k in keyof T as T[k] extends Required<T>[k] ? never : k]: T[k]
    }

export default progressNotice
