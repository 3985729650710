/** 待排版库所有数据-此模块只负责待排版库,误将其他数据添加到此模块中 */
import { fetchStoreData } from '@/apis/bujianStore'
import { LibraryType } from '@/apis/bujianStore/types'
import {
  AwaitStoreImageSize,
  DEFAULT_SHOW_AWAIT_STORE_IMAGE,
  From,
} from '@/data/constant'
import { PartType } from '@/partTypes'
import { getPlankMatCode } from '@/util/plankCommonFuncs'
import { getPlankImage } from '@/views/newPaiban/component/m-paiban-store/utlis'
import { nanoid } from 'nanoid'
import type { Module } from 'vuex'

import type { IRootState } from '../types'

export enum StoreType {
  alreadyPutStore,
  notPutStore,
}
export interface AwaitStoreItem {
  id?: string | number
  plank: PartType
  url: string
  width: number | string | undefined
  height: number | string | undefined
  title: string
  address: string
  partUniqueId: string
  thick: number
  matCode: string
  color: string
  from: From
  plKId: string | number
  /** 板件信息存储的位置，本地 or 服务器 */
  type: 'local' | 'serve'
  /** 信息是否完成加载 done 完成 err 加载失败 load 需要加载 */
  isDone: 'done' | 'err' | 'load'
  /** 是否在页面上显示 */
  isShow: boolean
  /** 是否是老板良 */
  isLbl?: boolean
  /** 老板良图片是否已加载 */
  lblImageIsLoad?: boolean
  /** 远程板件数据 */
  render_url?: string
  /** 本地板件是否被选中 */
  isSelect?: boolean
  /** 在本地板件是否还可以被选中放进待排版库 */
  isShowChoose?: boolean
}
export interface IStore {
  storeType: StoreType
  localPlankStore: AwaitStoreItem[]
  servePlankStore: AwaitStoreItem[]
  currentDragPlank: AwaitStoreItem | null
  participationPaibanPlank: string[]
  materialSelectIds: (string | number)[]
  inMaterialPageIds: string[]
  isLoading: boolean
  isViewCurrent: boolean
  isNoPromptPushStore: boolean
}

const awaitPaibanStore: Module<IStore, IRootState> = {
  namespaced: true,
  state: {
    isLoading: false,
    // 当前显示的库
    storeType: StoreType.notPutStore as StoreType,
    localPlankStore: [] as AwaitStoreItem[],
    // 服务端上的数据
    servePlankStore: [] as AwaitStoreItem[],
    currentDragPlank: null as null | AwaitStoreItem,
    // 被选中到料单页面的待排版库板件id
    inMaterialPageIds: [],
    // 当次参与排版的待排版库数据
    participationPaibanPlank: [],
    // 记录料单选中的id
    materialSelectIds: [],
    // 是否是显示当前订单
    isViewCurrent: true,
    // 是否本次排版不再提示用户入库
    isNoPromptPushStore: false,
  },
  getters: {
    // 当前拖拽板件是否存在下刀顺序和下刀点
    isExistPriority(state) {
      const { currentDragPlank } = state
      return (
        Reflect.has(currentDragPlank!.plank, 'priority') &&
        Reflect.has(currentDragPlank!.plank, 'cutOrigin')
      )
    },
  },
  mutations: {
    setStoreType(state, val) {
      state.storeType = val
    },
    setLocalPlankStore(state, data) {
      data = Array.isArray(data) ? data : [data]
      state.localPlankStore.push(...data)
    },
    reSetLocalPlankStore(state, data) {
      state.localPlankStore = data
    },
    setServePlankStore(state, data) {
      state.servePlankStore = data
    },
    setPlankToStore(state, data) {
      // 添加的板件如果是库中存在的则将之前隐藏的显示出来
      const index = state.servePlankStore.findIndex(
        (it: AwaitStoreItem) => it.partUniqueId === data.partUniqueId
      )
      if (index !== -1) {
        const target = state.servePlankStore[index]
        target.isShow = true
        target.url = data.url
        target.width = data.width
        target.height = data.height
        target.plank = data.plank
        state.storeType = StoreType.alreadyPutStore
        delServePush(state, target)
        return
      }
      // 添加的非库中的板件
      state.localPlankStore.push(data)
      state.storeType = StoreType.notPutStore
    },
    setJinxiPlankToStore(state, data) {
      const { index, list } = getStoreListById(
        [state.localPlankStore, state.servePlankStore],
        data.partUniqueId
      )
      const target = list[index]
      const local = !target || target.type === 'local'
      state.storeType = local
        ? StoreType.notPutStore
        : StoreType.alreadyPutStore
      if (!target) {
        // 只有本地添加才会找不到数据  所以直接添加到本地
        state.localPlankStore.push(data)
        return
      }
      if (index !== -1) {
        target.isShow = true
        target.url = data.url
        target.width = data.width
        target.height = data.height
        target.plank = data.plank
        delServePush(state, target)
        return
      }
    },
    /**
     * @description 删除/隐藏暂存区的板件 适用于排版页面
     */
    deleteCurrentDragPlank(state, partUniqueId) {
      const { index, list } = getStoreListById(
        [state.localPlankStore, state.servePlankStore],
        partUniqueId
      )
      // 删除对应目标的数据
      if (index === -1) return
      // 本地数据直接删除，库中的数据只是隐藏
      const target = list[index]
      if (target.type === 'local') {
        list.splice(index, 1)
      } else {
        target.isShow = false
      }
    },
    setCurrentDragPlank(state, val) {
      state.currentDragPlank = val
    },
    /** 更新当前拖动数据的板件数据 */
    updateCurrentDragPlank(state, val) {
      if (state.currentDragPlank) {
        state.currentDragPlank.plank = val
      }
    },
    clearParticipationPaibanPlank(state) {
      state.participationPaibanPlank = []
    },
    setParticipationPaibanPlank(state, val) {
      state.participationPaibanPlank = val
    },
    /** 删除精细排版下库中的板件，只是设置未不显示而不真正的删除 */
    deleteJinxiStorePlank(state, partUniqueId) {
      const { index, list } = getStoreListById(
        [state.localPlankStore, state.servePlankStore],
        partUniqueId
      )
      if (index === -1) return
      const target = list[index]
      target.isShow = false
    },
    clearInMaterialPageIds(state) {
      state.inMaterialPageIds = []
    },
    setInMaterialPageIds(state, val) {
      val = Array.isArray(val) ? val : [val]
      const result = new Set([...state.inMaterialPageIds, ...val])
      state.inMaterialPageIds = [...result]
    },
    setMaterialSelectIds(state, val) {
      state.materialSelectIds = val
    },
    /** 隐藏一块板件 包含本地库和远程库 */
    hiddenPlank(state, partUniqueId) {
      const { index, list } = getStoreListById(
        [state.localPlankStore, state.servePlankStore],
        partUniqueId
      )
      if (index === -1) return
      list[index].isShow = false
    },
    /** 显示一块板件 包含本地库和远程库 */
    showPlank(state, partUniqueId) {
      const { index, list } = getStoreListById(
        [state.localPlankStore, state.servePlankStore],
        partUniqueId
      )
      if (index === -1) return
      list[index].isShow = true
    },
    setIsViewCurrent(state, val) {
      state.isViewCurrent = val
    },
    setIsNoPromptPushStore(state, val) {
      state.isNoPromptPushStore = val
    },
    clear(state) {
      state.localPlankStore = []
      state.servePlankStore = []
    },
    historyClear(state) {
      state.servePlankStore = []
    },
    clearLocalPlankStore(state) {
      state.localPlankStore = []
    },
  },
  actions: {
    /** 获取服务器上的板件数据 */
    async getServePlankData({ commit, state, rootState }) {
      state.isLoading = true
      // 请求待排版库数据
      const result = await fetchStoreData({
        library_type: LibraryType.awaitStore,
      })
      state.isLoading = false
      if (result.status !== 1) return []
      const plankData = result.data?.data ?? []
      const servePlanks = plankData.map((it: any) => {
        const { plate_info, render_url, img_url, id } = it
        const plank = JSON.parse(plate_info ?? '{}')
        // 重新获取前检查是否之前存在这个数据，存在则使用之前的数据
        const target = state.servePlankStore.find(
          (it) => it.partUniqueId === plank.partUniqueId || it.id === id
        )
        // 这里逻辑存在一个问题为在画图上找到板件即可替换，不需要target是否存在的判断
        // 页面上存在就算没有target也得替换，后期再改这个逻辑，逻辑改完后participationPaibanPlank的很多记录操作则不再需要
        // 如果存在则使用之前的数据
        if (target) {
          // 存在之前的数据但未加载过板件则查询当前页面内是否存在和库中相同的板件，存在则替换，并隐藏相关板件
          if (!target.plank) {
            rootState.finalDrawData.forEach((drawData) => {
              drawData.data.forEach((bigPlank: any) => {
                bigPlank.parts.forEach((part: PartType) => {
                  if (part.partUniqueId === plank.partUniqueId) {
                    // 找到板件后则将信息进行部分重置
                    target.plank = part
                    target.isShow = false
                    target.isDone = 'done'
                    target.url =
                      getPlankImage(part, false, {
                        defaultScale: 6,
                        scale: 3,
                        plankLineWidth: 1,
                      }) ?? ''
                  }
                })
              })
            })
          }
          return target
        }
        const { partUniqueId, thick, matCode, color, width, height } =
          plank as AwaitStoreItem
        // 对需要但不存在的数据生成默认数据
        plank.partUniqueId = partUniqueId || nanoid()
        plank.width = width || `${AwaitStoreImageSize.width}px`
        plank.height = height || `${AwaitStoreImageSize.height}`
        plank.matCode = getPlankMatCode(plank)
        plank.title = `${thick}${matCode}(${color})`
        const url = img_url || DEFAULT_SHOW_AWAIT_STORE_IMAGE
        const isDone = 'load'
        return {
          ...plank,
          render_url,
          url,
          isShow: true,
          isDone,
          // 服务器下来的板件数据type全为serve
          type: 'serve',
          id,
          create_time: it.create_time,
        }
      })
      commit('setServePlankStore', servePlanks)
      return servePlanks
    },
  },
}

function getStoreListById(stores: AwaitStoreItem[][], uniqueId: string) {
  let index = -1
  let list: AwaitStoreItem[] = []
  for (const store of stores) {
    const idx = store.findIndex(
      (it: AwaitStoreItem) => it.partUniqueId === uniqueId
    )
    if (idx !== -1) {
      index = idx
      list = store
      break
    }
  }
  return { index, list }
}

// 处理放进待排版库且是服务器上的板件时需要查看participationPaibanPlank是否存在当前板件的id存在需要删除说明板件已回到待排版库不需要再判断隐藏这个板件了
function delServePush(state: IStore, plank: AwaitStoreItem) {
  // 如果是服务器上的数据 每次添加进入前都需要将之前存入用作筛选的已在页面的数据删除当前id，避免错误的匹配
  state.participationPaibanPlank = state.participationPaibanPlank.filter(
    (ids) => !ids.includes(plank.partUniqueId)
  )
}

export default awaitPaibanStore
