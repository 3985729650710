import Vue from 'vue'

const EventBusKey = {
  /** 翻译相关触发 */
  TRANSLATE: 'TRANSLATE',
  KNIVES_ERR: 'KNIVES_ERR', // 用于临时处理找不到刀
  OTHER_KNIVES_ERR: 'OTHER_KNIVES_ERR', // 另外的刀错误信息
  PREFERENCES_UPDATE: 'PREFERENCES_UPDATE', // 偏好设置更新
}

export { EventBusKey }
export default new Vue()
