///
/// 曲线处理对象
///
//
//  处理导入的CAD路径等曲线信息.
//

var libCurve = {
  libName: 'libCurve',
  libVersion: '0.1',
  minSegments: 30,
  maxSegments: 300,
}

function align2float(v) {
  return Math.round(v * 100) / 100
}

//  曲线处理类
libCurve.Curve = function (name) {
  function doLineTo(poly, pt) {
    if (typeof pt.x != 'number' || typeof pt.y != 'number') {
      return
    }
    poly.push({
      x: pt.x,
      y: pt.y,
    })
  }

  function iLengthOf(dir) {
    return Math.sqrt(dir.x * dir.x + dir.y * dir.y)
  }

  function iOffsetOf(radias, pt) {
    return Math.sqrt(radias * radias - (pt.x * pt.x + pt.y * pt.y))
  }

  function iCalcAngle(pt1, pt2) {
    var dx = pt1.x - pt2.x
    var dy = pt1.y - pt2.y

    var radias = iLengthOf({
      x: dx,
      y: dy,
    })
    var vsin = dy / radias
    var angle = Math.asin(vsin)
    if (dx < 0) angle = 3.14159265358979 - angle
    return angle
  }

  function doBulgeTo(poly, pt, iLimit) {
    var p1 = {
      x: 0,
      y: 0,
    }
    var p2 = pt

    if (poly.length > 0) {
      p1 = poly[poly.length - 1]
    }

    var clockwise = pt.b > 0
    var bulge = Math.abs(pt.b)

    var dir = { x: p2.x - p1.x, y: p2.y - p1.y }
    var cet = { x: (p2.x + p1.x) / 2, y: (p2.y + p1.y) / 2 }
    var distance = iLengthOf(dir)
    if (typeof distance != 'number') return

    var segments = Math.floor(distance)
    if (segments < libCurve.minSegments) segments = libCurve.minSegments
    if (!iLimit) {
      if (segments > libCurve.maxSegments) segments = libCurve.maxSegments
    }

    //  计算半径
    var th = (bulge * Math.PI) / 360
    var hd = distance / 2
    var radius = hd / Math.sin(th)

    //  圆心方向的向量
    if (clockwise) {
      dir = { x: dir.y, y: -dir.x }
    } else {
      dir = { x: -dir.y, y: dir.x }
    }

    //  圆心向量长度标定为半径
    var opt = { x: cet.x - p1.x, y: cet.y - p1.y }
    var l = iOffsetOf(radius, opt)
    if (typeof l != 'number') {
      l = 0
    }
    var scldir = l / iLengthOf(dir)
    dir.x = dir.x * scldir
    dir.y = dir.y * scldir

    //  求出圆心位置
    var oo = { x: cet.x + dir.x, y: cet.y + dir.y }

    //  插值计算
    var as = iCalcAngle(p1, oo)
    var es = iCalcAngle(p2, oo)

    if (clockwise) {
      while (es > as) es -= Math.PI * 2
    } else {
      while (es < as) es += Math.PI * 2
    }

    var step = (es - as) / segments
    //        console.log("radius:", radius, oo);

    for (var i = 1; i < segments; i++) {
      var angle = as + step * i
      var ox = Math.cos(angle) * radius
      var oy = Math.sin(angle) * radius
      var tx = oo.x + ox
      var ty = oo.y + oy
      //            console.log(i, "ox:", ox, " oy:", oy, " tx:", tx, "ty:", ty);
      doLineTo(poly, { x: tx, y: ty })
    }
    //        console.log("\n\n\n");

    doLineTo(poly, p2)
  }

  this.points = []

  this.movedPt = { x: 0, y: 0, left: 0, top: 0 }
  this.getMovedPt = function () {
    return this.movedPt
  }

  this.lineTo = function (x, y) {
    var pt = { x: x, y: y, b: 0 }
    this.points.push(pt)
  }

  this.bugleTo = function (x, y, b) {
    var pt = { x: x, y: y, b: b }
    this.points.push(pt)
  }

  this.getPolygon = function (iLimit) {
    var poly = []
    var points = this.points
    if (!points) return poly

    var count = points.length

    doLineTo(poly, points[count - 1])

    for (var k = 0; k < count; k++) {
      var ptex = points[k]
      if (ptex.b == 0 || ptex.b === null || ptex.b === undefined) {
        doLineTo(poly, ptex)
      } else {
        doBulgeTo(poly, ptex, iLimit)
      }
    }

    return poly
  }

  this.getCurve = function () {
    var cv = gg.createCurve()
    var points = this.points
    for (var k in points) {
      var p = points[k]
      cv.addPoint(p.x, p.y, p.b)
    }
    return cv
  }

  this.count = function () {
    return this.points.length
  }

  this.pointAt = function (idx) {
    return this.points[idx]
  }

  this.removePoint = function (idx) {
    this.points.splice(idx, 1)
  }

  this.insertPoints = function (idx, pts) {
    // 最后一个点
    if (idx == 0) {
      for (var i in pts) {
        this.points.push(pts[i])
      }
    } else {
      for (var i in pts) {
        this.points.splice(idx++, 0, pts[i])
      }
    }
  }

  this.replacePoint = function (idx, pt) {
    this.points.splice(idx, 1, pt)
  }

  this.debug = function () {
    for (var i in this.points) {
      var pt = this.points[i]
      //            console.log(JSON.stringify(pt));
    }
  }

  this.toJsonString = function () {
    for (var k in this.points) {
      this.points[k].x = Math.floor(this.points[k].x * 100) / 100
      this.points[k].y = Math.floor(this.points[k].y * 100) / 100
    }
    return JSON.stringify(this.points)
  }

  this.fromJsonString = function (str) {
    var obj = JSON.parse(str)
    if (obj.hasOwnProperty('vertex')) {
      obj = obj.vertex
    }
    this.points = obj
  }

  //  设定原点
  this.setOrigPoint = function (x, y) {
    var points = this.points
    for (var k in points) {
      points[k].x -= x
      points[k].y -= y
    }
  }

  //  缩放数据
  this.scalePoints = function (sc) {
    var points = this.points
    for (var k in points) {
      if (sc > 0) {
        points[k].x *= sc
        points[k].y *= sc
      } else if (sc < 0) {
        points[k].x /= -sc
        points[k].y /= -sc
      }
    }
  }

  //  旋转90度
  this.rotate90 = function () {
    var points = this.points
    for (var k in points) {
      var pt = points[k]
      var nx = pt.x
      var ny = pt.y
      pt.x = -ny
      pt.y = nx
    }
  }

  //  方向变换
  this.reversePoints = function () {
    var points = this.points
    //数组反向
    points.reverse()
    var count = points.length
    var pointB0 = points[count - 1].b
    for (var k = count - 1; k > 0; k--) {
      points[k].b = -points[k - 1].b
    }
    points[0].b = -pointB0
    this.refreshClockwise()
  }

  //  变换角度方向
  this.switchCurveDirection = function () {
    var points = this.points
    for (var k in points) {
      points[k].b = -points[k].b
    }
  }

  //  修改格式
  this.changeFormat = function () {
    var na = new Array()
    var oa = this.points
    var count = oa.length
    if (count == 0) return
    for (var i = 0; i < count; i++) {
      var k = (i + count - 1) % count
      var p1 = oa[k]
      var p2 = oa[i]
      var pr = { x: p2.x, y: p2.y, b: p1.b }
      na.push(pr)
    }

    this.points = na
    this.refreshClockwise()
  }

  //  检查闭合特性
  this.checkClosure = function () {
    var points = this.points
    if (!points) return
    var count = points.length
    if (count == 0) return
    var p1 = points[0]
    var p2 = points[count - 1]
    if (p1.x == p2.x && p1.y == p2.y) {
      if (p2.b != 0) p1.b = p2.b
      points.splice(count - 1, 1)
    }
    this.refreshClockwise()
  }

  //  镜像操作
  this.mirrorByY = function () {
    var points = this.points
    for (var k in points) {
      points[k].x = -points[k].x
    }
    this.reversePoints()
    this.switchCurveDirection()
    this.refreshClockwise()
  }

  //  设置标准原点及尺寸
  this.expandBound = function (bd) {
    var points = this.points
    var count = points.length
    for (var i = 0; i < count; i++) {
      var pt = points[i]
      bd.minx = Math.min(bd.minx, pt.x)
      bd.miny = Math.min(bd.miny, pt.y)
      bd.maxx = Math.max(bd.maxx, pt.x)
      bd.maxy = Math.max(bd.maxy, pt.y)
    }
  }

  this.makeStandardOrig = function () {
    var points = this.points
    var minx = 100000
    var miny = 100000
    var maxx = -minx
    var maxy = -miny

    var count = points.length
    for (var i = 0; i < count; i++) {
      var pt = points[i]
      minx = Math.min(minx, pt.x)
      miny = Math.min(miny, pt.y)
      maxx = Math.max(maxx, pt.x)
      maxy = Math.max(maxy, pt.y)
    }

    this.width = maxx - minx
    this.height = maxy - miny

    var ox = minx + this.width / 2
    var oy = miny + this.height / 2
    this.setOrigPoint(ox, oy)
  }

  //  检测曲线方向.
  function getPtArrayArea(pts) {
    if (!pts) return 0
    var len = pts.length
    var area = 0
    if (len < 3) return 0

    var spt = pts[0]
    for (var i = 0; i < len - 1; i++) {
      var p1 = pts[i]
      var p2 = pts[i + 1]
      area += p1.x * p2.y - p2.x * p1.y
    }
    p2 = spt
    p1 = pts[len - 1]
    area += p1.x * p2.y - p2.x * p1.y
    area = area / 2.0
    //console.log("AREA IS", area);
    return area
  }

  this.refreshClockwise = function () {
    var area = getPtArrayArea(this.points)
    this.isClockwise = area > 0.0
  }

  //  对齐数值
  this.alignPoints = function () {
    var points = this.points
    var count = points.length
    for (var i = 0; i < count; i++) {
      var pt = points[i]
      pt.x = align2float(pt.x)
      pt.y = align2float(pt.y)
      pt.b = align2float(pt.b)
    }
  }

  //  加载
  this.loadCurve = function (name) {
    var cv = libMaterial.loadCurve(name)
    this.points = cv.vertex
    this.refreshClockwise()
  }

  if (name != undefined) {
    this.loadCurve(name)
  }

  this.boundRect = function () {
    var rect = {}
    var minX = 0,
      minY = 0,
      maxX = 0,
      maxY = 0
    for (var index = 0; index < this.points.length; ++index) {
      var pt = this.points[index]
      if (index) {
        if (pt.x < minX) {
          minX = pt.x
        }
        if (pt.x > maxX) {
          maxX = pt.x
        }
        if (pt.y < minY) {
          minY = pt.y
        }
        if (pt.y > maxY) {
          maxY = pt.y
        }
      } else {
        minX = pt.x
        maxX = pt.x
        minY = pt.y
        maxY = pt.y
      }
    }
    rect['left'] = minX
    rect['right'] = maxX
    rect['top'] = minY
    rect['bottom'] = maxY
    return rect
  }

  this.moveBy = function (iOffsetX, iOffsetY, iLeft, iTop) {
    this.movedPt.x = iOffsetX
    this.movedPt.y = iOffsetY
    if (iLeft !== null) {
      this.movedPt.left = iLeft
    }
    if (iTop !== null) {
      this.movedPt.top = iTop
    }

    for (var index = 0; index < this.points.length; ++index) {
      var pt = this.points[index]
      pt.x += iOffsetX
      pt.y += iOffsetY
    }
  }
}

//  构造Curve对象
libCurve.newCurve = function (name) {
  return new libCurve.Curve(name)
}

//  加载曲线对象
//-----------------------------------------------------------------------------
libCurve.Curves = function (name, isPath) {
  console.log('打印name', name)
  this.paths = []
  if (name) {
    if (isPath) {
      var icv = libGG.loadFileContent(name)
      var cv = JSON.parse(icv)
    } else {
      var cv = libMaterial.loadShapeData(name)
    }
    this.initFromJson(cv)
  }
}

;(function () {
  var CCurve = libCurve.Curves.prototype

  //  从JSON对象构建
  CCurve.initFromJson = function (cv) {
    function pointsToPath(pts) {
      var cv = libCurve.newCurve()
      cv.points = pts
      cv.checkClosure()
      cv.refreshClockwise()
      return cv
    }

    this.paths.push(pointsToPath(cv.vertex))

    if (cv.extras) {
      for (var k in cv.extras) {
        var vs = cv.extras[k]
        this.paths.push(pointsToPath(vs))
      }
    }

    if (cv.props == undefined) {
      cv.props = { width: 0, height: 0 }
    }

    this.data = cv
    this.genPoints()
  }

  //  产生点标记(用于UI控制)
  CCurve.genPoints = function () {
    var idxs = []
    for (var k in this.paths) {
      var p = this.paths[k]
      for (var i in p.points) {
        idxs.push({ ic: k, ip: i })
      }
    }
    this.tags = idxs
  }

  CCurve.getPathAt = function (ic) {
    return this.paths[ic]
  }

  CCurve.getPathCount = function () {
    return this.paths.length
  }

  CCurve.getPointAt = function (ic, ip) {
    return this.paths[ic].points[ip]
  }

  CCurve.toJsonString = function () {
    this.alignPoints()
    var obj = this.data
    obj.vertex = this.paths[0].points
    var extras = []
    for (var i = 1; i < this.paths.length; i++) {
      extras.push(this.paths[i].points)
    }
    obj.extras = extras

    return JSON.stringify(obj)
  }

  CCurve.initAdjust = function () {
    for (var k in this.paths) {
      var cv = this.paths[k]
      cv.points.reverse()
      cv.reversePoints()
      cv.switchCurveDirection()
    }
  }

  CCurve.rotate90 = function () {
    for (var k in this.paths) {
      var cv = this.paths[k]
      cv.rotate90()
    }
  }

  CCurve.scale = function (s) {
    for (var k in this.paths) {
      var cv = this.paths[k]
      cv.scalePoints(s)
    }
  }

  CCurve.mirrorByY = function () {
    for (var k in this.paths) {
      var cv = this.paths[k]
      cv.mirrorByY()
    }
  }

  CCurve.alignPoints = function () {
    for (var k in this.paths) {
      var cv = this.paths[k]
      cv.alignPoints()
    }
  }

  CCurve.setOrigPoint = function (x, y) {
    for (var k in this.paths) {
      var cv = this.paths[k]
      cv.setOrigPoint(x, y)
    }
  }

  CCurve.autoOrig = function () {
    var bd = { minx: 100000, miny: 100000, maxx: -100000, maxy: -100000 }
    for (var k in this.paths) {
      var cv = this.paths[k]
      cv.expandBound(bd)
    }

    this.setSize(bd.maxx - bd.minx, bd.maxy - bd.miny)

    var ox = bd.minx + this.getWidth() / 2
    var oy = bd.miny + this.getHeight() / 2
    this.setOrigPoint(ox, oy)
  }

  CCurve.setSize = function (w, h) {
    this.data.props.width = align2float(w)
    this.data.props.height = align2float(h)
  }

  CCurve.getWidth = function () {
    return this.data.props.width
  }

  CCurve.getHeight = function () {
    return this.data.props.height
  }

  CCurve.removeCurve = function (ic) {
    return this.paths.splice(ic, 1)
  }

  CCurve.appendCurve = function (curve) {
    function pointsToPath(pts) {
      var cv = libCurve.newCurve()
      cv.points = pts
      cv.checkClosure()
      cv.refreshClockwise()
      return cv
    }
    return this.paths.push(pointsToPath(curve))
  }

  CCurve.getSpec = function () {
    var vertex = this.data.vertex
    if (vertex.length < 1) return false

    var xMin = vertex[0].x
    var xMax = vertex[0].x
    var yMin = vertex[0].y
    var yMax = vertex[0].y

    for (var i in vertex) {
      var x = vertex[i].x
      var y = vertex[i].y

      if (xMin > x) xMin = x
      if (xMax < x) xMax = x

      if (yMin > y) yMin = y
      if (yMax < y) yMax = y
    }

    var width = this.getWidth() // xMax - xMin;
    var height = this.getHeight() // yMax - yMin;
    var offsetX = width * 0.5 - xMax
    var offsetY = height * 0.5 - yMax

    return {
      /*width : width, height : height, */ offsetX: offsetX,
      offsetY: offsetY,
    }
  }
})()

libCurve.newCurves = function (path, isPath) {
  var res = new libCurve.Curves(path, isPath)
  return res
}

export default libCurve
