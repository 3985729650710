/** 处理板件数据的文件 */
import type { PointType, PointUpperCase } from '@/partTypes'

const SCALE = 10000
/**
 * 扩大板件路径
 * @param offset 偏移量 正数路径扩张 负数路径内缩
 * @param path 路径
 * @returns
 */
export function expandPath(offset: number, path: PointUpperCase[]) {
  window.ClipperLib.JS.ScaleUpPath(path, SCALE)
  const co = new window.ClipperLib.ClipperOffset()
  const offsetPaths = new window.ClipperLib.Paths()
  co.Clear()
  co.AddPath(
    path,
    window.ClipperLib.JoinType.jtMiter,
    window.ClipperLib.EndType.etClosedPolygon
  )
  //   执行扩张
  co.Execute(offsetPaths, offset * SCALE)
  const result = JSON.parse(JSON.stringify(offsetPaths))
  //   返回处理好的路径
  return result.map((path: any[]) =>
    path.map((point) => ({
      x: Number((point.X / SCALE).toFixed(3)),
      y: Number((point.Y / SCALE).toFixed(3)),
    }))
  )
}
