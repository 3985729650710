import store from '@/store'
import { generatorSideHoleSlot } from '@/util/LayoutFuncs.js'
import { isSideMachineHole, isSideMachineSlot } from '@/util/commonFuncs'
import { getPlateKnifeDiameter } from '@/util/dealPaibanData'
import { getByToken } from '@/util/vueRequest'

export function getEdgeValue(name, edgeInfo) {
  switch (name) {
    case 'LE':
      return String(edgeInfo[0] ?? 0)
    case 'BE':
      return String(edgeInfo[1] ?? 0)
    case 'RE':
      return String(edgeInfo[2] ?? 0)
    case 'TE':
      return String(edgeInfo[3] ?? 0)
  }
}

// 更新EdgeInfo信息
export function updateEdgeInfo(template, graghEdge) {
  if (!graghEdge) {
    return
  }
  let { topEdgeVal, bottomEdgeVal, leftEdgeVal, rightEdgeVal } = graghEdge || {}
  if (template.type === 'plank') {
    // 如果是大板标签，那么要修改图形四条边显示的val
    if (topEdgeVal) {
      return [leftEdgeVal, bottomEdgeVal, rightEdgeVal, topEdgeVal]
    } else {
      if (
        store.state.ncSetting.xyReverse &&
        (store.state.ncSetting.startPosition == '右下角' ||
          store.state.ncSetting.startPosition == '左上角')
      ) {
        // 避免旋转后，数字显示位置不在左边和下边
        return [leftEdgeVal, '', '', bottomEdgeVal]
      }
      return [leftEdgeVal, bottomEdgeVal, '', '']
    }
  }
  return null
}

/**
 * 
 * @param {*} option 
 * {
 *  openDir: 开口方向
 *  topEdgeVal: 四个方向的值
    bottomEdgeVal
    leftEdgeVal
    rightEdgeVal
    startX
    startY
    doc
    scale
 * }
 */

export function drawPdfPlankL(option) {
  let {
    openDir,
    topEdgeVal,
    bottomEdgeVal,
    leftEdgeVal,
    rightEdgeVal,
    startX,
    startY,
    doc,
    scale,
    plankH,
    plankW,
  } = option

  switch (openDir) {
    case 'r-t': // 开口在右上（暂时只有一种情况）
      doc.path([
        {
          op: 'm',
          c: [startX, startY],
        },
        {
          op: 'l',
          c: [startX + topEdgeVal * scale, startY],
        },
        {
          op: 'l',
          c: [
            startX + topEdgeVal * scale,
            startY + (plankH - rightEdgeVal) * scale,
          ],
        },
        {
          op: 'l',
          c: [
            startX + plankW * scale,
            startY + (plankH - rightEdgeVal) * scale,
          ],
        },
        {
          op: 'l',
          c: [startX + plankW * scale, startY + plankH * scale],
        },
        {
          op: 'l',
          c: [startX, startY + plankH * scale],
        },
        {
          op: 'h',
        },
      ])
      break

    case 'l-b':
      // 注意如果是在左下方的时候，需要逆时针旋转90度
      ;[topEdgeVal, rightEdgeVal, bottomEdgeVal, leftEdgeVal] = [
        rightEdgeVal,
        bottomEdgeVal,
        leftEdgeVal,
        topEdgeVal,
      ]
      // doc.path([
      //   {
      //     op: 'm',
      //     c: [startX, startY],
      //   },
      //   {
      //     op: 'l',
      //     c: [startX + plankW * scale, startY],
      //   },
      //   {
      //     op: 'l',
      //     c: [startX + plankW * scale, startY + plankH * scale],
      //   },
      //   {
      //     op: 'l',
      //     c: [
      //       startX + (plankW - bottomEdgeVal) * scale,
      //       startY + plankH * scale,
      //     ],
      //   },
      //   {
      //     op: 'l',
      //     c: [
      //       startX + (plankW - bottomEdgeVal) * scale,
      //       startY + leftEdgeVal * scale,
      //     ],
      //   },
      //   {
      //     op: 'l',
      //     c: [startX, startY + leftEdgeVal * scale],
      //   },
      //   {
      //     op: 'h',
      //   },
      // ])
      // 此时绘制开口逻辑按照右下来
      drawPdfPlankL({
        ...option,
        rightEdgeVal,
        bottomEdgeVal,
        leftEdgeVal,
        topEdgeVal,
        openDir: 'r-b',
      })
      break
    case 'r-b':
      doc.path([
        {
          op: 'm',
          c: [startX, startY],
        },
        {
          op: 'l',
          c: [startX + plankW * scale, startY],
        },
        {
          op: 'l',
          c: [startX + plankW * scale, startY + rightEdgeVal * scale],
        },
        {
          op: 'l',
          c: [startX + bottomEdgeVal * scale, startY + rightEdgeVal * scale],
        },
        {
          op: 'l',
          c: [startX + bottomEdgeVal * scale, startY + plankH * scale],
        },
        {
          op: 'l',
          c: [startX, startY + plankH * scale],
        },
        {
          op: 'h',
        },
      ])
      break
  }
}

export function getPaibanTime() {
  let time = store.state.paibanTime
    ? store.state.paibanTime
    : new Date().getTime()
  const date = new Date(time)
  const Y = date.getFullYear() + '-'
  const M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  const D = date.getDate() + ' '
  const h = date.getHours() + ':'
  const m = date.getMinutes() + ':'
  const s = date.getSeconds()
  const result = Y + M + D + h + m + s
  return result
}

export function handleGenFileNameStr(data, folderSetting) {
  let str = ''
  let addressArr = []
  let orderNoArr = []
  let addressOrderNoArr = []
  let orderNoAddressArr = []
  data = data.map((item) => {
    if (item.parts) {
      return item.parts
    } else {
      return item
    }
  })
  data.flat().forEach((part) => {
    if (part.address && !addressArr.includes(part.address)) {
      addressArr.push(part.address)
    }
    if (part.orderNo && !orderNoArr.includes(part.orderNo)) {
      orderNoArr.push(part.orderNo)
    }
    if (
      (part.address ?? '') + (part.orderNo ?? '') &&
      !addressOrderNoArr.includes((part.address ?? '') + (part.orderNo ?? ''))
    ) {
      addressOrderNoArr.push((part.address ?? '') + (part.orderNo ?? ''))
    }
    if (
      (part.orderNo ?? '') + (part.address ?? '') &&
      !orderNoAddressArr.includes((part.orderNo ?? '') + (part.address ?? ''))
    ) {
      orderNoAddressArr.push((part.orderNo ?? '') + (part.address ?? ''))
    }
  })
  if (folderSetting.orderCodeAsDirectionName) {
    str = orderNoArr.join(',')
  } else {
    if (
      folderSetting.wrapper_folder.length <= 1 ||
      addressOrderNoArr.length <= 1
    ) {
      folderSetting.wrapper_folder.forEach((tag) => {
        switch (tag) {
          case '项目地址':
            str += addressArr.join(',')
            break

          case '订单号':
            str += orderNoArr.join(',')
            break

          case '排版时间':
            str += getPaibanTime().replace(/\:/g, '_')
            break
          default:
            break
        }
      })
    } else if (
      folderSetting.wrapper_folder.indexOf('项目地址') <
      folderSetting.wrapper_folder.indexOf('订单号')
    ) {
      str = !folderSetting.wrapper_folder.includes('排版时间')
        ? addressOrderNoArr.join(',')
        : getPaibanTime() + addressOrderNoArr.join(',')
    } else {
      str = !folderSetting.wrapper_folder.includes('排版时间')
        ? orderNoAddressArr.join(',')
        : getPaibanTime() + orderNoAddressArr.join(',')
    }
  }
  str =
    str.length > folderSetting.max_file_name
      ? str.substring(0, folderSetting.max_file_name) + '...'
      : str
  return str
}

// 处理文件管理合并文件
function dealWrapperFolder(arr, data, toggleColon) {
  let part = store.state.paibanData[0].parts[0]
  let str = handleGenFileNameStr(
    store.state.paibanData,
    store.state.ncSetting.customizeFolder
  )
  if (toggleColon && str) {
    str = str.replace(/:/g, '_')
  }
  return {
    firstLevelFileName: str,
    data: data,
    arr,
  }
}

// 生成一级文件名
// toggleColon: 是否转化冒号
export function genFirstLevelFileName(toggleColon) {
  if (store.state.ncSetting.customizeFolder) {
    return dealWrapperFolder(
      store.state.ncSetting.customizeFolder.wrapper_folder,
      store.state.ncSetting.customizeFolder,
      toggleColon
    )
  }
  return new Promise((resolve, reject) => {
    getByToken(
      '/load_customize_folder_setting',
      {
        setting_id: store.state.ncSetting.process_setting_id,
      },
      (res) => {
        if (res.status === 1) {
          resolve(
            dealWrapperFolder(res.data.wrapper_folder, res.data, toggleColon)
          )
        } else {
          reject()
        }
      }
    )
  })
}
// 余料边长信息
export function getSurplusSize(paths, ncSetting, stockKey) {
  const diameterR = getPlateKnifeDiameter(stockKey, ncSetting)
  let str = ''
  let size
  let shape
  let max_long = 0,
    min_long = 0,
    max_width = 0,
    min_width = 0
  let arr = dealNotchDir(paths, ncSetting, stockKey)
  if (arr.length > 2) {
    size = arr.reduce(
      (pre, cut) => {
        if (cut.dir == 'left' || cut.dir == 'right') {
          pre[0].push(parseFloat((cut.text - diameterR).toFixed(2)))
        } else {
          pre[1].push(parseFloat((cut.text - diameterR).toFixed(2)))
        }
        return pre
      },
      [[], []]
    )
    max_long = Math.max.apply(null, size[0])
    min_long = Math.min.apply(null, size[0])
    max_width = Math.max.apply(null, size[1])
    min_width = Math.min.apply(null, size[1])
    size = size.sort((a, b) => {
      a = a.sort((a, b) => b - a)
      b = b.sort((a, b) => b - a)
      const am = Math.max(a[0], a[1])
      const bm = Math.max(b[0], b[1])
      return bm - am
    })
    const [long, short] = size
    str = `${parseInt(Number(long[0]).toFixed(2))}(${parseInt(
      Number(long[1]).toFixed(2)
    )})*${parseInt(Number(short[0]).toFixed(2))}(${parseInt(
      Number(short[1]).toFixed(2)
    )})`
    shape = 1
  } else {
    let wh = arr.map((it) => +it.text - diameterR)
    wh = wh.map((item) => parseInt(item))
    size = wh
    max_long = size[1]
    max_width = size[0]
    str = wh.join('*')
    shape = 0
  }
  return {
    str,
    size,
    shape,
    max_long,
    min_long,
    max_width,
    min_width,
  }
}
// 余料长边短边的位置
export function dealNotchDir(path) {
  let xArr = [],
    yArr = []
  path.forEach((it) => (xArr.push(it.x), yArr.push(it.y)))
  const [maxX, maxY] = [Math.max(...xArr), Math.max(...yArr)]
  if (path.length < 5) {
    return [
      { text: maxX, dir: 'width' },
      { text: maxY, dir: 'height' },
    ]
  }
  const notchDir = {
    LT: { x: 0, y: 0 },
    RT: { x: maxX, y: 0 },
    LB: { x: 0, y: maxY },
    RB: { x: maxX, y: maxY },
  }
  const dirKey = Object.keys(notchDir)
  let res = ''
  for (const key of dirKey) {
    const p = notchDir[key]
    const f = path.find((it) => it.x === p.x && it.y === p.y)
    if (!f) res = key
  }
  let midX, midY
  const findPath = path.find(
    (it) => it.x > 0 && it.x < maxX && it.y > 0 && it.y < maxY
  )
  if (findPath) {
    midX = findPath.x
    midY = findPath.y
  }
  let left, bottom, right, top
  switch (res) {
    case 'LT':
      left = maxY - midY
      bottom = maxX
      right = maxY
      top = maxX - midX
      break
    case 'LB':
      left = midY
      bottom = maxX - midX
      right = maxY
      top = maxX
      break
    case 'RT':
      left = maxY
      bottom = maxX
      right = maxY - midY
      top = midX
      break
    case 'RB':
      left = maxY
      bottom = midX
      right = midY
      top = maxX
      break
  }
  return [
    { text: left, dir: 'left' },
    { text: bottom, dir: 'bottom' },
    { text: right, dir: 'right' },
    { text: top, dir: 'top' },
  ]
}

export const SURPLUS_TAG_TEXT_OPTIONS = [
  {
    att: '',
    type: 'FixedText',
    name: '板件材质：',
    x: 12,
    y: 12,
    fontSize: 9,
    fontWeight: 'bold',
  },
  {
    att: 'matCode',
    type: 'DataSource',
    name: '',
    x: 57,
    y: 12,
    fontSize: 9,
    fontWeight: 'bold',
  },
  {
    att: '',
    type: 'FixedText',
    name: '板件颜色：',
    x: 12,
    y: 32,
    fontSize: 9,
    fontWeight: 'bold',
  },
  {
    att: 'texture',
    type: 'DataSource',
    name: '',
    x: 57,
    y: 32,
    fontSize: 9,
    fontWeight: 'bold',
  },
  {
    att: '',
    type: 'FixedText',
    name: '板件尺寸：',
    x: 12,
    y: 52,
    fontSize: 9,
    fontWeight: 'bold',
  },
  {
    att: 'plankSize',
    type: 'DataSource',
    name: '',
    x: 57,
    y: 52,
    fontSize: 9,
    fontWeight: 'bold',
  },
]

export const TEXT_OPTIONS = [
  {
    att: '',
    type: 'FixedText',
    name: '材质：',
    x: 12,
    y: 12,
    fontSize: 9,
    fontWeight: '',
  },
  {
    att: 'matCode',
    type: 'DataSource',
    name: '',
    x: 37,
    y: 12,
    fontSize: 9,
    fontWeight: '',
  },
  {
    att: '',
    type: 'FixedText',
    name: '颜色：',
    x: 12,
    y: 27,
    fontSize: 9,
    fontWeight: '',
  },
  {
    att: 'texture',
    type: 'DataSource',
    name: '',
    x: 37,
    y: 27,
    fontSize: 9,
    fontWeight: '',
  },
  {
    att: '',
    type: 'FixedText',
    name: '板件大小：',
    x: 12,
    y: 42,
    fontSize: 9,
    fontWeight: '',
  },
  {
    att: 'plankSize',
    type: 'DataSource',
    name: '',
    x: 57,
    y: 42,
    fontSize: 9,
    fontWeight: '',
  },
  {
    att: '',
    type: 'FixedText',
    name: '板件厚度：',
    x: 12,
    y: 57,
    fontSize: 9,
    fontWeight: '',
  },
  {
    att: 'thick',
    type: 'DataSource',
    name: '',
    x: 57,
    y: 57,
    fontSize: 9,
    fontWeight: '',
  },
]

// 在旋转方法中抽取出来的对于侧孔侧槽信息的处理
export function dealRotateShotsHoles(part, nc_setting) {
  let { sslots, sholes, slots, holes } = part
  sslots = JSON.parse(JSON.stringify(sslots))
  sholes = JSON.parse(JSON.stringify(sholes))
  // sholes
  for (let k in sholes) {
    let shole = sholes[k]
    let side = shole['side']

    side = parseInt(side) + 1
    if (side <= 0) {
      side = 4
    }
    if (side >= 5) {
      side = 1
    }
    shole['side'] = side
  }

  // sslots
  for (let j in sslots) {
    let sslot = sslots[j]
    let side = sslot['side']
    side = parseInt(side) + 1
    if (side <= 0) {
      side = 4
    }
    if (side >= 5) {
      side = 1
    }

    sslot['side'] = side
  }

  let sholeInfo = generatorSideHoleSlot([
    ...sholes,
    ...holes.filter((hole) => isSideMachineHole(hole) && hole.side == 1),
  ])
  let sholeInfoF = generatorSideHoleSlot(
    holes.filter((hole) => isSideMachineHole(hole) && hole.side == -1),
    'upDownFlip'
  )
  let sslotInfo = generatorSideHoleSlot([
    ...sslots,
    ...slots.filter((slot) => isSideMachineSlot(slot) && slot.side == 1),
  ])
  let sslotInfoF = generatorSideHoleSlot(
    slots.filter((slot) => isSideMachineSlot(slot) && slot.side == -1),
    'upDownFlip'
  )

  return {
    sholes: sholeInfo,
    sholesF:
      sholeInfoF.length == 2 ? sholeInfoF.replace('翻面', '') : sholeInfoF,
    sslots: sslotInfo,
    sslotsF:
      sslotInfoF.length == 2 ? sslotInfoF.replace('翻面', '') : sslotInfoF,
  }
}

export function getGraghEdge(plank, ncSetting) {
  let graghEdge = {}
  let size
  let shouldReverse = false
  if (
    ncSetting.xyReverse &&
    (ncSetting.startPosition == '右下角' || ncSetting.startPosition == '左上角')
  ) {
    shouldReverse = true
  }
  if (plank.surplusInfo) {
    if (plank.surplusInfo.shape === 'lshape') {
      // L型
      let { x3, x4, x5, y3, y4, width, height } = plank.surplusInfo
      // 短宽
      let shortWidth = x5 > x3 ? x3 : width - x3
      // 长宽
      let longWidth = width
      // 短高
      let shortHeight = y3 > y4 ? y4 : height - y4
      // 长高
      let longHeight = height

      size = `${longWidth}(${shortWidth})*${longHeight}(${shortHeight})*${plank.thick}`
      if (shouldReverse) {
        // 修改size的显示
        size = `${longHeight}(${shortHeight})*${longWidth}(${shortWidth})*${plank.thick}`
      }
      // 根据x5、x4的位置，确定左右两边的值
      if (x5 > x4) {
        graghEdge.rightEdgeVal = shortHeight
        graghEdge.leftEdgeVal = longHeight
        if (y3 > y4) {
          // 现在只有开口右上，以及翻转过后的开口在左下
          graghEdge.openDir = 'r-t'
          if (shouldReverse) {
            graghEdge.rightEdgeVal = longHeight
            graghEdge.leftEdgeVal = shortHeight
            graghEdge.openDir = 'l-b'
          }
        } else {
          // graghEdge.openDir = 'r-b'
        }
      } else {
        // graghEdge.rightEdgeVal = longHeight
        // graghEdge.leftEdgeVal = shortHeight
        // if (y3 > y4) {
        //   graghEdge.openDir = 'l-t'
        // } else {
        //   graghEdge.openDir = 'l-b'
        // }
      }

      // 根据y3、y4的位置，确定上下两边的值
      if (y3 > y4) {
        graghEdge.bottomEdgeVal = longWidth
        graghEdge.topEdgeVal = shortWidth
        if (shouldReverse) {
          graghEdge.bottomEdgeVal = shortWidth
          graghEdge.topEdgeVal = longWidth
        }
      } else {
        // graghEdge.bottomEdgeVal = shortWidth
        // graghEdge.topEdgeVal = longWidth
      }
    } else {
      // 非L型
      size = `${plank.plankWidth}*${plank.plankHeight}*${plank.thick}`
      if (shouldReverse) {
        // 修改size的显示
        size = `${plank.plankHeight}*${plank.plankWidth}*${plank.thick}`
      }
      graghEdge = {
        leftEdgeVal: plank.plankHeight,
        bottomEdgeVal: plank.plankWidth,
      }
    }
  } else {
    // 矩形
    size = `${plank.plankWidth}*${plank.plankHeight}*${plank.thick}`
    if (shouldReverse) {
      // 修改size的显示
      size = `${plank.plankHeight}*${plank.plankWidth}*${plank.thick}`
    }
    graghEdge = {
      leftEdgeVal: plank.plankHeight,
      bottomEdgeVal: plank.plankWidth,
    }
  }

  return {
    graghEdge,
    size,
  }
}
