import { BluenVersions, CookieKeys } from '@/data/constant'
import Cookies from 'js-cookie'

/**
 * @description 处理云排版在不同版本之前的跳转
 * @param userInfo 用户信息
 */
export function bluenVersionJump(userInfo: { bluen_version: string }) {
  const userUseVersion = userInfo.bluen_version || BluenVersions.v1
  const oldVersion = Cookies.get(CookieKeys.BLUEN_VERSION) || BluenVersions.v1
  // 设置版本信息
  Cookies.set(CookieKeys.BLUEN_VERSION, userUseVersion)
  if (oldVersion !== userUseVersion) {
    // 重新刷新页面让服务器返回对应的前端资源信息
    window.location.reload()
  }
}
