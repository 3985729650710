import { httpRequest } from '@/apis/request'

enum Apis {
  buryPoint = 'record_function_use_info',
}
interface BuryPoint {
  function_module: string
  function_point: string
}

export function buryPoint(data: BuryPoint | BuryPoint[]) {
  const isArr = Array.isArray(data)
  return httpRequest.post(Apis.buryPoint, {
    track_functions: isArr ? data : [data],
  })
}
