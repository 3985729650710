import { httpRequest } from '@/apis/request'
import {
  FetchTaskHistoryListPayload,
  GetTaskListPayload,
  PutIntoBatchPayload,
  RefuseTaskPayload,
  SendToTaskPayload,
  TaskType,
} from '@/views/taskList/types/taskCard'

enum Apis {
  taskList = 'task_list',
  taskListRefused = 'tasks/refused',
  taskHistory = 'archived_list',
}

/**
 * @description 获取任务列表接口
 * @link https://api.thinkerx.com/web/#/p/a4d423e481fcc1cec8e54f1ea6c19238
 */
export function fetchTaskList(payload: GetTaskListPayload) {
  return httpRequest.get(Apis.taskList, payload)
}

/**
 * @description 发送任务接口
 * @link https://api.thinkerx.com/web/#/p/941ad3fe0300ab54f327427191a726cd
 */
export function sendToTaskList(payload: SendToTaskPayload) {
  return httpRequest.post(Apis.taskList, payload)
}

/**
 * @description 拒绝任务接口
 * @link https://api.thinkerx.com/web/#/p/00f2343df3d7d3ad4a67e3b7ce1946bc
 */
export function refuseTask(payload: RefuseTaskPayload) {
  return httpRequest.put(Apis.taskList, { ...payload, current_step: 1 })
}

/**
 * @description 添加至批量列表接口
 * @link https://api.thinkerx.com/web/#/p/00f2343df3d7d3ad4a67e3b7ce1946bc
 */
export function putIntoBatchList(payload: PutIntoBatchPayload) {
  return httpRequest.put(Apis.taskList, { ...payload, current_step: 2 })
}

/**
 * @description 从批量移除接口
 * @link https://api.thinkerx.com/web/#/p/00f2343df3d7d3ad4a67e3b7ce1946bc
 */
export function cancelFromBatchList(payload: PutIntoBatchPayload) {
  return httpRequest.put(Apis.taskList, { ...payload, current_step: 0 })
}

/**
 * @description 更新任务为已排版
 * @link https://api.thinkerx.com/web/#/p/00f2343df3d7d3ad4a67e3b7ce1946bc
 */
export function updateToHasPaiban(
  payload: PutIntoBatchPayload & { current_step: number }
) {
  return httpRequest.put(Apis.taskList, payload)
}

/**
 * @description 删除任务接口
 * @link https://api.thinkerx.com/web/#/p/8f706ec6fc6a0a089c6a7370df5e9430
 */
export function deleteTask(payload: { task_ids: Array<TaskType['id']> }) {
  const url =
    (Apis.taskList as string) + `?task_ids=${payload.task_ids.join(',')}`
  return httpRequest.delete(url)
}

/**
 * @description 获取存档
 * @link https://api.thinkerx.com/web/#/p/aac50ba3576c76cd129d900d591a71b2
 */
export function fetchTaskHistoryList(payload: FetchTaskHistoryListPayload) {
  return httpRequest.get(Apis.taskHistory, payload)
}

/**
 * @description 存档任务
 * @link https://api.thinkerx.com/web/#/p/00f2343df3d7d3ad4a67e3b7ce1946bc
 */

export function saveTaskToHistory(payload: PutIntoBatchPayload) {
  return httpRequest.put(Apis.taskList, { ...payload, current_step: 4 })
}

/**
 * @description 删除存档
 * @link https://api.thinkerx.com/web/#/p/43c55da9a1f190f44268bf7851e6eb84
 */
export function deleteBatchHistory(payload: { batch_ids: number[] }) {
  const url =
    (Apis.taskHistory as string) + `?batch_ids=${payload.batch_ids.join(',')}`
  return httpRequest.delete(url)
}
