/** 和 file system api相关的数据库*/
enum StorageKey {
  currentUserFolder = 'currentUserFolder',
  folders = 'folders',
}
type Keys = keyof typeof StorageKey
/** 存储数据的格式 */
export interface Data {
  /** 文件句柄 */
  handle: any
  /** 主键，可以不填的 */
  id?: number
  /** 原始主键,当前使用句柄携带，用于和已使用的文件组匹配,目前不用，暂时通过文件名来匹配，如不行再使用(由于使用可能会造成性能问题) */
  oriId?: number
}
export class GenerateFileDB {
  /** 数据库实例 */
  db!: IDBDatabase
  dbName = 'generate-file-manage-db'
  /** 主键 */
  private primary = 'id' as const
  /** 当前文件的默认主键 */
  static currentUserFolderPrimaryKey = 1
  constructor() {
    if (!window.indexedDB) throw new Error('浏览器不支持indexedDB')
  }
  /** 打开数据库 */
  open(version = 1) {
    return new Promise<IDBDatabase>((resolve, reject) => {
      const request = window.indexedDB.open(this.dbName, version)
      request.onsuccess = (event: any) => {
        const db = event?.target?.result
        this.db = db
        resolve(request.result)
      }
      request.onerror = (event: any) => {
        reject(event)
      }
      request.onupgradeneeded = (event: any) => {
        const db = event?.target?.result
        this.db = db
        // 创建当前使用文件存储库
        this.createStore(StorageKey.currentUserFolder, {
          keyPath: this.primary,
        })
        // 创建所有文件存储库
        this.createStore(StorageKey.folders, {
          keyPath: this.primary,
          autoIncrement: true,
        })
      }
    })
  }
  /** 创建一个库 */
  createStore(storeName: Keys, data: IDBObjectStoreParameters) {
    if (!this.db) throw new Error('数据库未打开')
    if (!this.db.objectStoreNames.contains(storeName)) {
      this.db.createObjectStore(storeName, data)
    }
  }
  /** 为数据库添加数据 */
  add(storeName: Keys, data: Data) {
    return new Promise((resolve, reject) => {
      const request = this.db
        .transaction([storeName], 'readwrite')
        .objectStore(storeName)
        .add(data)
      request.onsuccess = (event: any) => {
        resolve(event)
      }
      request.onerror = (event: any) => {
        reject(event)
      }
    })
  }
  /** 直接覆盖数据 */
  put(storeName: Keys, data: Data) {
    return new Promise((resolve, reject) => {
      // 如果为currentUserFolder表，那么主键唯一，currentUserFolder只会存在一个
      if (storeName === 'currentUserFolder') {
        data[this.primary] = GenerateFileDB.currentUserFolderPrimaryKey
      }
      const request = this.db
        .transaction([storeName], 'readwrite')
        .objectStore(storeName)
        .put(data)
      request.onsuccess = (event: any) => {
        resolve(event)
      }
      request.onerror = (event: any) => {
        reject(event)
      }
    })
  }
  /** 根据主键查询单个数据 */
  getData(storeName: Keys, primary: string | number) {
    return new Promise<Data>((resolve, reject) => {
      const request = this.db
        .transaction([storeName], 'readonly')
        .objectStore(storeName)
        .get(primary)
      request.onsuccess = (event: any) => {
        resolve(event.target.result)
      }
      request.onerror = (event: any) => {
        reject(event)
      }
    })
  }
  /** 查询所有数据 */
  getAll(storeName: Keys) {
    return new Promise<Data[]>((resolve, reject) => {
      const request = this.db
        .transaction([storeName], 'readonly')
        .objectStore(storeName)
        .getAll()
      request.onsuccess = (event: any) => {
        resolve(event.target.result)
      }
      request.onerror = (event: any) => {
        reject(event)
      }
    })
  }
  /** 根据主键id删除 */
  delete(storeName: Keys, id: number) {
    return new Promise((resolve) => {
      const request = this.db
        .transaction([storeName], 'readwrite')
        .objectStore(storeName)
        .delete(id)
      request.onsuccess = (event: any) => {
        resolve(event.target.result)
      }
      request.onerror = (event: any) => {
        resolve(event)
      }
    })
  }
  /** 关闭数据库 */
  close() {
    this.db.close()
  }
}

/**
 * 向文件导出管理数据库添加数据
 * @param store 库名
 * @param data 需要存储的数据
 */
export async function addFileDBData(
  store: Keys,
  data: Data,
  addType: 'put' | 'add' = 'add'
) {
  const db = new GenerateFileDB()
  await db.open()
  await db[addType](store, data)
  db.close()
}

/**
 * 获取文件导出数据库的某个库数据,
 * @param store 库名，库名固定，且取值方式固定
 * @returns
 */
export async function getFileDBData(store: Keys): Promise<Data | Data[]> {
  const db = new GenerateFileDB()
  await db.open()
  let data: Data | Data[]
  if (store === StorageKey.currentUserFolder) {
    data = await db.getData(store, GenerateFileDB.currentUserFolderPrimaryKey)
  } else {
    data = await db.getAll(store)
  }
  db.close()
  return data
}

/**
 * 向文件导出管理数据库添加数据
 * @param store 库名
 * @param id 需要删除的主键
 */
export async function deleteFileDBData(store: Keys, id: number) {
  const db = new GenerateFileDB()
  await db.open()
  await db.delete(store, id)
  db.close()
}
