import { sendToTaskList } from '@/apis/taskList'
import store from '@/store'
import { dealGuiguiJson } from '@/util/dealGuiguiJson'
import { dealPreLayoutDataLabelID } from '@/util/dealPaibanData'
import { dealExcelData, dealExcelManifest } from '@/util/plankCommonFuncs'
import { showCardDetail } from '@/util/showCardDetail'
import { uploadFile } from '@yige/utils'
import { nanoid } from 'nanoid'
import Vue from 'vue'
import XLSX from 'xlsx'

export async function createTask(layoutData: any[]) {
  let taskDetail = {} as any
  if (layoutData) {
    taskDetail = showCardDetail(layoutData)
  }
  // 用于存放订单对应的板子数据
  const plankDataMap = new Map([])
  // 取出所有板子对应的所用订单id
  // 取出所有板子需要的材质颜色厚度信息
  const orderInfoList = Array.from(
    new Set(
      layoutData.map((part) => {
        return JSON.stringify({
          orderName: part.address,
          orderId: part.orderId
            ? part.orderId
            : part.groupNo
            ? part.groupNo
            : '',
          orderNo: part.orderNo
            ? part.orderNo
            : part.groupNo
            ? part.groupNo
            : '',
        })
      })
    )
  ).map((str) => JSON.parse(str))
  // 按订单id将板件分类存放进plankDataMap
  orderInfoList.forEach((orderInfo) => {
    if (orderInfo.orderId) {
      plankDataMap.set(
        orderInfo.orderId,
        layoutData.filter((part) => part.orderId == orderInfo.orderId)
      )
    } else {
      plankDataMap.set(
        orderInfo.orderNo,
        layoutData.filter((part) => part.orderNo == orderInfo.orderNo)
      )
    }
  })
  const promises = orderInfoList.map(async (orderInfo) => {
    const plankJson = new File(
      [
        new Blob(
          [
            JSON.stringify({
              layoutData: plankDataMap.get(
                orderInfo.orderId ? orderInfo.orderId : orderInfo.orderNo
              ),
            }),
          ],
          { type: '' }
        ),
      ],
      `taskData_${new Date().getTime()}.json`,
      {
        type: '',
        lastModified: Date.now(),
      }
    )
    const res = await uploadFile({
      file: plankJson,
      ossDir: 'bluenMaterialData',
    })
    await sendToTaskList({
      sender: store.state.userInfo.id,
      receiver: store.state.userInfo.id,
      /** TODO  按导入的任务算，暂时不传  wc */
      // oids: [orderInfo.orderId],
      // room_ids: Array.from(
      //   new Set(
      //     plankDataMap.get(orderInfo.orderId).map((part) => {
      //       if (part.roomID) return part.roomID
      //     })
      //   )
      // ),
      platform: 2,
      plank_json: JSON.stringify({
        orderName: orderInfo.orderName,
        orderId: orderInfo.orderId,
        orderNo: orderInfo.orderNo,
        matCode: taskDetail?.matCode ? taskDetail?.matCode : '',
        texDir: taskDetail?.texDir ? taskDetail?.texDir : '',
        texture: taskDetail?.texture ? taskDetail?.texture : '',
        url: res,
      }),
    })
  })
  const res = await Promise.all(promises)
  return res
}

export async function dealFileData(
  file: any,
  callBack?: (res: {
    status: number
    plankList: any[]
    errText: string
  }) => void
) {
  let plankArr: any[] = []
  // 不可缺少的板件字段
  const indispensableHeaderDataList = [
    '材质',
    '颜色',
    '厚度',
    '成品长度',
    '成品宽度',
    '数量',
    '纹理',
  ]
  const fileType = file.name.split('.').pop()
  const fileReader = new FileReader()
  let isLockNecessaryKey = false
  if (['xls', 'xlsx'].includes(fileType)) {
    fileReader.readAsBinaryString(file)
    fileReader.onload = (event) => {
      try {
        const data = event.target?.result
        const workbook = XLSX.read(data, { type: 'binary' })
        const wsname = workbook.SheetNames[0]
        let ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname])

        const plankKeys = Object.keys(ws[0] as any)
        const lockKeys: any[] = []
        isLockNecessaryKey = indispensableHeaderDataList.some((key) => {
          if (!plankKeys.includes(key)) {
            lockKeys.push(key)
          }
          return !plankKeys.includes(key)
        })
        if (isLockNecessaryKey) {
          throw Error(
            `板件数据缺失字段【${lockKeys.join('、')}】对应的数据，无法导入！`
          )
        } else {
          ws = dealExcelManifest(ws, wsname)
          dealExcelData(ws, 'xls').then(async (res) => {
            if (res && res.plankArr) {
              plankArr = res.plankArr
              callBack &&
                callBack({
                  status: 1,
                  plankList: plankArr,
                  errText: '',
                })
            }
          })
        }
      } catch (err: any) {
        const errText = String(err).split(':')[1]
        const errRes = dealImportErr(errText) as any
        callBack &&
          callBack({
            status: errRes.status,
            plankList: [],
            errText: errRes.errText,
          })
        return false
      }
    }
  } else if (fileType == 'csv') {
    fileReader.readAsText(file, 'GBK')
    fileReader.onload = (event) => {
      try {
        const lockKeys: string[] = []
        if (event.target) {
          if (event.target.result == '' || event.target.result == '\r\n') {
            throw Error(`没有查询到数据！`)
          }
          const plankKeys = String(event.target.result).split(',')
          isLockNecessaryKey = indispensableHeaderDataList.some((key) => {
            if (!plankKeys.includes(key)) {
              lockKeys.push(key)
            }
            return !plankKeys.includes(key)
          })
          if (isLockNecessaryKey) {
            throw Error(
              `板件数据缺失字段【${lockKeys.join('、')}】对应的数据，无法导入！`
            )
          } else {
            dealExcelData(event, 'csv').then(async (res) => {
              if (res && res.plankArr) {
                const hasErr = checkPlankDataErr(res.plankArr)
                if (hasErr) {
                  callBack &&
                    callBack({
                      status: 0,
                      plankList: [],
                      errText: '板件数据缺失字段的必要数据，请确认文件内容！',
                    })
                } else {
                  plankArr = res.plankArr
                  callBack &&
                    callBack({
                      status: 1,
                      plankList: plankArr,
                      errText: '',
                    })
                }
              } else {
                callBack &&
                  callBack({
                    status: 0,
                    plankList: [],
                    errText: '板件数据读取错误，无法导入！',
                  })
              }
            })
          }
        }
      } catch (err) {
        const errText = String(err).split(':')[1]
        const errRes = dealImportErr(errText) as any
        callBack &&
          callBack({
            status: errRes.status,
            plankList: [],
            errText: errRes.errText,
          })
        return false
      }
    }
  } else if (fileType == 'json') {
    fileReader.readAsText(file)
    fileReader.onloadend = async (event) => {
      if (event.target && event.target.result) {
        const data = JSON.parse(event.target.result as any)
        store.commit('setRecodrProductionLineChoose', data.process_setting_name)
        if (data.hasOwnProperty('layoutResult')) {
          store.commit('clearPaibanData')
          const result = await dealGuiguiJson(JSON.parse(data.layoutResult))
          plankArr = dealPreLayoutDataLabelID(
            result.map((item: any) => {
              return {
                ...item,
                partUniqueId: nanoid(),
              }
            })
          )
          callBack &&
            callBack({
              status: 1,
              plankList: plankArr,
              errText: '',
            })
        } else if (data.hasOwnProperty('layoutData')) {
          data.layoutData = dealPreLayoutDataLabelID(
            data.layoutData.map((item: any) => {
              return {
                ...item,
                partUniqueId: nanoid(),
              }
            })
          )
          // 导入数据时, 删除排版相关数据
          store.commit('clearPaibanData')
          plankArr = data.layoutData
          callBack &&
            callBack({
              status: 1,
              plankList: plankArr,
              errText: '',
            })
        }
      }
    }
  } else {
    Vue.prototype.$message.error('请选择正确的文件!')
    callBack &&
      callBack({
        status: 0,
        plankList: [],
        errText: '',
      })
    return false
  }
}

// 检查板子数据是否缺失必要数据
function checkPlankDataErr(plankList: any[]) {
  const necessaryKeys = [
    'matCode',
    'texture',
    'thick',
    'specHeight',
    'specWidth',
    'amount',
    'texDir',
  ]
  const isLockNecessaryKey = plankList.some((part) => {
    const islock = necessaryKeys.some((key) => {
      return !part[key]
    })
    return islock
  })

  return isLockNecessaryKey
}

function dealImportErr(errText: string) {
  if (errText.includes('缺失字段')) {
    return {
      status: 0,
      errText,
    }
  } else {
    return {
      status: 0,
      errText: '解析文件内容失败，请确认文件内容!',
    }
  }
}

export function getPlankOrderInfo(plankList: any[]) {
  const orderAddressList = Array.from(
    new Set(
      plankList.map((plank) => {
        if (plank.orderNo) {
          if (plank.address) {
            return plank.orderNo + plank.address
          } else {
            return plank.orderNo
          }
        } else {
          if (plank.address) {
            return plank.address
          } else {
            return ''
          }
        }
      })
    )
  )
  const orderIdList = Array.from(
    new Set(plankList.map((plank) => plank.orderId))
  )
  const roomIds = Array.from(new Set(plankList.map((plank) => plank.room_ids)))

  return {
    order_address: orderAddressList.join(','),
    order_ids: orderIdList.join(','),
    room_ids: roomIds,
  }
}
