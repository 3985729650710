import type { PreferencesSetting } from '@/store/modules/preferencesSetting/types'
import { PartType } from '@/views/materialsList/types/part'

import { httpRequest } from '../request'
import type {
  CommonResponse,
  IGetFolderSettingResponseType,
  IGetLayoutDataParamsType,
  IGetProductionNcSettingResponseType,
  ISmtFileResponse,
} from './types'

enum Apis {
  preferences = 'get_preferences_setting',
  savePreferences = 'save_preferences_setting',
  getLayoutData = 'get_layout_data',
  loadFolderSetting = 'load_customize_folder_setting',
  generateSmtFile = 'electronic_saw',
  getProductionNcSetting = 'get_production_nc_setting',
  saveBaseMaterial = 'save_plank_manage_setting_info',
  checkSyncGuiguiData = 'check_guigui_plate_data',
  syncGuiguiData = 'sync_guigui_data',
  getMaterialTransData = 'get_material_trans_data',
  productionTrialRoom = 'production/trial_room',
}

export function getPreferencesSetting(
  productionFrom?: string,
  platform?: number
) {
  productionFrom = productionFrom ? '柜门' : ''
  return httpRequest.get<CommonResponse<PreferencesSetting>>(Apis.preferences, {
    production_from: productionFrom,
    platform,
  })
}
export function savePreferencesSetting(data: PreferencesSetting) {
  return httpRequest.post<CommonResponse>(Apis.savePreferences, { data })
}

// 获取文件信息
export const getCustomFolderSetting = (params: { setting_id: number }) => {
  return httpRequest.get<CommonResponse<IGetFolderSettingResponseType>>(
    Apis.loadFolderSetting,
    params
  )
}

// 生成电子锯文件
export const fetchGenerateSmtFile = (params: {
  uid: string
  process_id: number
  layoutData: PartType[]
}) => {
  return httpRequest.post<CommonResponse<ISmtFileResponse[]>>(
    Apis.generateSmtFile,
    params
  )
}

// 获取nc文件
export const getProductionNcSetting = (params: {
  setting_id: number
  production_from?: 'guimen'
}) => {
  return httpRequest.get<CommonResponse<IGetProductionNcSettingResponseType>>(
    Apis.getProductionNcSetting,
    params
  )
}

// 保存原片管理数据
export const fetchSaveBaseMaterial = (params: {
  uid: number
  data: {
    specialPlankInfo: any[]
  }
}) => {
  return httpRequest.post<CommonResponse>(Apis.saveBaseMaterial, params)
}

// 检测是否需要同步柜柜原片数据
export const fetchCheckSyncGuiguiData = (params = {}) => {
  return httpRequest.post<CommonResponse<{ is_same: boolean }>>(
    Apis.checkSyncGuiguiData,
    params
  )
}
// 同步柜柜原片数据
export const fetchSyncGuiguiData = (params: { uid: number }) => {
  return httpRequest.post<CommonResponse>(Apis.syncGuiguiData, params)
}

// 柜柜跳转后的订单信息
export const fetchGuiguiOrderInfo = (params: { id: string }) => {
  return httpRequest.get<CommonResponse>(Apis.getMaterialTransData, params)
}

// 柜柜跳转，通过订单信息获取预处理后的数据
export function fetchGetLayoutData(params: IGetLayoutDataParamsType) {
  return httpRequest.post<CommonResponse>(Apis.getLayoutData, params)
}

// 试生产流程化
export const fetchMaterialTransData = (params: {
  is_get_layout_data: number
}) => {
  return httpRequest.get<CommonResponse>(Apis.productionTrialRoom, params)
}
