import { messages } from '@/data/i18n'
import { PlainObj } from '@yige/ui'
import { get } from 'lodash-es'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
// 这个 langCode 取用百度翻译的code，https://fanyi-api.baidu.com/doc/21
// 'zh' | 'en' | 'vie' | 'bur' | 'may'
interface Messages {
  [langCode: string]: {
    [key: string]: any
  }
}

type TranslateOptions =
  | {
      customerTranslate: PlainObj // customerTranslate 就是用户自定义翻译的内容，之所以要加上customerTranslate，是因为为了确保customerTranslate修改以后这儿也能拿到最新的
    }
  | (() => PlainObj) // 当为函数时就应该直接返回自定义的翻译对象

/**
 *
 * @param messages // 组合好的各种语言的 message
 * @param customerTranslate //
 * @returns
 */
export const formatI18n = (
  locale: string,
  messages: Messages,
  translateStore: TranslateOptions
) => {
  const i18n = new VueI18n({
    locale, // 设置地区
    messages, // 设置地区信息
    silentTranslationWarn: true, // 隐藏警告信息
    // 这里有问题，需要后续处理
    formatter: {
      interpolate(this, message, values: any, path) {
        const translatedMessage =
          (typeof translateStore === 'function'
            ? translateStore()[get(messages['zh'], path)]
            : translateStore.customerTranslate[get(messages['zh'], path)]) ||
          message
        const reg = /{(\w+)}/g
        if (!reg.test(translatedMessage)) {
          return [translatedMessage]
        }
        const interpolatedMessage = translatedMessage.replace(
          reg,
          (_: string, key: any) => {
            return values?.[key] ? values[key] : ''
          }
        )
        return [interpolatedMessage]
      },
    },
    // formatter: {
    //   interpolate(message, values, path) {
    //     // 在这里实现插值逻辑
    //     // 返回插值数组
    //     return [
    //       (typeof translateStore === 'function'
    //         ? // 根据path拿到对应的中文，再根据中文匹配自定义的内容
    //           translateStore()[get(messages['zh'], path)]
    //         : translateStore.customerTranslate[get(messages['zh'], path)]) ||
    //         message,
    //     ]
    //   },
    // },
  })
  return i18n
}
