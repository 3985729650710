import Calendar from './calendar'
import DatePicker from './data-picker'
import Pagination from './pagination'
import TimePicker from './time-picker'

export default {
  locale: 'zh-cn',
  Pagination: Pagination,
  DatePicker: DatePicker,
  TimePicker: TimePicker,
  Calendar: Calendar,
  // locales for all comoponents
  global: {
    placeholder: 'Таңдаңыз',
  },
  Table: {
    filterTitle: 'Фильтр',
    filterConfirm: 'OK',
    filterReset: 'Тазарту',
    filterEmptyText: 'Фильтр жоқ',
    emptyText: 'Деректер жоқ',
    selectAll: 'Барлығын таңдау',
    selectInvert: 'Таңдауды төңкеру',
    selectionAll: 'Барлық деректерді таңдаңыз',
    sortTitle: 'Сұрыптау',
    expand: 'Жолды жазу',
    collapse: 'Жолды бүктеу',
    triggerDesc: 'Төмендеуді сұрыптау үшін басыңыз',
    triggerAsc: 'Өсу ретімен сұрыптау үшін басыңыз',
    cancelSort: 'Сұрыптаудан бас тарту үшін басыңыз',
  },
  Modal: {
    okText: 'Жарайды',
    cancelText: 'Болдырмау',
    justOkText: 'Жарайды',
  },
  Popconfirm: {
    okText: 'Жарайды',
    cancelText: 'Болдырмау',
  },
  Transfer: {
    titles: ['', ''],
    searchPlaceholder: 'Іздеу',
    itemUnit: 'элемент.',
    itemsUnit: 'элемент.',
    remove: 'Жою',
    selectAll: 'Барлық деректерді таңдау',
    selectCurrent: 'Ағымдағы бетті таңдау',
    selectInvert: 'Кері тәртіпте көрсету',
    removeAll: 'Барлық деректерді жою',
    removeCurrent: 'Ағымдағы парақты өшіру',
  },
  Upload: {
    uploading: 'Жүктеу...',
    removeFile: 'Файлды жою',
    uploadError: 'Жүктеу кезінде қате пайда болды',
    previewFile: 'Файлды алдын ала қарау',
    downloadFile: 'Файлды жүктеу',
  },
  Empty: {
    description: 'Деректер жоқ',
  },
  Icon: {
    icon: 'белгішесі',
  },
  Text: {
    edit: 'Өңдеу',
    copy: 'Көшіру',
    copied: 'Көшірілді',
    expand: 'Жазу',
  },
  PageHeader: {
    back: 'Артқа',
  },
}
