import { httpRequest } from '@/apis/request'

enum Apis {
  thirdData = 'open-api/material',
}

interface ThirdDataPayload {
  token: string
  bluen_data_key: string
}

export function getThirdData(data: ThirdDataPayload) {
  return httpRequest.post(Apis.thirdData, data)
}
