import { fetchAllColor } from '@/apis/common/materialColor'
import { fetchAllMatCode } from '@/apis/common/materialMatCode'
import store from '@/store'
import jsrsasign from 'jsrsasign'

export const labelSources = ['orderNo', 'roomName', 'loc']
/**
 *
 * @param {[string]} arr 需要去掉的url参数
 * @returns 重组后的url
 */
function deleteUrlParam(arr) {
  let param = getUrlVars()
  for (let i = 0; i < arr.length; i++) {
    if (param.hasOwnProperty(arr[i])) {
      delete param[arr[i]]
    }
  }
  let paramStr = []
  for (let i in param) {
    paramStr.push(i + '=' + param[i])
  }
  paramStr = paramStr.join('&')
  // 获取router
  let routerStr = ''
  let router = window.location.href.split('#')
  if (router.length > 1) {
    router = router[1]
    router = router.split('?')
    routerStr = router[0]
  }
  let href = window.location.href.split('?')[0].split('#')[0]
  let url =
    href + (routerStr ? '#' + routerStr : '') + (paramStr ? '?' + paramStr : '')
  return url
}
function getUrlVars() {
  var vars = {},
    hash
  let url = window.location.href
  let hashes = []
  let temp1 = url.split('#')
  if (temp1.length > 1) {
    let temp2 = temp1[0].split('?')
    if (temp2.length > 1) {
      hashes = hashes = hashes.concat(temp2[1].split('&'))
    }
    temp2 = temp1[1].split('?')
    if (temp2.length > 1) {
      hashes = hashes = hashes.concat(temp2[1].split('&'))
    }
  } else {
    hashes = url.slice(url.indexOf('?') + 1).split('&')
  }
  // 判断浏览器是否兼容URLSearchParams, 如果不兼容则走简单的split
  try {
    if (url.includes('?')) {
      let searchUrl = window.location.href.split('?')[1]
      let params = new URLSearchParams(searchUrl)
      for (var i = 0; i < hashes.length; ++i) {
        hash = hashes[i].split('=')
        vars[hash[0]] = params.get(hash[0])
      }
    }
  } catch (err) {
    for (var i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=')
      if (hash[0] == 'guigui_token') {
        let newHash = hash[1]
        if (newHash.includes('%')) {
          newHash = decodeURIComponent(newHash)
        } else if (!newHash.includes('=')) {
          newHash = newHash + '='
        }
        vars[hash[0]] = newHash
      } else {
        vars[hash[0]] = hash[1]
      }
    }
  }
  return vars
}
const priK = `-----BEGIN PRIVATE KEY-----
    MIICeQIBADANBgkqhkiG9w0BAQEFAASCAmMwggJfAgEAAoGBAK5eOnCsq8RGu0EV
    /2Ojott5txP7FKf2Bvo/f4rjZm8G8rgtMqFoXwxl4wpvvo3M1m8yUp2P+O2P5fro
    zpQwXcmah16W0PHQUv1qpYutxFZoNrhjsO5X8t9ROUyRhggmF19dFDH/Cuc46K5b
    FfgFqmdH84nwo5k24xWIP20r9xIZAgMBAAECgYEAmyv78d2YFlmjtq4u0qsC+TH9
    /OgKX9aQKw9rH1xzAgc65TBDyGQrgcsCG32KJILPqbEs2E8RhcfzMDER9cp9Cr2Q
    5CW492Fc4SNrRESEaOw0Qg9N79KAtIAL/qXuxaQg0FMpuddvG55na4m7LKgw6+Gv
    ChJgaakKSrDukcje2qECQQDhEW4cacy7QWDGuPs4M+QvNEdJ4yEU2KzyNDBZQbPB
    CRUZdsVIFoxhtcZsfmjNzEJS7cP37r3AhnJweNY0CaEtAkEAxlUFGxGJlwMdpOoQ
    TnpTcPywxFVDZavOn2Jh6BecsYJBtrAXxjL+/5pz59sDsKPleYAHVSVHb5E2/Blq
    PiEQHQJBAKPjN1UFTYmFf9z00ioIzz34SXjqJSqy5KyxiAtWpngIDw8tx1ZI5Qw5
    ezWBjvtco2cTr8GYXSAWRrrTotbdtDkCQQDCtnOFyKjxFkEDSDxsH5JN6fdNOXwq
    lAsN1VPTvWK7rur/BVDB6A0LOkKyjQXJZI8EwtnwEOJaORHnXLg+eM1NAkEAvhZE
    hBRKMXAF1FzJLjKgJw/ibc+nle0Bz6qX4/bd2BlzyZyIEC/hLKZwBOv8ZMZJTJAH
    zrsdbU5HAqvhp0JpKA==
    -----END PRIVATE KEY-----`
function calcKeyByRSA(newStr) {
  newStr = calcRsaKey(newStr)
  // 将密钥转码
  let rsa = jsrsasign.KEYUTIL.getKey(priK)
  var sig = new jsrsasign.KJUR.crypto.Signature({ alg: 'SHA256withRSA' })
  // 初始化
  sig.init(rsa)
  // 传入待加密字符串
  sig.updateString(newStr)
  // 生成密文
  var sign = jsrsasign.hextob64(sig.sign())
  // 对加密后内容进行URI编码
  sign = encodeURIComponent(sign)
  //把参数与密文拼接好，返回
  var params = sign
  return params
}
function calcRsaKey(obj) {
  let str = ''
  let arr = Object.keys(obj)
  // 按键名从小到大排序，验签传参需排序
  let sortArr = arr.sort((a, b) => a.localeCompare(b))
  for (let i = 0; i < sortArr.length; i++) {
    str += `&${sortArr[i]}=${obj[sortArr[i]]}`
  }
  str = str.slice(1)
  return str
}
export default {
  deleteUrlParam,
  getUrlVars,
  calcKeyByRSA,
  calcRsaKey,
}

export function cacleColorList(list) {
  const newList = []
  list.forEach((item) => {
    const currentItem = {
      value: item.color_name ?? item.name,
      label: item.color_name ?? item.name,
      children: null,
    }
    if (item.children) {
      currentItem.value = currentItem.value + '_catalog'
      currentItem.children = cacleColorList(item.children)
    }
    if (item.color) {
      const itemColors = item.color.map((e) => {
        return {
          label: e.color_name ?? e.name,
          value: e.color_name ?? e.name,
        }
      })
      currentItem.children = currentItem.children.concat(itemColors)
    }
    newList.push(currentItem)
  })
  return newList
}

export function checkColorIsExist(color, colorList, re = []) {
  if (!colorList.length) return false
  let isExist = false
  for (let i = 0; i < colorList.length; i++) {
    const item = colorList[i]
    if (item.children) {
      if (item.children.length) {
        const copyRe = JSON.parse(JSON.stringify(re))
        re.push(item.value)
        const hasExist = checkColorIsExist(color, item.children, re)

        if (!hasExist) {
          re = copyRe
        } else {
          isExist = true
          re = hasExist
          break
        }
      }
    } else {
      if (item.value == color) {
        re.push(item.value)
        isExist = true
        break
      }
    }
  }
  if (isExist) {
    return re
  } else return false
}

// 材质部分的判断
export function checkMatCodeIsExist(matCode, matcodeList) {
  // 检查数据里面是否已经存在matCode
  for (let item of matcodeList) {
    if (item.label === matCode || !matCode) {
      return true
    }
  }
  return false
}
export async function fetchMaterialMatCodes() {
  // 需要传入 参数需要uid
  const res = await fetchAllMatCode(store.state.userInfo.id)
  // 获取本地保存的数据
  const otherMatCodes = localStorage.getItem('matcodeList')
    ? JSON.parse(localStorage.getItem('matcodeList'))
    : []
  let resultList = []
  if (res && res?.status === 1) {
    resultList = res.data.map((item) => {
      return {
        label: item,
        value: item,
      }
    })
  }
  resultList = resultList.concat(
    otherMatCodes.map((matCode) => ({
      label: matCode,
      value: matCode,
    }))
  )

  return resultList
}
export async function fetchMaterialColors() {
  store.commit('setIsLoadingColors', true)
  const res = await fetchAllColor()
  store.commit('setIsLoadingColors', false)
  const otherColors = localStorage.getItem('colorList')
    ? JSON.parse(localStorage.getItem('colorList'))
    : []
  let resultList = []
  if (res && res.status) {
    resultList = cacleColorList(res.data)
  }
  resultList.push({
    value: 'other',
    label: '其他',
    children: otherColors.map((color) => ({
      label: color,
      value: color,
    })),
    leaf: false,
  })
  store.commit('setMaterialColorList', resultList)
}

// 用于翻译vue文件中导入的外部数据文本
export function translate(key, values) {
  if (key) {
    return window.i18n.t(key, values)
  } else {
    return ''
  }
}

/**
 * @description 对于浏览器的检测
 */
export class Browser {
  /** 是否是Edge */
  static isEdge() {
    // 使用静态缓存，缓存计算结果
    if (typeof this._isEdge !== 'undefined') return this._isEdge
    this._isEdge = /[E|e]dg/.test(window.navigator.userAgent)
    return this._isEdge
  }
}
export function judgeSourceData(arr, type = 'compare') {
  // 将超过限制数量的数据源放入overLimitSources
  const judgeLimitSource = {}
  for (let v of labelSources) {
    const result = []
    for (let item of arr) {
      if (
        !result.includes((type == 'compare' ? item[v] : item.part[v]) ?? '')
      ) {
        result.push((type == 'compare' ? item[v] : item.part[v]) ?? '')
      }
    }
    if (!judgeLimitSource[v]) {
      judgeLimitSource[v] = {}
    }
    judgeLimitSource[v] = result
  }
  return judgeLimitSource
}
// 生成范围内的随机整数[m,n]
export function randomInt(m, n) {
  return Math.floor(Math.random() * (n - m + 1)) + m
}
// 生成颜色对应的数据colorRes
export function genColorRes(judgeL) {
  const colorBase = {},
    colorRes = {}
  // 给定的12色 每个标签区域的颜色
  // 门板可能是用固定的颜色展示
  const colorList = [
    '#F9D7D9',
    '#FFDBB1',
    '#C3FFD6',
    '#89E1C5',
    '#EFD374',
    '#DFDA81',
    '#FBA5DA',
    '#DFCBFF',
    '#E4FAAC',
    '#C4EEAA',
    '#9BB4F3',
    '#CEF7FD',
  ]
  // 选中一个从对应的初始颜色库里面把这个颜色删除
  for (let source of labelSources) {
    // 设置初始颜色库
    colorBase[source] = [].concat(colorList)
    const sourceValue = judgeL[source]
    for (let key of sourceValue) {
      if (!colorRes[source]) {
        colorRes[source] = {}
      }
      const randomNum = randomInt(0, colorBase[source].length - 1)
      colorRes[source][key] = colorBase[source][randomNum]
      // 删除这个颜色，保证同种数据源不会重复到同一种颜色
      colorBase[source].splice(randomNum, 1)
    }
  }
  return colorRes
}
// 传入坐标数据，判断是否构成矩形
export function judgeRectangle(points) {
  if (points.length !== 4) {
    return false
  }
  const [p1, p2, p3, p4] = points
  // 计算向量
  const v1 = { x: p2.x - p1.x, y: p2.y - p1.y }
  const v2 = { x: p3.x - p2.x, y: p3.y - p2.y }
  const v3 = { x: p4.x - p3.x, y: p4.y - p3.y }
  const v4 = { x: p1.x - p4.x, y: p1.y - p4.y }
  // 判断向量是否垂直
  const isPerpendicular = (v1, v2) => {
    return v1.x * v2.x + v1.y * v2.y == 0
  }
  // 判断四边两两垂直
  if (
    !isPerpendicular(v1, v2) ||
    !isPerpendicular(v2, v3) ||
    !isPerpendicular(v3, v4) ||
    !isPerpendicular(v4, v1)
  ) {
    return false
  }
  // 判断对边是否相等
  const length = (v) => {
    return Math.sqrt(v.x * v.x + v.y * v.y)
  }
  if (length(v1) !== length(v3) || length(v2) !== length(v4)) {
    return false
  }
  return true
}
