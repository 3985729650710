import zhData from './json/zh'

// 获取一个对象的所有value的联合类型
type GetValueType<T> = T extends Record<string, infer V> ? GetValueType<V> : T

type ZhDataType = {
  [key: string]: ZhDataType | string
}
// 根据中文内容获取对应的映射
function generateLanguageMap(
  data: ZhDataType,
  languageMap: Map<string, string> = new Map(),
  parentKeys: string[] = []
) {
  Object.keys(data).forEach((key) => {
    parentKeys.push(key)
    const mapKey = data[key]
    if (typeof mapKey === 'string') {
      languageMap.set(mapKey, parentKeys.join('.'))
    } else if (typeof mapKey === 'object') {
      generateLanguageMap(mapKey, languageMap, parentKeys)
    }
    parentKeys.pop()
  })
  return languageMap
}

const languageMap = generateLanguageMap(zhData)

// 翻译文本
export function translateLang(
  key: GetValueType<typeof zhData>,
  payload?: Record<string, any> | Array<any>
) {
  const realKey = languageMap.get(key) || ''
  return window.i18n?.t(realKey, payload)
}
