import { httpRequest } from '@/apis/request'

import { SaveCustomTranslationPayload } from './types'

enum Apis {
  saveCustomTranslation = 'language/translation',
}

/**
 * @description 保存自定义翻译
 * @link https://api.thinkerx.com/web/#/p/a4d423e481fcc1cec8e54f1ea6c19238
 */
export function saveCustomTranslation(payload: SaveCustomTranslationPayload) {
  return httpRequest.post(Apis.saveCustomTranslation, payload)
}

/**
 * @description 获取
 * @link https://api.thinkerx.com/web/#/p/a4d423e481fcc1cec8e54f1ea6c19238
 */
export function getCustomTranslation(payload: string) {
  return httpRequest.get(Apis.saveCustomTranslation, {
    language: payload,
  })
}
