import { IBaseMaterialManageDataType } from '@/apis/baseMaterial/type'
import { httpRequest } from '@/apis/request'
import type { FromGuimen, response } from '@/apis/types'

enum Apis {
  layoutData = 'get_layout_data',
  baseMaterial = 'load_plank_manage_setting_info',
}

export function getLayoutData(data: { room_ids: number[] }) {
  return httpRequest.post(Apis.layoutData, data)
}

export function getRawMaterialPlankInfo(params: { uid: number } & FromGuimen) {
  return httpRequest.post<response<IBaseMaterialManageDataType>>(
    Apis.baseMaterial,
    params
  )
}
