import type { Module } from 'vuex'

import type { IRootState } from '../types'
import type { IPaibanType } from './types'

const paibanStore: Module<IPaibanType, IRootState> = {
  namespaced: true,
  state: {
    /**
     * 记录多个排版结果
     * {
          paibanData: any[], // 排版数据
          drawData: any // 绘制数据
          finalDrawData: any[],// 最终排版数据
          paibanExtraInfo: any[], // 总优化率一类数据
          specialId: string, // 特殊ID
        }[]
     */
    paibanDataCollect: [],
    // 精细排版所有排版结果
    allSubPaibanDataCollectList: [],
    // 记录已被处理后的排版结果
    alreadyDealDrawData: {},
    // 记录每次调用排版接口后的预处理数据
    prePaibanData: {},
    // 记录当前多排版方案正在使用方案的特殊ID
    currentUsingSpecialId: '',
    subCurrentUsingSpecialId: '',
    // 记录已预载方案
    alreadyPreLoadPlan: [],
    subAlreadyDealDrawData: {},
    subAlreadyPreLoadPlanList: [],
    // 此次排版的用料数据
    useMaterialData: {},
  },
  mutations: {
    setPaibanDataCollect(state, val) {
      state.paibanDataCollect = val
    },
    setSubPaibanDataCollect(state, val) {
      state.allSubPaibanDataCollectList = val
    },
    setAlreadyDealDrawData(state, val) {
      state.alreadyDealDrawData[val.specialId] = val.value
      // 同步更新预载数据
      const keys = Object.keys(state.alreadyDealDrawData)
      state.alreadyPreLoadPlan = Array.from(keys)
    },
    setSubAlreadyDealDrawData(state, val) {
      state.subAlreadyDealDrawData[val.specialId] = val.value
      const keys = Object.keys(state.subAlreadyDealDrawData)
      state.subAlreadyPreLoadPlanList = Array.from(keys)
    },
    setPrePaibanData(state, val) {
      state.prePaibanData = val
    },
    clearAlreadyDealDrawData(state) {
      state.alreadyDealDrawData = {}
    },
    setCurrentUsingSpecialId(state, val) {
      state.currentUsingSpecialId = val
    },
    setSubCurrentUsingSpecialId(state, val) {
      state.subCurrentUsingSpecialId = val
    },
    // 排版用料信息赋值
    serUseMaterialData(state, val) {
      state.useMaterialData = val
    },
  },
}

export default paibanStore
