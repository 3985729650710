import Vue from 'vue'

import store from '../store'
import { viewDataToNCData } from './NCGenerator.js'

export function dealGuiguiJson(data, uid) {
  return new Promise((resolve) => {
    // 加载记录时获取默认生产线的nc设置的刀(只用获取刀, 不用管xy互换等操作)
    let params = {
      setting_id: -1,
    }
    if (window.sessionStorage.getItem('thinkerx_material')) {
      params.production_from = 'guimen'
    }
    if (uid != -1) {
      params.uid = uid
    }
    Vue.prototype.$getByToken('/get_production_nc_setting', params, (res) => {
      let arr = []
      for (let i = 0; i < data.length; ++i) {
        arr = [...arr, ...data[i].parts]
      }
      let ncSetting = res.data
      ncSetting.drawPlankWidth = ncSetting.xyReverse
        ? store.state.selectStandardPlank.plankHeight
        : store.state.selectStandardPlank.plankWidth
      ncSetting.drawPlankHeight = ncSetting.xyReverse
        ? store.state.selectStandardPlank.plankWidth
        : store.state.selectStandardPlank.plankHeight
      ncSetting.drawPlankEdgeOff = store.state.selectStandardPlank.plankEdgeOff
      store.commit('setNcSetting', ncSetting)
      let paibanData = []
      paibanData = viewDataToNCData(data, 'toView')
      store.commit('setPaibanData', paibanData)
      store.commit('changeHistoryStatus', true)
      resolve(arr)
    })
  })
}
