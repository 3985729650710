import { httpRequest } from '@/apis/request'
import type { FromGuimen, response } from '@/apis/types'
import { request } from '@/util/vueRequest'

import type { GetChangeRecordsListParams, ResponseType } from './type'

enum Apis {
  ncSetting = 'get_production_nc_setting',
  changeRecordsList = 'user/operate',
  getFiveSixDrillFileType = 'dock56F_list',
  getAllInOneMachineList = 'aio/models',
  sawSetting = 'load_electronic_saw_setting',
  oneMacLimit = 'integration_permission',
}

// 获取生产NC设置
export function getProductionNcSetting(
  params: { setting_id: number } & FromGuimen
) {
  return httpRequest.get<response>(Apis.ncSetting, params)
}

// 获取当前设备修改记录
export function getEquipmentChangeRecordsList(
  params: GetChangeRecordsListParams
) {
  return httpRequest.get<response<ResponseType>>(Apis.changeRecordsList, params)
}

// 保存设备配置()
export function saveEquipmentSetting(
  url: string,
  params:
    | {
        data: any
        setting_id: number
        password: boolean
      }
    | string
) {
  return request({
    url,
    method: 'POST',
    data: params,
    headers: {
      token: window.sessionStorage.getItem('token'),
    },
  })
}

// 获取五六面钻文件类型
export const getFiveSixDrillFileType = (params: { from: string }) => {
  return httpRequest.get<response<{ model_list: any[] }>>(
    Apis.getFiveSixDrillFileType,
    params
  )
}

// 获取一体机类型
export const getAllInOneMachine = (params: { from: string }) => {
  return httpRequest.get<response<{ model_list: any[] }>>(
    Apis.getAllInOneMachineList,
    params
  )
}

// 获取电子锯配置
export const getSaeSetting = (params: {
  setting_id: number
  setting_type: string
}) => {
  return request({
    url: Apis.sawSetting,
    method: 'POST',
    data: params,
    headers: {
      token: window.sessionStorage.getItem('token'),
    },
  })
}

// 获取一体机权限
export function getOneMacLimit(params: { setting_id: string }) {
  return httpRequest.get(Apis.oneMacLimit, params)
}

// 根据url获取数据
export function getJsonUrlData(url: string) {
  return request({
    url,
    method: 'get',
  })
}
